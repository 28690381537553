import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface EndSessionProps {
	closeDialog: () => void;
}

const EndSessionModal = ({ closeDialog }: EndSessionProps) => {
	return (
			<>
				<DialogTitle
						className='py-3 font-bold'
						sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
						id='alert-dialog-title'
				>
					Session Expired
				</DialogTitle>
				<DialogContent className='px-4 pt-4'>
					The session has expired. The test will be over.
				</DialogContent>
				<DialogActions className='pb-6 px-4'>
					<Button
							variant='contained'
							sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
							color='primary'
							onClick={closeDialog}
					>
						<p className='text-white'>Continue</p>
					</Button>
				</DialogActions>
			</>
	);
};

export default EndSessionModal;