import { Box } from '@mui/material';
import TitleSection from '../../../../core/components/section/TitleSection';

const DirL2 = () => {
	return (
		<TitleSection title='Listening Directions'>
			<Box>
				<p>In this part you will listen to 1 conversation an 2 lectures.</p>
				<br />
				<p>
					You must answer each question After you answer, click on <strong>Next</strong> Then click
					on <strong>OK</strong> to confirm your answer and go on to the next question. After you
					click on OK, you cannot return to previous questions. If you are using the{' '}
					<strong>Untimed Mode</strong>, you may return to previous questions.
				</p>
				<br />
				<p>
					You will now begin this part of the Listening section. In an a actual test, you will have
					10 minutes to answer the questions. NOTE: Also in an actual test some test takers may
					receive a third part in their Listening section; they will have another{' '}
					<strong>10 minutes</strong> to answer the questions about 1 conversation and 2 lectures.
				</p>
				<br />
				<p>
					Click on <strong>Continue</strong> to go on.
				</p>
			</Box>
		</TitleSection>
	);
};

export default DirL2;
