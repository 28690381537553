import { Box, Pagination, Stack } from '@mui/material';
import React from 'react';
import TitleSection from '../../../../core/components/section/TitleSection';
import HowToAnswerSub1 from './how-to-answer-sub1';
import HowToAnswerSub2 from './how-to-answer-sub2';
import HowToAnswerSub3 from './how-to-answer-sub3';
import HowToAnswerSub4 from './how-to-answer-sub4';
const HowToAnswer = () => {
	const [page, setPage] = React.useState(1);

	function handleChange(event: React.ChangeEvent<unknown>, selected: number) {
		setPage(selected);
	}
	return (
		<div className='pb-10'>
			{page === 1 && (
				<TitleSection title='Dragging Your Answer Choice'>
					<HowToAnswerSub1 />
				</TitleSection>
			)}

			{page === 2 && (
				<TitleSection title='Adding a Sentence to the Passage'>
					<HowToAnswerSub2 />
				</TitleSection>
			)}

			{page === 3 && (
				<TitleSection title='Choosing More Than One Answer'>
					<HowToAnswerSub3 />
				</TitleSection>
			)}

			{page === 4 && (
				<TitleSection title='Choosing One Answer'>
					<HowToAnswerSub4 />
				</TitleSection>
			)}
			<Box className='flex justify-center mt-10 bg-white'>
				<Stack className='fixed bottom-20' spacing={2}>
					<Pagination count={4} page={page} onChange={handleChange} color='primary' />
				</Stack>
			</Box>
		</div>
	);
};

export default HowToAnswer;
