import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import TitleSection from '../../../../core/components/section/TitleSection';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';

const WritingBasedOnReadingAndListeningDirections = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	const renderAudio = useCallback(
			() => {
				const fileList: any[] = content.ATTACH_FILE_LIST;
				const tempTracks: AudioProps[] = [];
				fileList.forEach((file) => {
					tempTracks.push({
						AUDIO_FILE: file.FILE_DATA,
						IMG_FILE: '',
						WAIT_TIME: 1,
					});
				});

				// setTracks(tempTracks);
				dispatch(setTracks(tempTracks));
			}, [content.ATTACH_FILE_LIST, dispatch]);

	useEffect(() => {
		if (content.ATTACH_FILE_LIST) {
			renderAudio();
		}
	}, [content.ATTACH_FILE_LIST, renderAudio]);

	return (
			<TitleSection title='Writing Based on Reading and Listening Directions'>
				<Box>
					<p>
						For this task, you will read a passage about an academic topic. A clock at the top of the
						screen will show how much time you have to read. You may take notes on the passage while
						you read. The passage will then be removed and you will listen to a lecture about the same
						topic. While you listen, you may also take notes.
					</p>
					<br />
					<p>
						Then you will write a response to a question that asks you about the relationship between
						the lecture you heard and the reading passage. Try to answer the question as completely as
						possible using information from the reading passage and the lecture. The question does not
						ask you to express your personal opinion. You will be able to see the reading passage
						again when it is time for you to write. You may use your notes to help you answer the
						question. A clock at the top of the screen will show how much time you have to write your
						response.
					</p>
					<br />
					<p>
						Typically, an effective response will be 150 to 225 words. Your response will be judged on
						the quality of your writing and on the completeness and accuracy of the content. If you
						finish your response before time is up, you may click on Next to go on to the second
						writing task.
					</p>
					<br />
					<p>
						Now you will see the reading passage. Remember it will be available to you again when you
						write your response. Immediately after the reading time ends. the lecture will begin, so
						keep your headset on until the lecture is over.
					</p>
					<br />
					<p className='font-bold'>(Select Continue at any time to dismiss these directions.)</p>
				</Box>
				<AudioPlayer visible={false} />
			</TitleSection>
	);
};

export default WritingBasedOnReadingAndListeningDirections;
