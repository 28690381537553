import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import blackman from '../../../../../assets/icons/blackman.png';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';

const ListenCarefully = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 1,
			});
		});

		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}

	useEffect(() => {
		renderAudio();
	}, []);

	return (
			<Box className='w-full max-w-[860px] mx-auto mt-52 flex flex-col'>
				<Typography className='font-bold text-xl mb-14'>Please listen carefully.</Typography>
				<img className='mb-5 w-[300px] self-center' src={blackman} alt={'black-man'} />
				<AudioPlayer visible={false} />
			</Box>
	);
};

export default ListenCarefully;
