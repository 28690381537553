import "./Item.scss";
import DragHandleIcon from '@mui/icons-material/DragHandle';

const Item = ({ id, dragOverlay, index, group }: any) => {
    const style = {
        cursor: dragOverlay ? "grabbing" : "grab",
    };
    return (
        <div style={style} className="flex items-center">
            <div
                contentEditable='false'
                dangerouslySetInnerHTML={{ __html: id }}
            ></div>
        </div>
    );
};

export default Item;
