import ApiService from '../services/api.service';
const API_URL = process.env.REACT_APP_BASE_URL;
export interface ILogin {
	HEADER: {
		SESSION: string;
	};
	BODY: {
		USER_INFO: {
			USER_ID: string;
			USER_NAME: string;
			NICKNAME: string;
		};
	};
	RESPONSE: {
		CODE: string;
		MESSAGE: string;
	};
}

class AuthRepository {
	async login(body: object) {
		try {
			const res = await ApiService.POST('/login.svc', body)
			return res;
		} catch (error) {
			throw error;
		}
	}
}

export default AuthRepository;
