import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { onTestController, toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';
import { userSelector } from '../../../../core/redux/AuthSlice';

const WritingWrl = () => {
	const dispatch = useAppDispatch();
	const { user: { USER_ID, SESSION } } = useAppSelector(userSelector);
	const { data, content } = useAppSelector(toeflSelector);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 1,
			});
		});

		dispatch(setTracks(tempTracks));
	}

	const afterDuringTime = useCallback(
			() => {
				const body = {
					HEADER: {
						SESSION,
						USER_ID,
					},
					BODY: {
						TEST_CODE: data.test_code,
						NEXT_STEP: data.next_step,
						TEST_MODE: data.test_mode,
					},
				};
				dispatch(onTestController({ body }));
			}, []);

	useEffect(() => {
		renderAudio();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			afterDuringTime();
		}, content.DURING_TIME * 1000);
	}, [afterDuringTime, content.DURING_TIME]);

	return (
			<Box className='w-full h-full flex border-2'>
				<Box className='w-2/4 h-full overflow-auto p-2 border-r-2'>
					{content.PASSAGELIST.map((passage: any) => {
						return <Box className='mb-5 font-bold'>
							<div
									contentEditable='false'
									dangerouslySetInnerHTML={{ __html: passage.PASSAGE }}
							></div>
						</Box>;
					})}
				</Box>
				<Box className='w-2/4 p-2'>
					<Box className='border-b-2 h-10'></Box>
				</Box>
				<AudioPlayer visible={false} />
			</Box>
	);
};

export default WritingWrl;
