import { Box, Pagination, Stack } from '@mui/material';
import React from 'react';
import TitleSection from '../../../../core/components/section/TitleSection';
import ListenHowToAnswerSub1 from './how-to-answer-sub1';
import ListenHowToAnswerSub2 from './how-to-answer-sub2';
const ListenHowToAnswer = () => {
	const [page, setPage] = React.useState(1);

	function handleChange(event: React.ChangeEvent<unknown>, selected: number) {
		setPage(selected);
	}
	return (
		<div className='pb-10'>
			{page === 1 && (
				<TitleSection title='Selecting a Box'>
					<ListenHowToAnswerSub1 />
				</TitleSection>
			)}

			{page === 2 && (
				<TitleSection title='Dragging Your Answer Choice'>
					<ListenHowToAnswerSub2 />
				</TitleSection>
			)}
			<Box className='flex justify-center mt-10 bg-white'>
				<Stack className='fixed bottom-20' spacing={2}>
					<Pagination count={2} page={page} onChange={handleChange} color='primary' />
				</Stack>
			</Box>
		</div>
	);
};

export default ListenHowToAnswer;
