import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';

interface ScrollBottomModalProps {
	open: boolean;
	handleClose: () => void;
}

const ScrollBottomModal = ({ open, handleClose }: ScrollBottomModalProps) => {
	return (
		<Dialog
			open={open}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			PaperProps={{
				style: { borderRadius: '12px' },
			}}
		>
			<DialogTitle className='py-3' sx={{ backgroundColor: '#FDF4C5' }} id='alert-dialog-title'>
				<Typography variant='body1' className='font-bold text-lg' sx={{ color: '#8A8066' }}>
					Scrolling
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box id='alert-dialog-description' className='flex flex-col px-4 pt-4'>
					<Typography variant='body1'>
						Read the whole passage before you begin to answer the questions. Use the scroll bar or
						the down arrow to reach the end of the passage. The passage will appear again with each
						question.
					</Typography>
					<br />
					<Typography variant='body1'>
						Select <strong>Continue</strong> to close this message.
					</Typography>
					<hr className='my-2' />
				</Box>
			</DialogContent>
			<DialogActions className='pb-6 px-4'>
				<Button
					variant='contained'
					sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
					onClick={handleClose}
					autoFocus
				>
					<p className='text-white'>Continue</p>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ScrollBottomModal;
