import { Box } from '@mui/material';
import TitleSection from '../../../core/components/section/TitleSection';

const EndOfTest = () => {
	return (
		<TitleSection title='End Of Test'>
			<Box className='pt-10'>
				<p className='font-bold mb-5'>Your testing session is now complete.</p>
				<p>
					Click on <strong>Continue</strong> to see your Score Report
				</p>
			</Box>
		</TitleSection>
	);
};

export default EndOfTest;
