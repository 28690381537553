import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';

interface PauseModalProps {
	open: boolean;
	handleClose: () => void;
	handleConfirm: () => void;
}

const PauseModal = ({ open, handleClose, handleConfirm }: PauseModalProps) => {
	const closeAndConfirm = () => {
		handleConfirm();
		handleClose();
	};

	return (
		<Dialog
			open={open}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			PaperProps={{
				style: { borderRadius: '12px' },
			}}
		>
			<DialogTitle
				className='py-3 font-bold'
				sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
				id='alert-dialog-title'
			>
				Pause
			</DialogTitle>
			<DialogContent>
				<Box id='alert-dialog-description' className='flex flex-col px-4 pt-4'>
					<Typography variant='body1' color={'#626576'}>
						You may now pause this test and resume work at any later time; however the score you
						obtain may not be an accurate indication of a score you would earn in a timed test. When
						you resume the test, you will return to the question from which you left. Are you sure
						you wish to pause the test? Click on Return to continue working. Click on Continue to
						pause the test.
					</Typography>
					<hr className='my-5' />
					<Typography component={'p'} color={'#313131'} variant='h6' className='font-normal mb-5'>
						Are you sure pause the test?
					</Typography>
					<ul className='list-disc pl-4 text-[#A3A5AE] text-sm'>
						<li className='mb-1'>
							Click on <strong>Return</strong> button then, return to the test.
						</li>
						<li>
							Click on <strong>Continue</strong> button then, pause the test.
						</li>
					</ul>
				</Box>
			</DialogContent>
			<DialogActions className='justify-center pb-6 gap-20'>
				<Button
					sx={{ backgroundColor: '#313131', color: '#FFFFFF', paddingX: '32px', paddingY: '10px' }}
					className='hover:bg-black'
					variant='contained'
					onClick={handleClose}
				>
					Return
				</Button>
				<Button
					variant='outlined'
					sx={{ border: '1px solid #313131', color: '#313131', paddingX: '24px', paddingY: '10px' }}
					onClick={closeAndConfirm}
					className='hover:border-black'
					autoFocus
				>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PauseModal;
