import ExamPassage from '../../../../../assets/imgs/exam-passage.png';
import TitleSection from '../../../../core/components/section/TitleSection';

const ReadingThePassage = () => {
	return (
		<TitleSection title='Reading the Passage'>
			<div>
				<p className='mb-5'>Select an arrow to move up or down in the passage.</p>
				<img alt='Reading passage' className='w-[30rem] mb-10' src={ExamPassage} />
				<p>
					Select <b>Return</b> to go on.
				</p>
			</div>
		</TitleSection>
	);
};

export default ReadingThePassage;
