import TitleSection from '../../../../core/components/section/TitleSection';

const EndSectionTimeOut = () => {
	return (
		<TitleSection title='End of Section - Time has Ended'>
			<div className='mt-32'>
				<p className='text-[#000000]'>
					Your time for answering questions in this section has ended.
					<br />
					<br />
					Select <span className='font-bold'>Continue</span> to go on to the next section of the
					test.
				</p>
			</div>
		</TitleSection>
	);
};

export default EndSectionTimeOut;
