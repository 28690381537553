import { Box, createTheme, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from './app/core/hooks';
import { loadedUser } from './app/core/redux/AuthSlice';
import { LocalStorageService } from './app/core/services/storage.service';

const theme = createTheme({
	typography: {
		button: {
			textTransform: 'none',
		},
		fontFamily: ['Noto Sans KR', 'sans-serif'].join(','),
	},
});

const App = () => {
	const dispatch = useAppDispatch();
	const storage = new LocalStorageService();

	useEffect(() => {
		if (storage.getItem('USER')) {
			dispatch(loadedUser());
		}
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Box className='app'>
				<Outlet />
			</Box>
		</ThemeProvider>
	);
};

export default App;
