import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppBar, Box, CircularProgress, Paper, Typography } from '@mui/material';
import logo from '../../../../../assets/icons/G-LOGO.svg';
import CButton from '../../../../core/components/Button/CButton';
import { ButtonEnum } from '../../../../core/constants/button.enum';
import pause_icon from '../../../../../assets/icons/pause-circle.svg';
import section_exit_icon from '../../../../../assets/icons/log-out-04.svg';
import return_icon from '../../../../../assets/icons/Arrow-transparent-left.svg';
import CountdownRemainTime from '../../../../core/components/countdown/CountdownRemainTime';
import Footer from '../../../../core/common/footer/Footer';
import { erasePlayBack, onTestController, toeflSelector } from '../../../../core/redux/ToeflSlice';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { useNavigate } from 'react-router-dom';
import { userSelector } from '../../../../core/redux/AuthSlice';
import TestRepository from '../../../../core/repositories/TestRepository';
import volume_icon from '../../../../../assets/icons/volume-max.svg';
import x_icon from '../../../../../assets/icons/X.svg';
import Stack from '@mui/material/Stack';
import VolumeDown from '@mui/icons-material/VolumeDown';
import Slider from '@mui/material/Slider';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { audioSelector, updateVolumeCntl } from '../../../../core/redux/AudioSlice';
import getBlobDuration from 'get-blob-duration';
import './playback-response.scss';

const PlayBackResponse = () => {
	const testRepository = new TestRepository();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { data, playback, expired_time, status } = useAppSelector(toeflSelector);
	const { volumeCntl, audioFile, isPlaying } = useAppSelector(audioSelector);
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);

	const [isVolumeModalHide, setIsVolumeModalHide] = useState<boolean>(true);
	const [volume, setVolume] = useState<number>(volumeCntl * 100);
	const [timer, setTimer] = useState(0);
	const [start, setStart] = useState(false);
	const firstStart: any = useRef(true);
	const tick: any = useRef();

	const audioFileRef = useRef<HTMLAudioElement>(new Audio(audioFile));
	const { duration }: HTMLAudioElement = audioFileRef.current;

	useEffect(() => {
		getBlobLength();
	}, []);

	async function getBlobLength() {
		const duration = await getBlobDuration(audioFile);
		setTimer(Math.floor(duration));
	}


	/* Section Pause button handle */
	const onPauseButton = async () => {
		const pause = await testRepository.onPauseTest({
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		});

		if (pause.RESPONSE.CODE === '1000') {
			navigate('/my-page', { replace: true });
		}
	};
	/* End section */

	/* Section exit handle */
	const onSectionExit = async () => {
		const section = await testRepository.onSectionTest({
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		});

		dispatch(onTestController(section));
		navigate('/testing', { replace: true });
	};
	/* End section */

	const returnTesting = () => {
		dispatch(erasePlayBack());
		navigate('/testing', { replace: true });
	};

	const handleVolumeChange = (event: Event, newValue: number | number[]) => {
		setVolume(newValue as number);
		dispatch(updateVolumeCntl((newValue as number) / 100));
	};

	const secondsToTime = (secs: number) => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		return <>
			{hours !== 0 ? (hours < 10 ? `0${hours}` : hours) : `00`}:
			{minutes !== 0 ? (minutes < 10 ? `0${minutes}` : minutes) : `00`}:
			{seconds !== 0 ? (seconds < 10 ? `0${seconds}` : seconds) : `00`}
		</>;
	};

	const dispSecondsAsMins = (seconds: any) => {
		if (seconds > 0) {
			return secondsToTime(seconds);
		} else {
			return '00:00:00';
		}
	};

	const playAudio = useCallback(
			async (playPromise: Promise<void>) => {
				try {
					await playPromise;
				} catch (e: any) {
					console.log(e.toString());
				}
			}, []);

	useEffect(() => {
		if (audioFile && audioFileRef.current) {
			audioFileRef.current.volume = volumeCntl;
			const playPromise = audioFileRef.current.play();
			if (playPromise !== undefined) {
				setTimer(duration);
				playAudio(playPromise).catch((err) => console.log(err));
			}
		}
	}, [audioFile, playAudio]);

	useEffect(() => {
		if (firstStart.current) {
			firstStart.current = !firstStart.current;
			return;
		}

		if (start) {
			tick.current = setInterval(() => {
				setTimer((timer) => timer - 1);
			}, 1000);
		} else {
			clearInterval(tick.current);
		}

		return () => clearInterval(tick.current);
	}, [start]);

	useEffect(() => {
		if (!isPlaying) {
			setStart(true);
		}
	}, [isPlaying]);

	useEffect(() => {
		// Pause and clean up on unmount
		return () => {
			if (audioFileRef.current) audioFileRef.current.pause();
		};
	}, []);

	return (
			<div className='flex flex-col h-screen'>
				<AppBar position='sticky' className='pt-[19px]' sx={{ backgroundColor: '#313131' }}>
					<Box className='flex justify-between px-[25px] pb-4'>
						<Box
								className='self-center mb-2'
								component={'img'}
								src={logo}
								alt={'logo'}
								sx={{ height: '38.9px', marginRight: 2 }}
						/>
						<Box className='flex flex-1 justify-between'>
							<Box className='left-col'>
								<CButton text={ButtonEnum.PAUSE} icon={pause_icon} onClick={onPauseButton} />
								<CButton
										text={ButtonEnum.SECTION_EXIT}
										icon={section_exit_icon}
										onClick={onSectionExit}
								/>
							</Box>
							<Box className='right-col'>
								<Box className='inline-block relative'>
									<CButton
											text={ButtonEnum.VOLUME}
											icon={volume_icon}
											isLong={true}
											onClick={() => setIsVolumeModalHide(!isVolumeModalHide)}
									/>
									{!isVolumeModalHide && (
											<Paper elevation={3} className='w-80 bg-white px-8 py-4 absolute right-0'>
												<Box className='flex justify-between items-center mb-3'>
													<Typography className='mb-2 color-[#626576]'>Volume Control</Typography>
													<img
															className='mb-3 cursor-pointer'
															src={x_icon}
															alt='close'
															onClick={() => setIsVolumeModalHide(true)}
													/>
												</Box>

												<Stack spacing={2} direction='row' sx={{ mb: 1 }} alignItems='center'>
													<VolumeDown />
													<Slider
															aria-label='Volume'
															value={volume}
															onChange={handleVolumeChange}
															className='text-black h-2'
													/>
													<VolumeUp />
												</Stack>
											</Paper>
									)}
								</Box>
								<CButton text={ButtonEnum.RETURN} icon={return_icon} onClick={returnTesting} />
							</Box>
						</Box>
					</Box>
					<Box className='bg-[#EDE2A9] h-8 border-b-black border-b flex px-5'>
						{playback.SECTION_TITLE && playback.SECTION_TITLE !== '' ? (
								<Box className='self-center'>
									<Typography className='font-semibold' variant='body1' color={'#313131'}>
										{playback.SECTION_TITLE}
									</Typography>
								</Box>
						) : null}
						{playback.TEST_MODE && playback.TEST_MODE === 'T' ? <CountdownRemainTime /> : null}
					</Box>
				</AppBar>
				<Box className='w-full max-w-[128rem] mx-auto flex-1 overflow-auto'>
					{status === 'loading' ? (
							<Box className='flex items-center justify-center h-full'>
								<CircularProgress />
							</Box>
					) : (
							<Box className='w-full flex flex-col h-full items-center'>
								<Box className='w-2/4 flex flex-col items-center mt-56'>
									<Box
											contentEditable='false'
											dangerouslySetInnerHTML={{ __html: playback.QNO_CONTENT }}
											className={'border-b border-slate-900 py-5 text-base'}
									></Box>
									<Box className='w-1/3 mt-20 flex flex-col items-center justify-center'>
										<Box className='w-full flex-col items-center justify-center border-black border-2 rounded-lg'>
											<Box className='w-full text-center bg-black text-white p-1 '>Playing time</Box>
											<Box className='w-full flex justify-evenly items-center px-5 py-1'>
												<Box className='text-3xl text-slate-500'>{dispSecondsAsMins(timer)}</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
					)}
				</Box>
				<Footer />
			</div>
	);
};

export default PlayBackResponse;
