export class LocalStorageService {
	public setItem(key: string, value: any) {
		const item = {
			value: value,
		};
		localStorage.setItem(key, JSON.stringify(item));
	}

	public setWithExpiry(key: string, value: any, ttl: number) {
		const now = new Date();

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		};
		localStorage.setItem(key, JSON.stringify(item));
	}

	public getItem(key: string) {
		return localStorage.getItem(key);
	}

	public getItemWithValue(key: string) {
		const itemStr = localStorage.getItem(key);

		// if the item doesn't exist, return null
		if (!itemStr) {
			return null;
		}
		const item = JSON.parse(itemStr);
		return item.value;
	}

	public getWithExpiry(key: string) {
		const itemStr = localStorage.getItem(key);
		// if the item doesn't exist, return null
		if (!itemStr) {
			return null;
		}
		const item = JSON.parse(itemStr);
		const now = new Date();
		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key);
			return null;
		}
		return item.value;
	}

	public removeItem(key: string) {
		localStorage.removeItem(key);
	}

	public clear() {
		localStorage.clear();
	}
}
