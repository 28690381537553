import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import { motion } from 'framer-motion';
import * as _ from 'lodash';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { setAnswer, setStrikeout, toeflSelector, updateGuessAnswer } from '../../../core/redux/ToeflSlice';
import './reading-passage-and-question.scss';
import { topBarSelector, updateScrollBottom } from '../../../core/redux/TopBarSlice';
import correctIcon from '../../../../assets/icons/Vector.png';
import wrongIcon from '../../../../assets/icons/Vector-1.png';


const ReadingPassageAndQuestion = () => {

	const dispatch = useAppDispatch();
	const { content, answer } = useAppSelector(toeflSelector);
	const { showAnswer } = useAppSelector(topBarSelector);
	const [answerArr, setAnswerArr] = useState<any[]>(content.USER_CHECK_NO);
	const [backupAnswerArr, setBackupAnswerArr] = useState<any>(content.USER_CHECK_NO);
	const [strikeoutList, setStrikeoutList] = useState<any[]>([]);
	const [paraString, setParaString] = useState('');
	const [checkCorrectAnswer, setCheckCorrectAnswer] = useState(true);

	const qnoTitleRef = useRef<HTMLDivElement>(null);
	const qnoContentRef = useRef<HTMLDivElement>(null);
	const passageRef = useRef<HTMLDivElement>(null);
	const paraRef = useRef<HTMLDivElement>(null);
	const SSITtargetListRef = useRef<any[]>([]);
	const SSITAnswer = useRef<Number>(0);

	useEffect(() => {
		if (answerArr.length) {
			let checkWrong = 0;
			if (content.QNOTYPE === 'MSMC' || content.QNOTYPE === 'SSMC') {
				for (const answer of answerArr) {
					const choice = content.CHOICE_LIST.find((item: any) => item.ITEM_NO === answer);
					if (choice.CORRECT_ANSWER_YN === 'N') {
						checkWrong++;
					}
				}
			}
			if (content.QNOTYPE === 'SSIT') {
				if (parseFloat(answerArr[0]) !== parseFloat(content.CORRECT_ANSWER_NO)) {
					checkWrong++;
				}
			}
			setCheckCorrectAnswer(checkWrong > 0 ? false : true);
		} else {
			setCheckCorrectAnswer(false);
		}
	}, [showAnswer]);

	useEffect(() => {
		if (showAnswer && content.QNOTYPE === 'SSIT') {
			const target: any = SSITtargetListRef.current[parseFloat(content.CORRECT_ANSWER_NO) - 1];
			const idx = target.getAttribute('item_no');

			const prevAnswer = SSITAnswer.current;
			if (prevAnswer !== 0) {
				const prevTarget = SSITtargetListRef.current[Number(prevAnswer) - 1];
				prevTarget.style.display = 'inline-block';
				prevTarget.firstChild.style.display = 'block';
				prevTarget.lastChild.style.display = 'none';
				target.className = '';

			}
			if (prevAnswer !== idx) {
				target.style.display = 'inline';
				target.className = 'show-answer';
				target.firstChild.style.display = 'none';
				target.lastChild.style.display = 'inline';
				SSITAnswer.current = idx;
				setAnswerArr([idx]);
			} else {
				SSITAnswer.current = 0;
				setAnswerArr([]);
			}
		}

		if (!showAnswer && content.QNOTYPE === 'SSIT' && answerArr.length) {
			if (SSITtargetListRef.current.length) {
				let target: any = {};
				if (backupAnswerArr[0]) {
					target = SSITtargetListRef.current[parseFloat(backupAnswerArr[0]) - 1];
				} else {
					target = SSITtargetListRef.current[parseFloat(answerArr[0]) - 1];
				}
				const idx = target.getAttribute('item_no');
				const prevAnswer = SSITAnswer.current;
				if (prevAnswer !== 0) {
					const prevTarget = SSITtargetListRef.current[Number(prevAnswer) - 1];
					prevTarget.style.display = 'inline-block';
					prevTarget.firstChild.style.display = 'block';
					prevTarget.lastChild.style.display = 'none';
					target.className = '';
					prevTarget.className = '';
				}
				if (prevAnswer !== idx) {
					target.style.display = 'inline';
					target.className = '';
					target.firstChild.style.display = 'none';
					target.lastChild.style.display = 'inline';
					SSITAnswer.current = idx;
					setAnswerArr([idx]);
				} else {
					SSITAnswer.current = 0;
					setAnswerArr([]);
				}
			}
		}
	}, [showAnswer]);
	useEffect(() => {
		if (!showAnswer) {
			setBackupAnswerArr(answerArr);
		}
	}, [answerArr]);

	const handleChangeAnswer = (value: any) => {
		value = value.toString();
		if (answerArr.length) {
			if (answerArr[0] === value) {
				setAnswerArr([]);
			} else {
				setAnswerArr([value]);
			}
		} else {
			setAnswerArr([value]);
		}
	};

	const handleChangeAnswerMSMC = (event: any) => {
		const ITEM_SEQ = event.target.value;
		const index = answerArr.indexOf(ITEM_SEQ);
		const temp = _.cloneDeep(answerArr);
		if (index < 0) {
			const corrects = content.CHOICE_LIST.filter((choice: any) => choice.CORRECT_ANSWER_YN === 'Y');
			if (answerArr.length === corrects.length) return;

			temp.push(event.target.value);
		} else {
			temp.splice(index, 1);
		}
		setAnswerArr(temp);
	};

	function onStrikeout(e: React.MouseEvent<HTMLDivElement, MouseEvent>, item_seq: string) {
		if (content.TEST_MODE === 'T') return;
		if (e.type === 'click') return;

		if (e.button === 2) {
			e.preventDefault();
			const index = strikeoutList.indexOf(item_seq);
			const temp = _.cloneDeep(strikeoutList);
			if (index < 0) {
				temp.push(item_seq);
			} else {
				temp.splice(index, 1);
			}
			setStrikeoutList(temp);
		}
	}

	const setScroll = () => {
		if (paraRef.current) {
			let top = localStorage.getItem('para-scroll');
			if (top !== null) {
				paraRef.current.scrollTop = parseInt(top, 10);
			}
		}
	};

	const delay = (ms: number) => new Promise(
			resolve => setTimeout(resolve, ms),
	);

	const onScroll = async () => {
		if (paraRef.current) {
			const { scrollTop } = paraRef.current;
			await delay(700);
			localStorage.setItem('para-scroll', String(scrollTop));
		}
	};

	function renderPara() {
		let paraString = '';
		content.PASSAGE.PARA_LIST.map((para: any, index: number) => {
			paraString += para.PARA_SENTENCE + '<br/><br/>';
			const stringDecode = decodeHtml(para.PARA_SENTENCE.replace(/<[^>]+>/g, ''))
		});
		setParaString(paraString);
	}

	function decodeHtml(html: any) {
		var txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	function handleSSITTargetClick(event: Event) {
		const target: any = event.currentTarget;
		const idx = target.getAttribute('item_no');

		const prevAnswer = SSITAnswer.current;
		if (prevAnswer !== 0) {
			const prevTarget = SSITtargetListRef.current[Number(prevAnswer) - 1];
			prevTarget.style.display = 'inline-block';
			prevTarget.firstChild.style.display = 'block';
			prevTarget.lastChild.style.display = 'none';
			prevTarget.className = '';

		}
		if (prevAnswer !== idx) {
			target.style.display = 'inline';
			target.firstChild.style.display = 'none';
			target.lastChild.style.display = 'inline';
			target.className = '';


			SSITAnswer.current = idx;
			setAnswerArr([idx]);
		} else {
			SSITAnswer.current = 0;
			setAnswerArr([]);
		}
	}

	//render images qno, passage
	function renderImgInRefs(
			refs: RefObject<HTMLDivElement>[],
			refCode: string,
			fileData: string,
	) {
		refs.forEach((ref) => {
			const imgs = ref.current?.getElementsByTagName('img');
			if (imgs !== undefined) {
				Array.from(imgs).forEach((img) => {
					const attachCode = img.getAttribute('attach_code');
					if (attachCode === refCode) {
						img.src = `data:image/jpeg;base64,${fileData}`;
					}
				});
			}
		});
	}

	const renderBase64 = useCallback(() => {
		if (content.QNOTYPE === 'SSIT' || content.QNOTYPE === 'SSMC' || content.QNOTYPE === 'MSMC') {
			const fileList: any[] = content.ATTACH_FILE_LIST;

			fileList?.forEach((file) => {
				const REF_CODE = file.REF_CODE;
				const FILE_DATA = file.FILE_DATA;
				renderImgInRefs([qnoTitleRef, qnoContentRef, passageRef], REF_CODE, FILE_DATA);

				//render SSIT images in passage
				if (content.QNOTYPE === 'SSIT') {
					const passageTargets = passageRef.current?.getElementsByTagName('insert_text_target');

					if (passageTargets !== undefined) {
						Array.from(passageTargets).forEach((passageTarget) => {
							const attachCode = passageTarget.getAttribute('ref_code');
							if (REF_CODE.includes(attachCode) && passageTarget.children.length < 1) {
								passageTarget.addEventListener('click', handleSSITTargetClick);

								SSITtargetListRef.current.push(passageTarget);
								const img = document.createElement('img');
								img.src = `data:image/jpeg;base64,${FILE_DATA}`;

								const insertText = document.createElement('span');
								insertText.innerHTML = `${content.INSERT_TEXT}`;
								insertText.style.display = 'none';
								passageTarget.appendChild(img);
								passageTarget.appendChild(insertText);
							}
						});
					}
				}
			});

			if (content.USER_CHECK_NO.length) {
				onUserChecked();
			}

		}
	}, [content.ATTACH_FILE_LIST, content.INSERT_TEXT, content.QNOTYPE]);

	function onUserChecked() {
		if (SSITtargetListRef.current.length) {
			let target: any = {};
			if (backupAnswerArr[0]) {
				target = SSITtargetListRef.current[parseFloat(backupAnswerArr[0]) - 1];
			} else {
				target = SSITtargetListRef.current[parseFloat(answerArr[0]) - 1];
			}
			const idx = target.getAttribute('item_no');
			const prevAnswer = SSITAnswer.current;
			if (prevAnswer !== 0) {
				const prevTarget = SSITtargetListRef.current[Number(prevAnswer) - 1];
				prevTarget.style.display = 'inline-block';
				prevTarget.firstChild.style.display = 'block';
				prevTarget.lastChild.style.display = 'none';
				target.className = '';
				prevTarget.className = '';
			}
			if (prevAnswer !== idx) {
				target.style.display = 'inline';
				target.className = '';
				target.firstChild.style.display = 'none';
				target.lastChild.style.display = 'inline';
				SSITAnswer.current = idx;
				setAnswerArr([idx]);
			} else {
				SSITAnswer.current = 0;
				setAnswerArr([]);
			}
		}
	}

	useEffect(() => {
		dispatch(updateScrollBottom(true));
	}, [dispatch]);
	useEffect(() => {
		renderPara();
	}, []);
	useEffect(() => {
		renderBase64();
		setTimeout(() => {
			setScroll();
		}, 700);
	}, [paraString, content, renderBase64]);
	useEffect(() => {
		dispatch(setAnswer({ ...answer, ANSWER: answerArr }));
		dispatch(updateGuessAnswer({ GUESS_ANSWER: answerArr }));
	}, [answerArr, dispatch]);

	useEffect(() => {
		dispatch(setAnswer({ ...answer, STRIKEOUT: strikeoutList }));
	}, [dispatch, strikeoutList]);

	return (
			<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					className='w-full h-full relative flex'
			>
				<div className='w-2/4 p-2'>
					{
						showAnswer ?
								<img className='float-left mr-2' src={checkCorrectAnswer ? correctIcon : wrongIcon} alt='' />
								: ''
					}
					<div
							ref={qnoTitleRef}
							className='qno mb-5'
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: content.QNO_CONTENT }}
					></div>
					<div
							className='mb-5 font-bold'
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: content.INSERT_TEXT }}
					></div>
					<div
							ref={qnoContentRef}
							className='qno mb-5'
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: content.POST_QNO_CONTENT }}
					></div>

					{content.CHOICE_LIST?.map((choice: any, index: number) => {
						if (content.QNOTYPE === 'MSMC') {
							return (
									<div key={index} className='flex items-center'>
										<Checkbox
												onChange={handleChangeAnswerMSMC}
												checked={answerArr.includes(choice.ITEM_NO)}
												value={choice.ITEM_SEQ}
												name='radio-buttons'
												sx={{
													color: grey[800],
													'&.Mui-checked': {
														color: grey[800],
													},
												}}
												checkedIcon={<DisabledByDefaultIcon />}
										/>

										<div
												className={strikeoutList.includes(choice.ITEM_NO) ? 'line-through' : ''}
												onClick={(e) => onStrikeout(e, choice.ITEM_NO)}
												onContextMenu={(e: any) => onStrikeout(e, choice.ITEM_NO)}
										>
									<span
											className={showAnswer && choice.CORRECT_ANSWER_YN === 'Y' && content.TEST_MODE === 'U' ? 'show-answer' : ''}
											contentEditable='false'
											dangerouslySetInnerHTML={{ __html: choice.ITEM_CONTENT }}
									></span>
										</div>
									</div>
							);
						} else {
							return (
									<div key={index} className='flex items-center'>
										<div
												onClick={() => handleChangeAnswer(choice.ITEM_NO)}
												className={
													answerArr.includes(choice.ITEM_NO)
															? 'answer-circle-checkbox-active'
															: 'answer-circle-checkbox'
												}
										></div>
										<div
												style={{ width: '90%' }}
												className={strikeoutList.includes(choice.ITEM_NO) ? 'line-through' : ''}
												onClick={(e: any) => onStrikeout(e, choice.ITEM_NO)}
												onContextMenu={(e: any) => onStrikeout(e, choice.ITEM_NO)}
										>
									<span
											className={showAnswer && choice.CORRECT_ANSWER_YN === 'Y' && content.TEST_MODE === 'U' ? 'show-answer' : ''}
											contentEditable='false'
											dangerouslySetInnerHTML={{ __html: choice.ITEM_CONTENT }}
									></span>
										</div>
									</div>
							);
						}
					})}
				</div>
				<div className='w-2/4 h-full overflow-auto border-l-2 p-2' ref={paraRef} onScroll={onScroll}>
					<div
							className='w-full text-center font-bold my-3'
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: content.PASSAGE.TITLE }}
					></div>
					<div>
						{
							<div
									ref={passageRef}
									contentEditable='false'
									dangerouslySetInnerHTML={{ __html: paraString }}
							></div>
						}
					</div>
				</div>
			</motion.div>
	);
};

export default ReadingPassageAndQuestion;
