import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import alert_icon from '../../../../assets/icons/G-Alert.svg';

interface SSMCProps {
	closeDialog: () => void;
}

const SSMCDialog = ({ closeDialog }: SSMCProps) => {
	return (
			<>
				<DialogTitle
						className='py-3 font-bold flex'
						sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
						id='alert-dialog-title'
				>
					<img src={alert_icon} alt='alert_icon' className='mr-2' /> Must Answer
				</DialogTitle>
				<DialogContent className='px-4 pt-4'>
					You Must enter an answer before you can leave this question.
				</DialogContent>
				<DialogActions className='pb-6 px-4'>
					<Button
							variant='contained'
							sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
							color='primary'
							onClick={closeDialog}
					>
						<p className='text-white'>Return to Question</p>
					</Button>
				</DialogActions>
			</>
	);
};

export default SSMCDialog;
