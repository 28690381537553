import { Box } from '@mui/material';
import { useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import TitleSection from '../../../../core/components/section/TitleSection';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';

const WritingSectionDirection = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 1,
			});
		});

		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}

	useEffect(() => {
		renderAudio();
	}, []);

	return (
			<TitleSection title='Writing Section Directions'>
				<Box>
					<p className='font-bold'>Make sure your headset is on.</p>
					<br />
					<p>
						This section is measures your ability to use writing to communicate in an academic
						environment. There will be two writing tasks.
					</p>
					<br />
					<p>
						For the first writing task, you will read a passage and listen to a lecture and then
						answer a question based on what you have read and heard. For the second writing task, you
						will answer a question based on your own knowledge and experience.
					</p>
					<br />
					<p>Now listen to the directions for the first writing task.</p>
					<br />
					<p className='font-bold'>(Select Continue at any time to dismiss these directions.)</p>
				</Box>
				<AudioPlayer visible={false} />
			</TitleSection>
	);
};

export default WritingSectionDirection;
