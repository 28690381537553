import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { setAnswer, toeflSelector } from '../../../../core/redux/ToeflSlice';
import CheckIcon from '@mui/icons-material/Check';
import { topBarSelector, updateCorrectQuestion } from '../../../../core/redux/TopBarSlice';

const MSGRIDQuestion = () => {
    const dispatch = useAppDispatch();
    const { content } = useAppSelector(toeflSelector);
    const [answerArr, setAnswerArr] = useState<any>([]);
    const [backupAnswerArr, setBackupAnswerArr] = useState<any>([]);
    const { showAnswer } = useAppSelector(topBarSelector);
    const [isHighlight, setIsHighlight] = useState<boolean>(false);

    useEffect(() => {
        if (answerArr.length) {
            const find = answerArr.find((item: any)=> item.CORRECT_ANSWER_YN === 'N');
            
            dispatch(updateCorrectQuestion(find ? false : true));

        } else {
            dispatch(updateCorrectQuestion(false))
        }
    }, [showAnswer])

    useEffect(() => {
        const answers: any = [];
        if (showAnswer) {
            for (let i = 0; i < content.ROW_LIST.length; i++) {
                const row = content.ROW_LIST[i];
                if (i !== 0) {
                    for (const col of row.COL_LIST) {
                        if (col.CORRECT_ANSWER_YN === 'Y') {
                            answers.push(col)
                        }
                    }
                }
            }
            setAnswerArr(answers);
            setIsHighlight(true)
        } else {
            setAnswerArr(backupAnswerArr);
            setIsHighlight(false)

        }
    }, [showAnswer])

    useEffect(() => {
        if (!showAnswer) {
            setBackupAnswerArr(answerArr)
        }
    }, [answerArr])

    useEffect(() => {
        formatAnswer();
    }, [answerArr])

    function columnCheck(col: any) {
        let tempAnswerArr = _.cloneDeep(answerArr);
        const checkExist = tempAnswerArr.find((answer: any) => answer.ITEM_NO === col.ITEM_NO);

        if (checkExist) {
            tempAnswerArr = tempAnswerArr.filter((answer: any) => answer.ITEM_NO !== col.ITEM_NO);
            setAnswerArr(tempAnswerArr);
        } else {

            const check = tempAnswerArr.find((answer: any) => answer.ROW_NO === col.ROW_NO);
            if (check) {
                tempAnswerArr = tempAnswerArr.filter((answer: any) => answer.ROW_NO !== col.ROW_NO);
            }
            tempAnswerArr.push(col);
            setAnswerArr(tempAnswerArr);
        }
        setIsHighlight(false)
    }
    function formatAnswer() {
        const answers = answerArr.map((answer: any) => { return answer.ITEM_NO });
        dispatch(setAnswer({ ANSWER: answers }));
    }
    return (
        <div className='w-full flex justify-center' style={{ marginTop: '60px' }}>
            <div className='w-2/4 border-t-2'>
                {
                    content.ROW_LIST.map((row: any, index: number) => {
                        if (index !== 0) {
                            return (
                                <div className='w-full flex border-b-2' key={index}>
                                    <div
                                        className='w-2/4 border-r-2 border-l-2 p-2'
                                        contentEditable='false'
                                        dangerouslySetInnerHTML={{ __html: row.COL_LIST[0].ITEM_CONTENT }}
                                    ></div>
                                    <div onClick={() => { columnCheck(row.COL_LIST[1]) }} className='w-1/4 border-r-2 text-center flex items-center justify-center'>
                                        {
                                            answerArr.find((answer: any) => answer.ITEM_NO === row.COL_LIST[1].ITEM_NO) ? <div style={{ backgroundColor: showAnswer && content.TEST_MODE === 'U' && isHighlight ? '#A5EF47' : '' }}><CheckIcon /></div> : ''
                                        }
                                    </div>
                                    <div onClick={() => { columnCheck(row.COL_LIST[2]) }} className='w-1/4 border-r-2 text-center flex items-center justify-center'>
                                        {
                                            answerArr.find((answer: any) => answer.ITEM_NO === row.COL_LIST[2].ITEM_NO) ? <div style={{ backgroundColor: showAnswer && content.TEST_MODE === 'U' && isHighlight ? '#A5EF47' : '' }}><CheckIcon /></div> : ''
                                        }
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='w-full flex border-b-2' key={index}>
                                    <div className='w-2/4 border-r-2 border-l-2 p-2'></div>
                                    <div onClick={() => { columnCheck(row.COL_LIST[1]) }} className='w-1/4 border-r-2 text-center flex items-center justify-center'>
                                        Yes
                                    </div>
                                    <div onClick={() => { columnCheck(row.COL_LIST[2]) }} className='w-1/4 border-r-2 text-center flex items-center justify-center'>
                                        No
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )

}

export default MSGRIDQuestion;