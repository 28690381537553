import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import * as _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { setAnswer, setStrikeout, toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../image-and-listening-audio/image-and-listening-audio';
import MSOMQuestion from '../msom-question/msom-question';
import './listening-question.scss';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import alert_icon from '../../../../../assets/icons/G-Alert.svg';
import { useDialog } from '../../../../utils/context/DialogContext';
import { audioSelector, setTracks } from '../../../../core/redux/AudioSlice';
import MSGRIDQuestion from '../msgrid-question/msgrid-question';
import { topBarSelector } from '../../../../core/redux/TopBarSlice';
import correctIcon from '../../../../../assets/icons/Vector.png';
import wrongIcon from '../../../../../assets/icons/Vector-1.png';

const ListeningQuestion = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	const { isAudioEnded } = useAppSelector(audioSelector);
	const [answerArr, setAnswerArr] = useState<any[]>([]);
	const [strikeoutList, setStrikeoutList] = useState<any[]>([]);
	const { showAnswer, correctQuestion } = useAppSelector(topBarSelector);
	const [checkCorrectAnswer, setCheckCorrectAnswer] = useState(true);

	const [openDialog, closeDialog] = useDialog();

	useEffect(() => {
		setCheckCorrectAnswer(correctQuestion);
	}, [correctQuestion]);

	useEffect(() => {
		if (answerArr.length) {
			let checkWrong = 0;
			if (content.QNOTYPE === 'MSMC' || content.QNOTYPE === 'SSMC') {
				for (const answer of answerArr) {
					const choice = content.CHOICE_LIST.find((item: any) => item.ITEM_NO === answer);
					if (choice && choice.CORRECT_ANSWER_YN === 'N') {
						checkWrong++;
					}
				}
			}
			setCheckCorrectAnswer(checkWrong > 0 ? false : true);
		} else {
			setCheckCorrectAnswer(false);
		}
	}, [showAnswer]);

	const playFile = useCallback(
			() => {
				const fileList: any[] = content.ATTACH_FILE_LIST;

				const filtered = Object.keys(content)
						.filter(key => key.slice(0, 9) === 'PLAY_FILE')
						.reduce((arr: any, key) => {
							arr.push(content[key]);
							return arr;
						}, []);

				const tempTracks: AudioProps[] = [];
				for (let i = 0; i < filtered.length; i++) {
					const audioIdx = fileList.findIndex((val) => filtered[i] === val.REF_CODE);
					if (audioIdx !== -1) {
						tempTracks.push({
							AUDIO_FILE: fileList[audioIdx].FILE_DATA,
							IMG_FILE: '',
							WAIT_TIME: 1,
						});
					}
				}

				dispatch(setTracks(tempTracks));
			}, [content, dispatch]);

	const openMSMCDialog = () => {
		openDialog({
			children: (
					<>
						<DialogTitle
								className='py-3 font-bold flex'
								sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
								id='alert-dialog-title'
						>
							<img src={alert_icon} alt='alert_icon' className='mr-2' /> Required Answer
						</DialogTitle>
						<DialogContent className='px-4 pt-4'>
							<p>You must select the EXACT number of choices before you can leave this question.</p>
							<br />
							<p>Refer to the directions.</p>
						</DialogContent>
						<DialogActions className='pb-6 px-4'>
							<Button
									variant='contained'
									sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
									color='primary'
									onClick={closeDialog}
							>
								<p className='text-white'>Return to Question</p>
							</Button>
						</DialogActions>
					</>
			),
		});
	};

	useEffect(() => {
		playFile();
	}, [playFile]);

	const handleChangeAnswer = (value: any) => {
		value = value.toString();
		if (answerArr.length) {
			if (answerArr[0] === value) {
				setAnswerArr([]);
				dispatch(setAnswer({ ANSWER: [] }));
			} else {
				setAnswerArr([value]);
				dispatch(setAnswer({ ANSWER: [value] }));
			}
		} else {
			setAnswerArr([value]);
			dispatch(setAnswer({ ANSWER: [value] }));
		}
	};

	const handleChangeAnswerMSMC = (event: any) => {
		let ansLength = 0;
		const ITEM_SEQ = parseInt(event.target.value);
		const index = answerArr.indexOf(ITEM_SEQ);
		const temp = _.cloneDeep(answerArr);

		ansLength = (content.CHOICE_LIST as Array<any>).filter(
				(val: any) => val.CORRECT_ANSWER_YN === 'Y',
		).length;

		if (index < 0) {
			if (temp.length >= ansLength) {
				openMSMCDialog();
				return;
			}
			temp.push(parseInt(event.target.value));
		} else {
			temp.splice(index, 1);
		}
		setAnswerArr(temp);
		dispatch(setAnswer({ ANSWER: temp }));
	};

	function onStrikeout(e: any, item_seq: number) {
		if (content.TEST_MODE === 'T') return;
		const index = strikeoutList.indexOf(item_seq);
		const temp = _.cloneDeep(strikeoutList);
		if (index < 0) {
			temp.push(item_seq);
		} else {
			temp.splice(index, 1);
		}
		setStrikeoutList(temp);
	}

	useEffect(() => {
		dispatch(setStrikeout({ STRIKEOUT: strikeoutList }));
	}, [dispatch, strikeoutList]);

	return (
			<div className='w-full'>
				<div className='p-2'>
					{
						showAnswer ?
								<img className='float-left mr-2' src={checkCorrectAnswer ? correctIcon : wrongIcon} alt='' /> : ''
					}
					<div
							className='qno mb-5 font-bold'
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: content.QNO_CONTENT }}
					></div>
					{content.RESPONSE_DIRECTION ? (
							<div className='text-center font-bold bg-gray-200 p-2'>{content.RESPONSE_DIRECTION}</div>
					) : (
							''
					)}
					{content.QNOTYPE === 'MSOM' ? <MSOMQuestion /> : ''}
					{content.QNOTYPE === 'MSGRID' ? <MSGRIDQuestion /> : ''}

					{content.CHOICE_LIST && content.CHOICE_LIST?.map((choice: any, index: number) => {
						if (isAudioEnded) {
							if (content.QNOTYPE === 'MSMC') {
								return (
										<div key={index} className='flex items-center'>
											<Checkbox
													onChange={handleChangeAnswerMSMC}
													checked={answerArr.includes(choice.ITEM_SEQ)}
													value={choice.ITEM_SEQ}
													name='radio-buttons'
													sx={{
														color: grey[800],
														'&.Mui-checked': {
															color: grey[800],
														},
													}}
													checkedIcon={<DisabledByDefaultIcon />}
											/>

											<span
													className={strikeoutList.includes(choice.ITEM_SEQ) ? 'line-through' : ''}
													style={{ backgroundColor: showAnswer && choice.CORRECT_ANSWER_YN === 'Y' && content.TEST_MODE === 'U' ? '#A5EF47' : '' }}
													onClick={(e: any) => onStrikeout(e, choice.ITEM_SEQ)}
													dangerouslySetInnerHTML={{ __html: choice.ITEM_CONTENT }}
											>
									</span>
										</div>
								);
							} else {
								return (
										<div key={index} className='flex items-center'>
											<div
													onClick={() => handleChangeAnswer(choice.ITEM_NO)}
													className={
														answerArr.includes(choice.ITEM_NO)
																? 'answer-circle-checkbox-active'
																: 'answer-circle-checkbox'
													}
											></div>
											<span
													style={{ backgroundColor: showAnswer && choice.CORRECT_ANSWER_YN === 'Y' && content.TEST_MODE === 'U' ? '#A5EF47' : '' }}
													className={strikeoutList.includes(choice.ITEM_SEQ) ? 'line-through' : ''}
													onClick={(e: any) => onStrikeout(e, choice.ITEM_SEQ)}
													dangerouslySetInnerHTML={{ __html: choice.ITEM_CONTENT }}
											>
									</span>
										</div>
								);
							}
						}
					})}
				</div>
				<AudioPlayer visible={false} />
			</div>
	);
};

export default ListeningQuestion;
