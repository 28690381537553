import { Dialog } from '@mui/material';
import { createContext, ReactNode, useContext, useRef, useState } from 'react';

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

type DialogParams = {
	children: ReactNode;
	open: boolean;
	onClose?: Function;
};
type DialogOption = Omit<DialogParams, 'open'>;
type DialogContainerProps = DialogParams & {
	onClose: () => void;
};

interface ChildProps {
	children: ReactNode;
}
const EMPTY_FUNC = () => {};
const DialogContext = createContext<ProviderContext>([EMPTY_FUNC, EMPTY_FUNC]);

export const DialogProvider = ({ children }: ChildProps) => {
	const [dialogs, setDialogs] = useState<DialogParams[]>([]);

	const createDialog = (option: DialogOption) => {
		const dialog = { ...option, open: true };
		setDialogs([...dialogs, dialog]);
	};

	const closeDialog = () => {
		setDialogs((dialogs) => {
			const latestDialog = dialogs.pop();
			if (!latestDialog) return dialogs;
			if (latestDialog.onClose) latestDialog.onClose();
			return [...dialogs].concat({ ...latestDialog, open: false });
		});
	};

	const contextValue = useRef([createDialog, closeDialog] as const);

	return (
		<DialogContext.Provider value={contextValue.current}>
			{children}
			{dialogs.map((dialog, i) => {
				const { onClose, ...dialogParams } = dialog;
				return <DialogContainer key={i} onClose={closeDialog} {...dialogParams} />;
			})}
		</DialogContext.Provider>
	);
};

const DialogContainer = (props: DialogContainerProps) => {
	const { children, open } = props;

	return (
		<Dialog
			open={open}
			PaperProps={{
				style: { borderRadius: '12px' },
			}}
		>
			{children}
		</Dialog>
	);
};

export const useDialog = () => useContext(DialogContext);

export default DialogContext;
