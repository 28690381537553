import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Time from '../../../assets/icons/Time.svg';
import Untime from '../../../assets/icons/Untime.svg';
import { grey } from '@mui/material/colors';

import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { onTestController, toeflSelector } from '../../core/redux/ToeflSlice';
import { LocalStorageService } from '../../core/services/storage.service';
import SelectModeModal from '../../core/components/modals/SelectModeModal';
import useFullScreen from '../../core/customhooks/useFullScreen';

const StartScreen = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const storage = new LocalStorageService();
	const user = storage.getItemWithValue('USER');
	const { data } = useAppSelector(toeflSelector);
	const [mode, setMode] = useState('');
	const [openSelectModeModal, setOpenSelectModeModal] = useState<boolean>(false);
	const [open, closeFullScreen] = useFullScreen(document.documentElement);

	const untimedInitialState = {
		UNTIMED_OPTION_HL: 'N',
		UNTIMED_OPTION_SO: 'N',
		UNTIMED_OPTION_SS: 'N',
		UNTIMED_OPTION_GS: 'N',
	};

	const [untimed, setUntimed] = useState<any>(untimedInitialState);

	function onSelectMode(mode: string) {
		if (mode === 'U') {
			setUntimed({
				UNTIMED_OPTION_HL: 'Y',
				UNTIMED_OPTION_SO: 'Y',
				UNTIMED_OPTION_SS: 'Y',
				UNTIMED_OPTION_GS: 'Y',
			});
		} else {
			setUntimed(untimedInitialState);
		}
		setMode(mode);
	}

	async function handleTestController() {
		let body: any = {
			HEADER: {
				SESSION: user.SESSION,
				USER_ID: user.USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: mode,
			},
		};

		if (mode === 'U') {
			for (const key in untimed) {
				body.BODY[key] = untimed[key];
			}
		}
		dispatch(onTestController({ body }));
	}

	function onChangeUntimed(type: any) {
		setUntimed({ ...untimed, [type]: untimed[type] === 'Y' ? 'N' : 'Y' });
	}

	const returnToMyPage = () => {
		closeFullScreen();
		navigate('/my-page', { replace: true });
	};

	return (
			<>
				<div className='p-5 w-full'>
					<Card className='p-8'>
						<div className='mb-10'>
							<div style={{ color: '#0F4C95' }} className='mb-3 font-bold'>
								Practice Test Customize Test Page
							</div>
							<div>
								You may choose to take the Practice Test in TIMED or UNTIMED mode. Once you make your
								choice and click on <b>START TEST</b> below, you <b>WILL NOT</b> be able to return to
								this page to choose again. The report of your scores on the Practice Test will be the
								same regardless of whether you take the test in the timed or the untimed mode. Please
								read carefully and select the mode in which you wish to take the test.
							</div>
						</div>

						<div className='w-full flex h-max gap-10'>
							<div
									className={
											'w-2/4 pr-2 flex flex-col items-center justify-between border rounded-lg p-6 ' +
											(mode === 'T' ? 'border-[#313131]' : 'border-gray-300')
									}
							>
								<div>
									<div className='font-bold mb-5 flex items-center'>
										<img alt='Timed mode title' className='mr-4' src={Time} />
										TAKE THE TEST IN TIMED MODE:
									</div>
									<div className='text-[#626576]'>
										If you choose to take the test in timed mode, your experience will be closer to
										the actual TOEFL test experience. When time expires for each section, you will be
										given the option to either continue with that section or to end the section and
										continue to the next section. Once you continue to the next section, you MAY NOT
										return to the section just completed. Scores obtained in the timed mode help
										predict (but do not guarantee) test performance in an actual test.
									</div>
								</div>
								<div>
									<Button
											onClick={() => onSelectMode('T')}
											variant={mode === 'T' ? 'contained' : 'outlined'}
											className={
													'w-40 h-12 ' +
													(mode === 'T'
															? 'text-white bg-black border-0'
															: 'bg-slate-200 text-black border-0')
											}
									>
										{mode === 'T' ? 'Selected' : 'Select'}
									</Button>
								</div>
							</div>
							<div
									className={
											'w-2/4 pr-2 flex flex-col items-center justify-between border rounded-lg p-6 ' +
											(mode === 'U' ? 'border-[#313131]' : 'border-gray-300')
									}
							>
								<div className='mb-5'>
									<div className='font-bold mb-5 flex items-center'>
										<img alt='Untimed mode title' className='mr-4 w-7' src={Untime} />
										TAKE THE TEST IN UNTIMED MODE:
									</div>
									<div className='text-[#626576]'>
										If you choose to take the test in timed mode, your experience will be closer to
										the actual TOEFL test experience. When time expires for each section, you will be
										given the option to either continue with that section or to end the section and
										continue to the next section. Once you continue to the next section, you MAY NOT
										return to the section just completed. <br />
										Scores obtained in the timed mode help predict (but do not guarantee) test
										performance in an actual test.
									</div>
								</div>
								<div className='mb-5 flex flex-col gap-4'>
									<div className='flex items-center'>
										<Checkbox
												sx={{
													color: grey[800],
													'&.Mui-checked': {
														color: grey[800],
													},
												}}
												checked={untimed.UNTIMED_OPTION_HL === 'Y'}
												onChange={() => onChangeUntimed('UNTIMED_OPTION_HL')}
										/>
										<div className='text-[#626576]'>
											Highlight Text: You may use your mouse to highlight text in the reading
											passages.
										</div>
									</div>
									<div className='flex items-center'>
										<Checkbox
												sx={{
													color: grey[800],
													'&.Mui-checked': {
														color: grey[800],
													},
												}}
												checked={untimed.UNTIMED_OPTION_SO === 'Y'}
												onChange={() => onChangeUntimed('UNTIMED_OPTION_SO')}
										/>
										<div className='text-[#626576]'>
											Strike Out Options: You may eliminate incorrect answer options by using your
											right button to strike a line through an answer option.
										</div>
									</div>
									<div className='flex items-center'>
										<Checkbox
												sx={{
													color: grey[800],
													'&.Mui-checked': {
														color: grey[800],
													},
												}}
												checked={untimed.UNTIMED_OPTION_SS === 'Y'}
												onChange={() => onChangeUntimed('UNTIMED_OPTION_SS')}
										/>
										<div className='text-[#626576]'>
											Check Answers: You may check your answers as you work through the test by
											clicking on a “solution” button. A V or an X will appear next to the question.
										</div>
									</div>
									<div className='flex items-center'>
										<Checkbox
												sx={{
													color: grey[800],
													'&.Mui-checked': {
														color: grey[800],
													},
												}}
												checked={untimed.UNTIMED_OPTION_GS === 'Y'}
												onChange={() => onChangeUntimed('UNTIMED_OPTION_GS')}
										/>
										<div className='text-[#626576]'>
											Marked Guesses: When you choose this option, you can review the items you have
											marked after you have completed the test and you will see what percentage of
											your guesses were correct on your detailed score report. If you are using the
											Free Practice test, this option will not be recorded on your score report.
										</div>
									</div>
								</div>
								<div>
									<Button
											onClick={() => onSelectMode('U')}
											variant={mode === 'U' ? 'contained' : 'outlined'}
											className={
													'w-40 h-12 ' +
													(mode === 'U'
															? 'text-white bg-black border-0'
															: 'bg-slate-200 text-black border-0')
											}
									>
										{mode === 'U' ? 'Selected' : 'Select'}
									</Button>
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div className='w-full p-5 mb-20' style={{ backgroundColor: '#F1F2F3' }}>
					<div className='mb-5'>
						Once you have selected the mode and the options in which you wish to take the TOEFL
						Practice Test, click the <b>Start Test</b> Arrow. Remember, once you start the test, you
						MAY NOT return to this page to change the mode. If you wish to reconsider, click on{' '}
						<b>Return to Home Page.</b>
						Upon completion of the Practice Test, your score report will be displayed. You may return
						to the score report later from your TOEFL Practice Online Home Page.
					</div>
					<div className='w-full flex justify-end px-5'>
						<div className='mr-3'>
							<Button
									onClick={returnToMyPage}
									variant='outlined'
									className='bg-white border border-[#E1E2E5] text-black w-40 py-2'
							>
								Return to MyPage
							</Button>
						</div>
						<div>
							<Button
									onClick={() => {
										if (mode) {
											handleTestController();
										} else {
											setOpenSelectModeModal(true);
										}
									}}
									variant='contained'
									className='bg-black border  text-white w-40 py-2'
							>
								Start Test
							</Button>
						</div>
					</div>

					<SelectModeModal
							open={openSelectModeModal}
							handleClose={() => setOpenSelectModeModal(false)}
					/>
				</div>
			</>
	);
};

export default StartScreen;
