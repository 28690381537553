import { Box } from '@mui/material';

const AdjConfirm = () => {
	return (
			<Box className='w-full flex justify-center'>
				<Box className='w-2/4 mt-5'>
					<Box className='mt-5'>
						<p className='mb-5'>Your response time has ended. Stop speaking now.</p>
						<p className='mb-5'>Click on <span className='font-bold underline'>Playback Response</span> to hear your
							recording. Once you have heard your response, you may make any necessary adjustments to your microphone.
						</p>
						<p className='mb-5'>Click on <span className='font-bold underline'>Record Again</span> to go back to the
							question and immediately record your response again.</p>
						<p className='mb-5'>Click on <span className='font-bold underline'>Continue</span> to accept your current
							settings now and to continue on to the next screen.</p>
						<p className='mb-5'>Click on <span className='font-bold underline'>Pause</span> to pause the test. If you
							need to make any adjustments to your computer you will need to pause your test.</p>
						<p className='mb-5'>Please select your choice above.</p>
					</Box>
				</Box>
			</Box>
	);
};

export default AdjConfirm;
