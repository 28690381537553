import React from 'react';
import { Quill } from 'react-quill';


const CustomUndo = () => (
	<div className='w-28 bg-black rounded border-2 border-black text-white font-bold p-1'>
		Undo
	</div>
);

const CustomRedo = () => (
	<div className='w-28 bg-black rounded border-2 border-black text-white font-bold p-1'>
		Redo
	</div>
);

function pasteChange() {
	setTimeout(async () => {
		const text = await navigator.clipboard.readText();
		let mergeFieldText = text;
		var selection = this.quill.getSelection(true);
		this.quill.insertText(selection.index, mergeFieldText);
	}, 100);
}


function undoChange() {
	this.quill.history.undo();
}
function redoChange() {
	this.quill.history.redo();
}

function cutChange() {
	setTimeout(async () => {
		navigator.clipboard.writeText(window.getSelection().toString())
		const selection = this.quill.getSelection(true);
		const text =  window.getSelection().toString();
		this.quill.deleteText(selection.index, text.length);
	}, 100);
}

export const modules = {
	toolbar: {
		container: '#toolbar',
		handlers: {
			undo: undoChange,
			redo: redoChange,
			paste: pasteChange,
			cut: cutChange
		},
	},
	history: {
		delay: 500,
		maxStack: 100,
		userOnly: true,
	},
};

export const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'align',
	'strike',
	'script',
	'blockquote',
	'background',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
	'code-block',
];



// Quill Toolbar component
export const QuillToolbar = () => (
	<div className='border-0' id='toolbar'>
		<span className='ql-formats w-full'>
			<button className='ql-cut' style={{ width: 'auto', height: 'auto' }}>
				<div className='w-28 bg-black rounded border-2 border-black text-white font-bold p-1'>
					Cut
				</div>
			</button>
			<button className='ql-paste' style={{ width: 'auto', height: 'auto' }}>
				<div className='w-28 bg-black rounded border-2 border-black text-white font-bold p-1'>
					Paste
				</div>
			</button>
			<button className='ql-undo' style={{ width: 'auto', height: 'auto' }}>
				<CustomUndo />
			</button>
			<button className='ql-redo' style={{ width: 'auto', height: 'auto' }}>
				<CustomRedo />
			</button>

		</span>
	</div>
);

export default QuillToolbar;
