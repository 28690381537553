import TitleSection from '../../../../core/components/section/TitleSection';

const EndL1 = () => {
	return (
		<TitleSection title='Begin Part'>
			<div style={{ marginTop: '100px' }}>
				You will now begin the next part of the Listening Section.
			</div>
		</TitleSection>
	);
};

export default EndL1;
