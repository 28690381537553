import { Box } from '@mui/material';
import { useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import TitleSection from '../../../../core/components/section/TitleSection';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';

const SpeakingSectionDirection = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 1,
			});
		});

		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}

	useEffect(() => {
		renderAudio();
	}, []);

	return (
			<TitleSection title='Speaking Section Direction'>
				<Box>
					<p>
						In this section of the test, you will be able to demonstrate your ability to speak about a
						variety of topics. You will answer six questions by speaking into the microphone. Answer
						each of the questions as completely as possible.
					</p>
					<br />
					<p>
						In the first question, you will speak about a familiar topic. Your response will be scored
						on your ability to speak clearly and coherently about the topic.
					</p>
					<br />
					<p>
						In the next two questions, you will first read a short text. The text will go away and you
						will then listen to a talk on the same topic. You will then be asked a question about what
						you have read and heard. You will need to combine appropriate information from the text
						and the talk to provide a complete answer to the question. Your response will be scored on
						your ability to speak clearly and coherently and on your ability to accurately convey
						information about what you read and heard.
					</p>
					<br />
					<p>
						In the final question, you will listen to part of a lecture. You will then be asked a
						question about what you heard. Your response will be scored on your ability to speak
						clearly and coherently and on your ability to accurately convey information about what you
						heard.
					</p>
					<br />
					<p>
						You may take notes while you read and while you listen to the conversations and lectures.
						You may use your notes to help prepare your response.
					</p>
					<br />
					<p>
						Listen carefully to the directions for each question. The directions will not be written
						on the screen.
					</p>
					<br />
					<p>
						For each question you will be given a short time to prepare your response. A clock that is
						provided during the test will show how much preparation time is remaining. When the
						preparation time is up, you will be told to begin your response. A clock will show how
						much response time is remaining. A message will appear on the screen when the response
						time has ended.
					</p>
					<br />
					<p className='font-bold italic'>
						(Select Continue at any time to dismiss these directions.)
					</p>
				</Box>
				<AudioPlayer visible={false} />
			</TitleSection>
	);
};

export default SpeakingSectionDirection;
