import { Box } from '@mui/material';
import ProgressElement from './ProgressElement';

interface MicProgressBarProps {
	progress: number;
}

const MicProgressBar = ({
	progress = 0, //0~15
}: MicProgressBarProps) => {
	return (
		<Box>
			<Box className='flex gap-5'>
				{[0, 1, 2].map((idx) => (
					<div key={idx} className='flex gap-2'>
						{[idx * 5 + 1, idx * 5 + 2, idx * 5 + 3, idx * 5 + 4, idx * 5 + 5].map((idx2) => (
							<ProgressElement
								key={idx2}
								idx={idx2}
								progress={progress}
								color={progress === 0 ? 'none' : progress >= 6 && progress <= 10 ? 'good' : 'bad'}
							></ProgressElement>
						))}
					</div>
				))}
			</Box>
			<Box className='flex justify-around mt-4 gap-9 text-gray-500'>
				<span className={progress < 6 && progress >= 0 ? 'font-bold text-red-600' : ''}>
					Too Quiet
				</span>
				<span className={progress >= 6 && progress <= 10 ? 'font-bold text-green-600' : ''}>
					Good
				</span>
				<span className={progress >= 11 ? 'font-bold text-red-600' : ''}>Too Loud</span>
			</Box>
		</Box>
	);
};

export default MicProgressBar;
