import { Card } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import volume_icon from '../../../../../assets/icons/Volume.svg';
import volume_icon_x from '../../../../../assets/icons/Volume_x.svg';

import TitleSection from '../../../../core/components/section/TitleSection';
import { useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { setTracks } from '../../../../core/redux/AudioSlice';
import useHardwareConnect from '../../../../core/customhooks/useHardwareConnect';

export interface AudioProps {
	AUDIO_FILE: string;
	IMG_FILE: string;
	WAIT_TIME: number;
}

const HardwareCheckText = () => {
	const [isMicConnected, isSpeakerConnected] = useHardwareConnect();

	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	useEffect(() => {
		renderAudio();
	}, []);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 99999999,
			});
		});

		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}

	return (
			<Card className='w-full max-w-[860px] mx-auto px-20 pb-5 mt-10 rounded-xl'>
				<TitleSection title='Change the volume'>
					<div>
						<div className='flex flex-col gap-2 mb-7'>
							<li>
								To change the volume, click on the Volume icon at the top of the screen. The volume
								control will appear. Move the volume indicator to the left or the right to change the
								volume.
							</li>
							<li>To close the volume control, click on the Volume icon again.</li>
							<li>You will be able to change the volume during the test if you need to.</li>
						</div>

						<div className='flex items-center'>
							<img className='mr-4' src={isSpeakerConnected ? volume_icon : volume_icon_x} alt={'volume'} />
							<div>
								You may now change the volume. When you are finished select{' '}
								<span className='font-bold'>Continue</span>
							</div>
						</div>
					</div>
				</TitleSection>

				<AudioPlayer visible={false} />
			</Card>
	);
};

export default HardwareCheckText;
