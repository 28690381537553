import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import TitleSection from '../../../../core/components/section/TitleSection';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';

const WritingBaseOnKnowledgeAndExperienceDirections = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	const renderAudio = useCallback(
			() => {
				const fileList: any[] = content.ATTACH_FILE_LIST;
				const tempTracks: AudioProps[] = [];
				fileList.forEach((file) => {
					tempTracks.push({
						AUDIO_FILE: file.FILE_DATA,
						IMG_FILE: '',
						WAIT_TIME: 99999999,
					});
				});

				// setTracks(tempTracks);
				dispatch(setTracks(tempTracks));
			}, [content.ATTACH_FILE_LIST, dispatch]);

	useEffect(() => {
		if (content.ATTACH_FILE_LIST) {
			renderAudio();
		}
	}, [content.ATTACH_FILE_LIST, renderAudio]);

	return (
			<TitleSection title='Writing Based on Knowledge and Experience Directions'>
				<Box>
					<p>
						For this task, you will write an essay in response to a question that asks you to state,
						explain, and support your opinion on an issue. A clock that is provided during the test
						will show how much time you have to plan, write and revise your essay.
					</p>
					<br />
					<p>
						Typically, an effective essay will contain a minimum of 300 words. Your essay will be
						judged on the quality of your writing. This Includes the development of your ideas, the
						organization of your essay, and the quality and accuracy of the language you use to
						express your ideas.
					</p>
					<br />
					<div className='pl-2 mb-2'>
						<p>● Use specific reasons and examples to support your response.</p>
						<br />
						<p>● Be sure to use your own words. Do not use memorized reasons or examples.</p>
						<br />
						<p>● Responses that include memorized text will receive a lower score.</p>
					</div>
					<p>If you finish your essay before time is up, you may select Next to end this section.</p>
					<br />
					<p>
						Select <strong>Continue</strong> to go on.
					</p>
				</Box>
				<AudioPlayer visible={false} />
			</TitleSection>
	);
};

export default WritingBaseOnKnowledgeAndExperienceDirections;
