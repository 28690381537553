import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { setAnswer, toeflSelector } from '../../../core/redux/ToeflSlice';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import * as _ from 'lodash';
import './survey.scss';

interface ArrayProps {
	QNOSEQ: string;
	CHECKNO: string[];
}

const Survey = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	const [answerArr, setAnswerArr] = useState<ArrayProps[]>([]);

	const handleChangeAnswerMSMC = (event: any, QNOSEQ: string) => {
		const ITEM_SEQ = event.target.value;
		const existAns = answerArr.findIndex((item: ArrayProps) => item.QNOSEQ === QNOSEQ);
		let temp = _.cloneDeep(answerArr);

		if (existAns === -1) {
			setAnswerArr(prevState => [...prevState, { QNOSEQ: QNOSEQ, CHECKNO: [ITEM_SEQ] }]);
		} else {
			const checkNoIdx = temp[existAns].CHECKNO.findIndex(val => val === ITEM_SEQ);
			if (checkNoIdx !== -1) {
				temp[existAns].CHECKNO.splice(checkNoIdx, 1);
				setAnswerArr(temp);
			} else {
				temp[existAns].CHECKNO = [...temp[existAns].CHECKNO, ITEM_SEQ];
				setAnswerArr(temp);
			}
		}
	};

	const handleChangeAnswer = (value: ArrayProps) => {
		const existAns = answerArr.findIndex((item: ArrayProps) => item.QNOSEQ === value.QNOSEQ);
		let temp = _.cloneDeep(answerArr);

		if (existAns === -1) {
			setAnswerArr(prevState => [...prevState, value]);
		} else {
			temp[existAns].CHECKNO = value.CHECKNO;
			setAnswerArr(temp);
		}
	};

	const checkSSMCQuestion = (QNOSEQ: string, item: string): boolean => {
		const idx = answerArr.findIndex((items: ArrayProps) => items.QNOSEQ === QNOSEQ);

		if (idx !== -1) {
			return answerArr[idx].CHECKNO.includes(item);
		} else {
			return false;
		}
	};

	const checkMSMCQuestion = (QNOSEQ: string, item: string): boolean => {
		const idx = answerArr.findIndex((items: ArrayProps) => items.QNOSEQ === QNOSEQ);

		if (idx !== -1) {
			return answerArr[idx].CHECKNO.includes(item);
		} else {
			return false;
		}
	};

	useEffect(() => {
		dispatch(setAnswer({ ANSWER: answerArr }));
	}, [answerArr, dispatch]);


	const survey_qno_list = (val: any, index: number) => {
		if (val.QNOTYPE === 'MSMC') {
			return (
					<Box className='my-10 survey_question'>
						<Box className='flex mb-4'>
							<Typography className='mr-2'>{index + 1}.</Typography>
							<Typography>{val.QNOCONT}</Typography>
						</Box>
						{
							val.QNO_ITEMLIST.map((item: any, itemIdx: number) => {
								return <div key={itemIdx} className='flex items-center mb-2 pl-10'>
									<Checkbox
											onChange={(event) => handleChangeAnswerMSMC(event, val.QNOSEQ)}
											checked={checkMSMCQuestion(val.QNOSEQ, item.QNOITEMSEQ)}
											value={item.QNOITEMSEQ}
											name='radio-buttons'
											sx={{
												color: grey[800],
												'&.Mui-checked': {
													color: grey[800],
												},
											}}
											checkedIcon={<DisabledByDefaultIcon />}
									/>

									<div>{item.ITEMCONT}</div>
								</div>;
							})
						}
					</Box>
			);
		} else {
			return (
					<Box className='my-10 survey_question'>
						<Box className='flex mb-4'>
							<Typography className='mr-2'>{index + 1}.</Typography>
							<Typography>{val.QNOCONT}</Typography>
						</Box>
						{
							val.QNO_ITEMLIST.map((item: any, itemIdx: number) => {
								return (
										<div key={itemIdx} className='flex items-center mb-2 pl-10'>
											<div
													onClick={() => handleChangeAnswer({ QNOSEQ: val.QNOSEQ, CHECKNO: [item.QNOITEMSEQ] })}
													className={
														checkSSMCQuestion(val.QNOSEQ, item.QNOITEMSEQ)
																? 'answer-circle-checkbox-active'
																: 'answer-circle-checkbox'
													}
											></div>
											<div style={{ width: '90%' }}>
												{item.ITEMCONT}
											</div>
										</div>
								);
							})
						}

					</Box>
			);
		}
	};

	const qno_list = content.QNO_LIST as Array<any>;
	return (
			<Box className='w-full max-w-[860px] mx-auto mt-10 mb-5 survey'>
				{qno_list.map((value: any, index: number) => {
					return survey_qno_list(value, index);
				})}
			</Box>
	);
};

export default Survey;