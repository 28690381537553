import { Box, Button, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import { ButtonEnum } from '../../constants/button.enum';
import './CButton.scss';

interface CButtonProps {
	text: string;
	icon: any;
	variant?: 'text' | 'outlined' | 'contained' | undefined;
	color?: string;
	background?: string;
	className?: string | undefined;
	disabled?: boolean;
	disabledStyle?: boolean;
	width?: string;
	materialIcon?: boolean;
	isBlack?: boolean;
	isLong?: boolean;
	isGuessBtn?: boolean;
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const CButton = ({
	                 text,
	                 icon,
	                 color,
	                 background = 'linear-gradient(180deg, #5495E2 0%, #0F4C95 100%)',
	                 variant = 'text',
	                 isBlack = false,
	                 disabled = false,
	                 disabledStyle = false,
	                 materialIcon = false,
	                 width = '108px',
	                 isLong = false,
	                 isGuessBtn = false,
	                 onClick,
                 }: CButtonProps) => {
	const convertEnumToText = (text: string): string => {
		switch (text) {
			case ButtonEnum.ANSWER:
				return 'Answer';
			case ButtonEnum.CONFIRM_RESPONSE:
			case ButtonEnum.CONFIRM_RESPONSE_DISABLED:
				return 'Confirm Response';
			case ButtonEnum.GUESS:
				return 'Guess';
			case ButtonEnum.HELP:
			case ButtonEnum.HELP_DISABLED:
				return 'Help';
			case ButtonEnum.OK:
			case ButtonEnum.OK_DISABLED:
				return 'OK';
			case ButtonEnum.PAUSE:
				return 'Pause';
			case ButtonEnum.SECTION_EXIT:
				return 'Section Exit';
			case ButtonEnum.PROG_REVIEW:
			case ButtonEnum.PROG_REVIEW_DISABLED:
				return 'Review';
			case ButtonEnum.PROG_BACK:
			case ButtonEnum.PROG_BACK_DISABLED:
				return 'Back';
			case ButtonEnum.PROG_NEXT:
			case ButtonEnum.PROG_NEXT_DISABLED:
				return 'Next';
			case ButtonEnum.PROG_CONTINUE:
				return 'Continue';
			case ButtonEnum.REPLAY_TALK:
			case ButtonEnum.REPLAY_TALK_DISABLED:
				return 'Replay Talk';
			case ButtonEnum.STOP_TALK:
				return 'Stop Talk';
			case ButtonEnum.PLAYBACK:
			case ButtonEnum.PLAYBACK_DISABLED:
				return 'Playback Response';
			case ButtonEnum.RECORD_AGAIN:
			case ButtonEnum.RECORD_AGAIN_DISABLED:
				return 'Record Again';
			case ButtonEnum.RETURN_QUESTION:
				return 'Return to Question';
			case ButtonEnum.RETURN:
				return 'Return';
			case ButtonEnum.VOLUME:
				return 'Volume';
			case ButtonEnum.PRINT:
				return 'Print';
			case ButtonEnum.EXIT:
				return 'Exit';
			case 'Next_test':
				return 'Next(Test)';
			default:
				return '';
		}
	};

	const renderTopBarBtn = () => {
		if (isGuessBtn) {
			return <Button
					variant={variant}
					className={'topbar-btn'}
					disabled={disabled}
					disableRipple={disabled}
					onClick={onClick}
					sx={{
						background: disabledStyle
								? 'linear-gradient(180deg, #D5D5D5 0%, #656565 100%)'
								: background,
						borderRadius: '8px',
						width: isLong ? '120px' : width,
						height: '65px',
						opacity: disabledStyle === true ? '0.5' : '1',
						border: '1px solid #8FBFF8',
					}}
			>
				<Box className={`flex flex-col items-center`}>
					<Typography
							className='self-center text-[14px] w-fit'
							sx={{ color: 'white' }}
							variant='subtitle1'
					>
						{convertEnumToText(text)}
					</Typography>
					{materialIcon ? icon : <img src={icon} alt='icon' className='self-center w-5' />}
				</Box>
			</Button>;
		} else {
			return <Button
					variant={variant}
					className={'topbar-btn'}
					disabled={disabled}
					disableRipple={disabled}
					onClick={onClick}
					sx={{
						background: isBlack
								? 'linear-gradient(180deg, #5B5A5A 0%, #272727 100%)'
								: background,
						borderRadius: '8px',
						width: isLong ? '120px' : width,
						height: '65px',
						opacity: disabledStyle === true ? '0.5' : '1',
						border: '1px solid #8FBFF8',
					}}
			>
				<Box className={`flex flex-col items-center`}>
					<Typography
							className='self-center text-[14px] w-fit'
							sx={{ color: 'white' }}
							variant='subtitle1'
					>
						{convertEnumToText(text)}
					</Typography>
					<Box sx={{ color: 'white' }}>
						{materialIcon ? icon : <img src={icon} alt='icon' className='self-center w-5' />}
					</Box>
				</Box>
			</Button>;
		}
	};

	return renderTopBarBtn();
};

export default CButton;
