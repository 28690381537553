import { Outlet } from 'react-router-dom';
import Footer from '../../footer/Footer';
import TopBar from '../../topbar/TopBar';

const TestLayout = () => {
	return (
		<div className='flex flex-col h-screen'>
			<TopBar />
			<div className='w-full max-w-[128rem] mx-auto overflow-auto flex-1'>
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export default TestLayout;
