import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../../../core/customhooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { setHighlights, toeflSelector, updateReadingCurrentPassageStep } from '../../../core/redux/ToeflSlice';
import './reading-passage.scss';
import { updateScrollBottom } from '../../../core/redux/TopBarSlice';
import { ControlType, FlowControlName } from '../../../core/constants/type.enum';

const ReadingPassage = () => {
	const {
		content,
		highlights,
		reading: { current_passage_step, reading_content },
	} = useAppSelector(toeflSelector);
	const { height } = useWindowDimensions();
	const dispatch = useAppDispatch();
	const paraRef = useRef(null);
	const [paraString, setParaString] = useState('');
	const [passageTitle, setPassageTitle] = useState<string>('');

	const onScroll = () => {
		if (paraRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = paraRef.current;

			if (content.FLOW_CNTL_NAME === FlowControlName.Reading && content.CONTROL_TYPE === ControlType.C3) {
				dispatch(updateScrollBottom(true));
			} else {
				if (scrollTop + clientHeight >= scrollHeight) {
					dispatch(updateScrollBottom(true));
				}
			}
		}
	};

	const scrollableContent = useCallback(() => {
		if (paraRef.current) {
			const { scrollHeight, clientHeight } = paraRef.current;
			const hasScrollableContent = scrollHeight > clientHeight;

			if (hasScrollableContent) {
				dispatch(updateScrollBottom(false));
			} else {
				dispatch(updateScrollBottom(true));
			}
		}
	}, [dispatch]);

	const renderPara = useCallback(() => {
		let paraString = '';
		let paraCont: any;
		if (content.FLOW_CNTL_NAME === FlowControlName.Reading && content.CONTROL_TYPE === ControlType.C3) {
			paraCont = reading_content;
		} else {
			paraCont = content;
		}

		paraCont.PASSAGE.PARA_LIST.map((para: any) => {
			return (paraString += para.PARA_SENTENCE + '<br/><br/>');
		});

		paraString = paraString.replace(/<(?!br\s*\/?)[^>]+>/g, '');//remove tag element without <br> tag
		paraString = paraString.replace(/(?:\r\n|\r|\n)/g, '');//remove break down line
		const txt = document.createElement('textarea');
		txt.innerHTML = paraString;
		paraString = txt.value;

		setParaString(paraString);
		if (current_passage_step !== paraCont.CURRENT_PASSAGE_STEP) {
			localStorage.removeItem('para-scroll');
			dispatch(updateReadingCurrentPassageStep(paraCont.CURRENT_PASSAGE_STEP));
		}
	}, [content, current_passage_step, dispatch, reading_content]);

	useEffect(() => {
		if (content.FLOW_CNTL_NAME === FlowControlName.Reading && content.CONTROL_TYPE === ControlType.C3) {
			setPassageTitle(reading_content.PASSAGE.TITLE);
		} else {
			setPassageTitle(content.PASSAGE.TITLE);
		}
	}, [content, dispatch, reading_content]);


	useEffect(() => {
		renderPara();
	}, [renderPara]);

	useEffect(() => {
		setTimeout(() => {
			scrollableContent();
		}, 700);
	}, [scrollableContent, height]);


	async function onMouseUpEvent(e: any) {
		if (content.TEST_MODE === 'T') {
			return;
		}
		let str: any = window.getSelection()?.toString();

		const check = await onCheckDuplicate(str);
		if (check) {
			const strWithoutBreak = str.replace(/(?:\r\n|\r|\n)/g, ''); //remove break down line
			let tempCheck = check.replace(strWithoutBreak, str);
			tempCheck = tempCheck.replace(/(?:\r\n|\r|\n)/g, '<br/>');//add break down line

			// remove hightlight
			const paraStringHighlight = paraString.replace(`<span class='highlight-words'>${tempCheck}</span>`, tempCheck);
			setParaString(paraStringHighlight);

			// re-set hightlights
			const newHightlights = highlights.filter((highlight: any) => highlight.TEXT !== check);
			dispatch(setHighlights(newHightlights));
		} else {
			let tempParaString = paraString;
			tempParaString = tempParaString.replace(/<[^>]+>/g, ''); //remove all tag element

			// const Tempstr = str.replace(/(?:\r\n|\r|\n)/g, ''); //remove break down line
			if (str.length) {
				const indexOfStr = tempParaString.indexOf(str);
				dispatch(setHighlights([...highlights,
					{
						START_WORD_NO: indexOfStr.toString(),
						END_WORD_NO: (indexOfStr + str.length).toString(),
						TEXT: str,
					}]));

				str = str.replace(/(?:\r\n|\r|\n)/g, '<br/>');//add break down line
				const paraStringHighlight = paraString.replace(str, `<span class='highlight-words'>${str}</span>`);
				setParaString(paraStringHighlight);
			}
		}
	}

	function onCheckDuplicate(str: any) {
		str = str.replace(/(?:\r\n|\r|\n)/g, '');
		for (const hightlight of highlights) {

			const text: any = hightlight.TEXT.replace(/(?:\r\n|\r|\n)/g, '');
			if (text.includes(str)) {
				return hightlight.TEXT;
			}
		}
		return false;
	}


	return (
			<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					className='w-full h-full relative flex'
			>
				<div className='w-2/4 p-2'></div>
				<div className='w-2/4 h-full overflow-auto border-l-2 p-2' ref={paraRef} onScroll={onScroll}>
					<div
							className='w-full text-center font-bold my-3'
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: passageTitle }}
					></div>
					{/*<div onMouseUp={onMouseUpEvent}>*/}
					<div
							onMouseUp={onMouseUpEvent}
							contentEditable='false'
							dangerouslySetInnerHTML={{ __html: paraString }}
					></div>
					{/*</div>*/}
				</div>

			</motion.div>
	);
};

export default ReadingPassage;
