import React from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Button from '@mui/material/Button';

interface DialogProps {
	onClose: () => void,
}

const WritingEndedDialog = ({ onClose }: DialogProps) => {
	return (
			<>
				<DialogTitle
						className='py-3 w-full'
						sx={{ backgroundColor: '#B5EDFF', color: '#55737C' }}
						id='alert-dialog-title'
				>
					Writing time expired
				</DialogTitle>
				<DialogContent className='px-4 pt-4'>
					<Box className={'px-24 py-10 border-b-2 border-slate-200'}>
						<Typography>Your time for answering this question has ended.</Typography>
						<br />
						<Typography>Your response has been saved.</Typography>
						<br />
						<Typography>Select Continue to go in.</Typography>
					</Box>
				</DialogContent>
				<DialogActions className='pb-6 px-4'>
					<Button
							variant='contained'
							sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
							color='primary'
							onClick={onClose}
					>
						<p className='text-white'>Continue</p>
					</Button>
				</DialogActions>
			</>
	);
};

export default WritingEndedDialog;
