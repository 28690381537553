import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { userSelector } from '../../redux/AuthSlice';
import { onExpiredTime, onTestController, toeflSelector, updateExpired } from '../../redux/ToeflSlice';
import './CountDown.scss';
import minus_circle_icon from '../../../../assets/icons/Minus-circle.svg';
import { Box, Button } from '@mui/material';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { unwrapResult } from '@reduxjs/toolkit';

const CountdownRemainTime = () => {
	const dispatch = useAppDispatch();
	const { data, expired_time } = useAppSelector(toeflSelector);
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);

	const [showTime, setShowTime] = useState<boolean>(false);

	const renderer = ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
		if (completed) {
			setTimeout(() => {
				if (expired_time <= Date.now()) {
					dispatch(updateExpired(true));
				}
			}, 1000);
			return <span className='self-center text-[#313131] font-bold'>00:00:00</span>;
		} else {
			// Render a countdown
			return (
					<span className='self-center text-[#313131] font-bold'>
					{hours !== 0 ? (hours < 10 ? `0${hours}` : hours) : `00`}:
						{minutes !== 0 ? (minutes < 10 ? `0${minutes}` : minutes) : `00`}:
						{seconds !== 0 ? (seconds < 10 ? `0${seconds}` : seconds) : `00`}
				</span>
			);
		}
	};

	useEffect(() => {
	}, [expired_time]);

	return (
			<Box className='self-center flex justify-center'>
				{showTime ? <Countdown date={expired_time} renderer={renderer} /> : null}
				<Button
						onClick={() => setShowTime(!showTime)}
						variant='text'
						sx={{ color: '#313131' }}
						className='flex justify-center self-center'
				>
					<img src={minus_circle_icon} alt='minus-icon' className='pr-1' />
					{showTime ? 'Hide Time' : 'Show Time'}
				</Button>
			</Box>
	);
};

/* const ExpiredNotice = () => {
	const [showTime, setShowTime] = useState<boolean>(false);

	return (
			<Box className='self-center flex justify-center'>
				<p className='self-center text-[#313131] font-bold'>00:00:00</p>
				<Button
						onClick={() => setShowTime(!showTime)}
						variant='text'
						sx={{ color: '#313131' }}
						className='flex justify-center self-center'
				>
					<img src={minus_circle_icon} alt='minus-icon' className='pr-1' />
					{showTime ? 'Hide Time' : 'Show Time'}
				</Button>
			</Box>
	);
};

const ShowCounter = ({ hours, minutes, seconds }: ShowCounterProps) => {
	const [showTime, setShowTime] = useState<boolean>(false);
	return (
			<Box className='self-center flex justify-center'>
				{showTime ? <Box className='flex justify-center items-center font-bold text-base' style={{ color: '#000' }}>
					<DateTimeDisplay value={hours} />
					<p>:</p>
					<DateTimeDisplay value={minutes} />
					<p>:</p>
					<DateTimeDisplay value={seconds} />
				</Box> : null}
				<Button
						onClick={() => setShowTime(!showTime)}
						variant='text'
						sx={{ color: '#313131' }}
						className='flex justify-center self-center'
				>
					<img src={minus_circle_icon} alt='minus-icon' className='pr-1' />
					{showTime ? 'Hide Time' : 'Show Time'}
				</Button>
			</Box>
	)
			;
};

const CountdownTimer = () => {
	const dispatch = useAppDispatch();
	const { data } = useAppSelector(toeflSelector);
	const { expired_time } = useAppSelector(toeflSelector);
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);
	const { isPlaying, isAudioEnded } = useAppSelector(audioSelector);
	const { time: { days, hours, minutes, seconds }, handleStart, handlePause } = useCountdown(expired_time);

	const handleExpireRemainTime = useCallback(
			() => {
				const body = {
					HEADER: {
						SESSION,
						USER_ID,
					},
					BODY: {
						TEST_CODE: data.test_code,
					},
				};
				dispatch(onExpiredTime(body));
			}, [SESSION, USER_ID, data.test_code, dispatch]);

	useEffect(() => {
		if (isPlaying) {
			handlePause();
		}
	}, [handlePause, isPlaying]);

	useEffect(() => {
		if (isAudioEnded) {
			handleStart();
		}
	}, [handleStart, isAudioEnded]);

	useEffect(() => {
		if (days + hours + minutes + seconds <= 0) handleExpireRemainTime();
	}, [handleExpireRemainTime]);


	return (
			(days + hours + minutes + seconds <= 0) ? <ExpiredNotice /> :
					<ShowCounter hours={hours} minutes={minutes} seconds={seconds} />
	);
}; */

export default CountdownRemainTime;
