import ApiService from '../services/api.service';

const API_URL = process.env.REACT_APP_BASE_URL;

class TestRepository {
	async onTestStart(body: object) {
		try {
			return await ApiService.POST('/mypage/test/teststart.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onTestController(body: object) {
		try {
			return await ApiService.POST('/volume/testController.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onSectionTest(body: object) {
		try {
			return await ApiService.POST('/volume/sectionExit.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onPauseTest(body: object) {
		try {
			return await ApiService.POST('/volume/pauseTest.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onRequestReview(body: object) {
		try {
			return await ApiService.POST('/volume/review.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onSaveCurrentStatus(body: object) {
		try {
			return await ApiService.POST('/volume/saveCurrentStatus.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onExpiredTime(body: object) {
		try {
			return await ApiService.POST('/volume/expireTime.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async onReplayTalk(body: object) {
		try {
			return await ApiService.POST('/volume/replayTalk.svc', body);
		} catch (error) {
			throw error;
		}
	}

	async nextAfterRecording(body: object) {
		try {
			return await ApiService.POST('/volume/uploadSpeaking.svc', body);
		} catch (error) {
			throw error;
		}
	}

	onSessionEnded() {
		localStorage.clear();
	}
}

export default TestRepository;
