import { Box } from '@mui/material';
import TitleSection from '../../../../core/components/section/TitleSection';
const SectionDirections = () => {
	return (
		<TitleSection title='Writing Section Directions'>
			<Box>
				<p className='font-bold'>Make sure your headset is on.</p>
				<br />
				<p>
					This section is measures your ability to use writing to communicate in an academic
					environment. There will be two writing tasks.
				</p>
				<br />
				<p>
					For the first writing task, you will read a passage and listen to a lecture and then
					answer a question based on what you have read and heard. For the second writing task, you
					will answer a question based on your own knowledge and experience.
				</p>
				<br />
				<p>Now listen to the directions for the first writing task.</p>
				<br />
				<p className='font-bold'>(Select Continue at any time to dismiss these directions.)</p>
			</Box>
		</TitleSection>
	);
};

export default SectionDirections;
