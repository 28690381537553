import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import {
	AppBar,
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Paper,
	styled,
	Typography,
} from '@mui/material';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { unwrapResult } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import answer_icon from '../../../../assets/icons/answer.svg';
import return_icon from '../../../../assets/icons/Arrow-line-left.svg';
import back_icon from '../../../../assets/icons/Arrow-transparent-left.svg';
import next_icon from '../../../../assets/icons/Arrow-transparent-right.svg';
import continue_icon from '../../../../assets/icons/Arrow-line-right.svg';
import ok_icon from '../../../../assets/icons/check.svg';
import logo from '../../../../assets/icons/G-LOGO.svg';
import replay_talk_icon from '../../../../assets/icons/G-ReplayTalk.svg';
import help_icon from '../../../../assets/icons/help-circle.svg';
import section_exit_icon from '../../../../assets/icons/log-out-04.svg';
import pause_icon from '../../../../assets/icons/pause-circle.svg';
import return_question_icon from '../../../../assets/icons/return-circle.svg';
import review_icon from '../../../../assets/icons/review.svg';
import volume_icon from '../../../../assets/icons/volume-max.svg';
import x_icon from '../../../../assets/icons/X.svg';
import { useDialog } from '../../../utils/context/DialogContext';
import CButton from '../../components/Button/CButton';
import CountdownRemainTime from '../../components/countdown/CountdownRemainTime';
import PauseModal from '../../components/modals/PauseModal';
import ScrollBottomModal from '../../components/modals/ScrollBottomModal';
import { ButtonEnum } from '../../constants/button.enum';
import { FlowControlName } from '../../constants/type.enum';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { audioSelector, resetAudioFile, resetAudioState, updateVolumeCntl } from '../../redux/AudioSlice';
import { userSelector } from '../../redux/AuthSlice';
import {
	onExpiredTime,
	onFetchCurrentPassage,
	onPlayBackResponseRequest,
	onReplayTalk,
	onTestController,
	RejectType,
	resetAnswer,
	resetGuessAnswer,
	resetHighlights,
	resetStrikeout,
	saveCurrentStatus,
	toeflSelector,
	updateExpired,
	updateExpiredTime,
	updateSectionID,
} from '../../redux/ToeflSlice';
import {
	resetTopBarState,
	toggleMSCTViewMode,
	topBarSelector,
	updateGuessMode,
	updateNextBtn,
	updateShowAnswer,
} from '../../redux/TopBarSlice';
import TestRepository from '../../repositories/TestRepository';
import EndSessionModal from '../../components/modals/EndSessionModal';
import SSMCDialog from '../../components/modals/SSMCDialog';
import MustAnswerCompleteDialog from '../../components/modals/MustAnswerDialog';
import { useReactToPrint } from 'react-to-print';
import './TopBar.scss';
import getBlobDuration from 'get-blob-duration';
import alert_icon from '../../../../assets/icons/G-Alert.svg';
import useFullScreen from '../../customhooks/useFullScreen';

const TopBar = () => {
	const appDispatch = useAppDispatch();
	const navigate = useNavigate();
	const [openDialog, closeDialog] = useDialog();
	const [open, closeFullScreen] = useFullScreen(document.documentElement);

	const {
		data,
		answer,
		content,
		testName,
		expired_time,
		strikeout,
		printComponent,
		highlights,
		guess_answer,
		expired,
	} = useAppSelector(toeflSelector);
	const {
		guessMode,
		enabledNextBtn,
		scrollBottom,
		showAnswer,
		MSCTViewMode,
	} = useAppSelector(topBarSelector);
	const { volumeCntl, isPlaying, audioFile } = useAppSelector(audioSelector);
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);
	const [openPauseModal, setOpenPauseModal] = useState<boolean>(false);
	const [openScrollBottomModal, setOpenScrollBottomModal] = useState<boolean>(false);

	const [volume, setVolume] = useState<number>(volumeCntl * 100);
	const [isVolumeModalHide, setIsVolumeModalHide] = useState<boolean>(true);
	const [okBtn, setOkBtn] = useState<boolean>(false);

	/* Playback response variable*/
	const vidRef = useRef<HTMLVideoElement>(null);
	const [blobLength, setBlobLength] = useState(0);
	const [isPlayRecording, setIsPlayRecording] = useState(false);
	const firstStart: any = useRef(true);
	const tick: any = useRef();

	const testRepository = new TestRepository();

	const isHaveButton = (btnName: string): boolean => {
		let exist: number;
		if (isArray(content.BUTTON_LIST)) {
			exist = content.BUTTON_LIST.findIndex((val: string) => val === btnName);
			return exist !== -1;
		} else {
			return false;
		}
	};

	const setSectionExpireTime = (result: any) => {
		if (data.test_mode === 'T' && result.SECTION_ID === data.section_id) {
			if (result.REMAIN_TIME === 0 && data.remain_time === 0) {
				//do nothing
			} else if (result.REMAIN_TIME > 0 && data.remain_time === 0) {
				appDispatch(updateExpiredTime(Date.now() + result.REMAIN_TIME * 1000));
			} else if (result.REMAIN_TIME > 0 && data.remain_time > 0) {
				//do nothing
			} else if (result.REMAIN_TIME === 0 && data.remain_time > 0) {
				//do nothing
			}
		} else if (data.test_mode === 'T' && result.SECTION_ID !== data.section_id) {
			appDispatch(updateSectionID(result.SECTION_ID));
			if (result.REMAIN_TIME === 0 && data.remain_time === 0) {
				//do nothing
			} else if (result.REMAIN_TIME > 0 && data.remain_time === 0) {
				appDispatch(updateExpiredTime(Date.now() + result.REMAIN_TIME * 1000));
			} else if (result.REMAIN_TIME > 0 && data.remain_time > 0) {
				//do nothing
			} else if (result.REMAIN_TIME === 0 && data.remain_time > 0) {
				//do nothing
			}
		}
	};

	const handleVolumeChange = (event: Event, newValue: number | number[]) => {
		setVolume(newValue as number);
		appDispatch(updateVolumeCntl((newValue as number) / 100));
	};

	/* Section Pause button handle */
	const onPauseButton = () => {
		setOpenPauseModal(true);
	};
	const onConfirmPauseModal = async () => {
		const pause = await testRepository.onPauseTest({
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		});

		if (pause.RESPONSE.CODE === '1000') {
			navigate('/my-page', { replace: true });
			await closeFullScreen();
		}
	};
	/* End section */

	/* Section exit handle */
	const onSectionExit = async () => {
		const section = await testRepository.onSectionTest({
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		});

		appDispatch(onTestController({ body: section }));
	};
	/* End section */

	/* Section Continue handle */
	const onContinue = async () => {
		if (content.FLOW_CNTL_NAME === FlowControlName.설문조사) {
			await surveyContinue();
		} else {
			await testContinue();
		}
	};

	const openMSGRIDialog = () => {
		openDialog({
			children: (
					<>
						<DialogTitle
								className='py-3 font-bold flex'
								sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
								id='alert-dialog-title'
						>
							<img src={alert_icon} alt='alert_icon' className='mr-2' /> Required Answer
						</DialogTitle>
						<DialogContent className='px-4 pt-4'>
							<p>You must select the EXACT number of choices before you can leave this question.</p>
							<br />
							<p>Refer to the directions.</p>
						</DialogContent>
						<DialogActions className='pb-6 px-4'>
							<Button
									variant='contained'
									sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
									color='primary'
									onClick={closeDialog}
							>
								<p className='text-white'>Return to Question</p>
							</Button>
						</DialogActions>
					</>
			),
		});
	};

	const testContinue = async () => {
		const body: any = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				CURRENT_STEP: data.curr_step,
				TEST_MODE: data.test_mode,
			},
		};

		if (content.QNOTYPE === 'MSGRID') {
			if (answer.ANSWER.length < content.ROW_LIST.length - 1) {
				openMSGRIDialog();
				return;
			}
		}

		if (Object.keys(answer).length > 0) {
			body.BODY = { ...body.BODY, ...answer };
		}

		if (guessMode) {
			body.BODY = { ...body.BODY, ...guess_answer };
		}

		// if (Object.keys(strikeout).length > 0) {
		// 	body.BODY = { ...body.BODY, ...strikeout };
		// }
		//
		// if (highlights.length) {
		// 	body.BODY.highlights = highlights;
		// }

		if (data.flow_cntl_name === 'Reading' && scrollBottom === false) {
			setOpenScrollBottomModal(true);
			return;
		}

		if (content.TEST_MODE === 'T') {
			switch (content.FLOW_CNTL_NAME) {
				case FlowControlName.Listening1_question:
				case FlowControlName.Listening2_question:
					if (
							content.QNOTYPE === 'SSMC' ||
							content.QNOTYPE === 'MSMC' ||
							content.QNOTYPE === 'MSOM'
					) {
						appDispatch(updateNextBtn(false));
						setOkBtn(true);
					}
					return;
			}
		}

		const onRejected = (data: RejectType) => {
			openEndSessionDialog();
		};

		appDispatch(resetTopBarState());
		appDispatch(resetAudioState());

		try {
			const done = await appDispatch(onTestController({ body, onRejected }));
			if (done.meta.requestStatus === 'fulfilled') {
				const { content } = unwrapResult(done);
				setSectionExpireTime(content);
				appDispatch(resetAnswer({}));
				appDispatch(resetHighlights([]));
				appDispatch(resetStrikeout({}));
				appDispatch(resetGuessAnswer({}));
			}
		} catch (error) {
			console.log(error);
		}
	};

	const surveyContinue = async () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				CURRENT_STEP: data.curr_step,
				TEST_ID: content.TEST_ID,
			},
		};

		if (Object.keys(answer).length > 0) {
			body.BODY = { ...body.BODY, ...answer };
		}

		const onRejected = (data: RejectType) => {
			openEndSessionDialog();
		};

		appDispatch(resetTopBarState());
		appDispatch(resetAudioState());

		try {
			const done = await appDispatch(onTestController({ body, onRejected }));
			if (done.meta.requestStatus === 'fulfilled') {
				appDispatch(resetAnswer({}));
			}
		} catch (error) {
			console.log(error);
		}
	};
	/* End section */

	/* Section OK Click */
	const handleOKClick = async () => {
		let ans: number[] = [];
		let ansLength = 0;

		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};

		if (Object.keys(answer).length > 0) {
			ans = answer.ANSWER as Array<number>;
		}

		if (Object.keys(answer).length > 0) {
			body.BODY = { ...body.BODY, ...answer };
		}

		if (content.QNOTYPE === 'MSMC') {
			ansLength = (content.CHOICE_LIST as Array<any>).filter(
					(val: any) => val.CORRECT_ANSWER_YN === 'Y',
			).length;
		}

		if (content.QNOTYPE === ('SSMC' || 'MSOM') && ans.length === 0) {
			openSSMCDialog();
			appDispatch(updateNextBtn(true));
			setOkBtn(false);
			return;
		} else if (content.QNOTYPE === 'MSMC' && ans.length === 0) {
			openMustAnswerCompleteDialog();
			appDispatch(updateNextBtn(true));
			setOkBtn(false);
			return;
		} else if (content.QNOTYPE === 'MSMC' && ans.length > 0 && ans.length < ansLength) {
			openMustAnswerCompleteDialog();
			appDispatch(updateNextBtn(true));
			setOkBtn(false);
			return;
		} else {
			appDispatch(updateNextBtn(true));
			setOkBtn(false);
		}

		const onRejected = (data: RejectType) => {
			openEndSessionDialog();
		};

		appDispatch(resetTopBarState());
		appDispatch(resetAudioState());
		try {
			const done = await appDispatch(onTestController({ body, onRejected }));
			if (done.meta.requestStatus === 'fulfilled') {
				const { content } = unwrapResult(done);
				setSectionExpireTime(content);
				appDispatch(resetAnswer({}));
			}
		} catch (e) {
			console.log(e);
		}
	};
	/* End section */

	/* Section Back button handle */
	const onBackToPrevious = () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: content.PREV_STEP,
				CURRENT_STEP: data.curr_step,
			},
		};

		appDispatch(resetTopBarState());
		appDispatch(resetAudioState());
		appDispatch(onTestController({ body }));
	};
	/* End section */

	/* Section save current status */
	const handleSaveCurrentStatus = useCallback(() => {
		let body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: content.TEST_CODE,
				SECTION_ID: content.SECTION_ID,
				CURRENT_STEP: content.CURRENT_STEP,
				REMAIN_TIME: content.TEST_MODE === 'T' ? Math.ceil((expired_time - Date.now()) / 1000) : 0,
				ANSWER: [],
				GUESS_ANSWER: [],
				STRIKEOUT: [],
				HIGHLIGHT: [],
			},
		};


		if (Object.keys(answer).length > 0) {
			body.BODY = { ...body.BODY, ...answer };
		}

		if (guessMode) {
			body.BODY = { ...body.BODY, ...guess_answer };
		} else {
			body.BODY = { ...body.BODY, GUESS_ANSWER: [] };
		}

		appDispatch(saveCurrentStatus(body));
	}, [SESSION, USER_ID, answer, appDispatch, content.CURRENT_STEP, content.SECTION_ID, content.TEST_CODE, content.TEST_MODE, expired_time, guessMode, guess_answer]);
	/*  End section */

	/* Section help */
	const onNavigateHelp = () => {
		switch (content.SECTION_TITLE) {
			case 'Reading':
				navigate('/reading-help', { replace: true });
				break;
			case 'Listening':
				navigate('/listening-help', { replace: true });
				break;
			case 'Writing':
				navigate('/writing-help', { replace: true });
				break;
			default:
				break;
		}
	};
	/* End section */

	/* Section Review */
	const onOpenReview = () => {
		navigate('/review', { replace: true });
	};
	/* End section */

	/* Section Guess mode */
	const toggleGuessMode = () => {
		appDispatch(updateGuessMode(!guessMode));
	};
	/* End Section */

	/* Section show answer */
	const toggleShowAnswer = () => {
		appDispatch(updateShowAnswer(!showAnswer));
	};
	/* End Section */

	/* Section print document */
	const handlePrint = useReactToPrint({
		content: () => printComponent,
		documentTitle: 'Toefl Score Report',
		removeAfterPrint: true,
	});
	/* End section  */

	/* Replay talk section*/
	const handleReplayTalk = () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: content.CURRENT_PASSAGE_STEP,
			},
		};

		const onRejected = () => {
			openEndSessionDialog();
		};

		appDispatch(resetTopBarState());
		appDispatch(resetAudioState());
		appDispatch(onReplayTalk({ body, onRejected }));
	};
	/* End section*/

	/* Stop replay talk section*/
	const handleStopReplayTalk = () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.curr_step,
			},
		};

		const onRejected = () => {
			openEndSessionDialog();
		};

		appDispatch(resetTopBarState());
		appDispatch(resetAudioState());
		appDispatch(onTestController({ body, onRejected }));
	};
	/* End section*/

	/* Confirm response section*/
	const handleConfirmResponse = async () => {
		appDispatch(resetAudioFile());
		await onContinue();
	};
	/* End section */

	/* Section playback response */
	const handlePlayBackResponse = async () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: content.PREV_STEP,
				TEST_MODE: data.test_mode,
			},
		};

		appDispatch(onPlayBackResponseRequest(body));
		navigate('playback-response', { replace: true });
	};
	/* End section */

	/* Modal Section */
	const openEndSessionDialog = () => {
		openDialog({
			children: (
					<EndSessionModal closeDialog={() => {
						closeDialog();
						navigate('/login', { replace: true });
					}} />
			),
		});
	};
	const openSSMCDialog = () => {
		openDialog({
			children: (
					<SSMCDialog closeDialog={closeDialog} />
			),
		});
	};

	const openMustAnswerCompleteDialog = () => {
		openDialog({
			children: (
					<MustAnswerCompleteDialog closeDialog={closeDialog} />
			),
		});
	};
	/* End Section */

	const hideAudioOnListening = (): boolean => {
		switch (content.FLOW_CNTL_NAME) {
			case FlowControlName.Listening1_question:
			case FlowControlName.Listening1_stimulus:
			case FlowControlName.Listening1_setLeader:
			case FlowControlName.Listening2_setLeader:
			case FlowControlName.Listening2_question:
			case FlowControlName.Listening2_stimulus:
				return true;
			default:
				return false;
		}
	};

	/* Playback response function*/
	const getBlobLength = useCallback(async () => {
		if (audioFile !== '') {
			const duration = await getBlobDuration(audioFile);
			setBlobLength(Math.floor(duration));
		}
	}, [audioFile]);

	const secondsToTime = (secs: number) => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		return <>
			{hours !== 0 ? (hours < 10 ? `0${hours}` : hours) : `00`}:
			{minutes !== 0 ? (minutes < 10 ? `0${minutes}` : minutes) : `00`}:
			{seconds !== 0 ? (seconds < 10 ? `0${seconds}` : seconds) : `00`}
		</>;
	};

	const dispSecondsAsMins = (seconds: any) => {
		if (seconds > 0) {
			return secondsToTime(seconds);
		} else {
			return '00:00:00';
		}
	};

	const renderPlayBackResponseBtn = () => {
		if (content.FLOW_CNTL_NAME === FlowControlName.ADJ_VOL_CONFIRM) {
			if (!isPlayRecording) {
				return <CButton text={ButtonEnum.PLAYBACK} icon={help_icon} width={'145px'}
				                onClick={() => setIsPlayRecording(true)} />;
			} else {
				return <CButton text={ButtonEnum.PLAYBACK} icon={dispSecondsAsMins(blobLength)} width={'145px'}
				                onClick={() => setIsPlayRecording(false)}
				                background={'linear-gradient(180deg, #EA4F4F 0%, #A60A0A 100%)'} materialIcon={true} />;
			}
		} else {
			return <CButton text={ButtonEnum.PLAYBACK} icon={help_icon} width={'145px'}
			                onClick={handlePlayBackResponse} />;
		}
	};

	/* Section under top bar*/
	const StyledViewQuestionButton = styled(Button)(({ theme, color = 'primary' }) => ({
		width: '144px',
		paddingY: '0.4rem',
		color: MSCTViewMode === 'question' ? 'black' : 'white',
		backgroundColor: MSCTViewMode === 'question' ? 'white' : 'black',
		':hover': {
			color: MSCTViewMode === 'question' ? 'black' : 'white',
			backgroundColor: MSCTViewMode === 'question' ? 'white' : 'black',
		},
	}));

	const StyledViewTextButton = styled(Button)(({ theme, color = 'primary' }) => ({
		width: '144px',
		paddingY: '0.4rem',
		color: MSCTViewMode === 'text' ? 'black' : 'white',
		backgroundColor: MSCTViewMode === 'text' ? 'white' : 'black',
		':hover': {
			color: MSCTViewMode === 'text' ? 'black' : 'white',
			backgroundColor: MSCTViewMode === 'text' ? 'white' : 'black',
		},
	}));

	/* Section view text */
	const fetchCurrentPassageStepPassage = () => {
		const body: any = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: content.CURRENT_PASSAGE_STEP,
			},
		};

		appDispatch(onFetchCurrentPassage(body));
		appDispatch(toggleMSCTViewMode('text'));
	};

	const fetchBackToCurrentStepQuestion = () => {
		const body: any = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.curr_step,
			},
		};

		appDispatch(onTestController(body));
		appDispatch(toggleMSCTViewMode('question'));
	};
	/* End of section */

	/* Section expired time */
	const handleExpireRemainTime = useCallback(
			async () => {
				let flowcntl = '';
				if (content.FLOW_CNTL_NAME && content.FLOW_CNTL_NAME.includes('_NEW')) {
					flowcntl = content.FLOW_CNTL_NAME.replace('_NEW', '');
				}
				switch (flowcntl) {
					case FlowControlName.GCI:
					case FlowControlName.GTI:
					case FlowControlName.HC1:
					case FlowControlName.HC2:
					case FlowControlName.HCCV:
					case FlowControlName.HCAM:
					case FlowControlName.EOT:
					case FlowControlName.DIR_R:
					case FlowControlName.END_R:
					case FlowControlName.HEADSET_L:
					case FlowControlName.CHG_VOL:
					case FlowControlName.DIR_L1:
					case FlowControlName.END_L1:
					case FlowControlName.DIR_L2:
					case FlowControlName.END_L2:
					case FlowControlName.HEADSET_S:
					case FlowControlName.ADJ_VOL:
					case FlowControlName.ADJ_VOL_CONFIRM:
					case FlowControlName.DIR_S1:
					case FlowControlName.END_S1:
					case FlowControlName.DIR_W1:
					case FlowControlName.DIR_WRL:
					case FlowControlName.DIR_WKE:
					case FlowControlName.END_W1:
					case FlowControlName.설문조사:
					case FlowControlName.Score_Report:
					case FlowControlName.DIR_BREAK:
						return;
				}
				const body = {
					HEADER: {
						SESSION,
						USER_ID,
					},
					BODY: {
						TEST_CODE: data.test_code,
					},
				};

				try {
					const expiredRes = await appDispatch(onExpiredTime(body));
					const result = unwrapResult(expiredRes);
					if (result) {
						const nextBody = {
							HEADER: {
								SESSION,
								USER_ID,
							},
							BODY: {
								TEST_CODE: data.test_code,
								NEXT_STEP: result.NEXT_STEP,
							},
						};
						appDispatch(onTestController({ body: nextBody }));
						appDispatch(updateExpired(false));
					}
				} catch (error) {
					console.log(error);
				}
			}, [SESSION, USER_ID, appDispatch, content.FLOW_CNTL_NAME, data.test_code]);
	/* end of section*/

	useEffect(() => {
		if (content.TEST_MODE === 'T' && expired === true) {
			handleExpireRemainTime();
		}
	}, [handleExpireRemainTime, expired, content.TEST_MODE]);


	useEffect(() => {
		const interval = setInterval(() => {
			if (Object.keys(content).length > 0) handleSaveCurrentStatus();
		}, 30 * 1000);

		return () => clearInterval(interval);
	}, [content, handleSaveCurrentStatus]);

	useEffect(() => {
		if (vidRef.current) {
			if (isPlayRecording) {
				vidRef.current.play();
			} else {
				vidRef.current.pause();
			}
		}
	}, [isPlayRecording]);

	useEffect(() => {
		if (content.FLOW_CNTL_NAME === FlowControlName.ADJ_VOL_CONFIRM) {
			getBlobLength();
		}
	}, [content.FLOW_CNTL_NAME, getBlobLength]);

	useEffect(() => {
		if (firstStart.current) {
			firstStart.current = !firstStart.current;
			return;
		}
		if (isPlayRecording) {
			tick.current = setInterval(() => {
				setBlobLength((blobLength) => blobLength - 1);
			}, 1000);
		} else {
			clearInterval(tick.current);
		}

		return () => clearInterval(tick.current);
	}, [isPlayRecording]);

	useEffect(() => {
		if (blobLength <= 0) {
			setIsPlayRecording(false);
			getBlobLength();
		}
	}, [blobLength, getBlobLength]);


	return (
			<AppBar position='sticky' className='pt-[19px]' sx={{ backgroundColor: '#313131' }}>
				<Box className='flex px-[25px] pb-4'>
					<Box
							className='self-center mb-2'
							component={'img'}
							src={logo}
							alt={'logo'}
							sx={{ height: '38.9px', marginRight: 2 }}
					/>
					<Box className='flex flex-1 justify-between'>
						<Box className='left-btn'>
							{isHaveButton(ButtonEnum.PAUSE) ? (
									<CButton text={ButtonEnum.PAUSE} icon={pause_icon} onClick={onPauseButton} />
							) : null}
							{isHaveButton(ButtonEnum.SECTION_EXIT) ? (
									<CButton
											text={ButtonEnum.SECTION_EXIT}
											icon={section_exit_icon}
											onClick={onSectionExit}
									/>
							) : null}
							{isHaveButton(ButtonEnum.GUESS) ? (
									guessMode ?
											<CButton
													text={ButtonEnum.GUESS}
													materialIcon={true}
													icon={<CheckBoxOutlinedIcon sx={{ color: '#fff' }} />}
													onClick={toggleGuessMode}
													isGuessBtn={true}
											/> : <CButton
													text={ButtonEnum.GUESS}
													materialIcon={true}
													icon={<CheckBoxOutlineBlankIcon sx={{ color: '#fff' }} />}
													onClick={toggleGuessMode}
													isGuessBtn={true}
													disabledStyle={true}
											/>
							) : null}
							{isHaveButton(ButtonEnum.ANSWER) ? (
									<CButton text={ButtonEnum.ANSWER} icon={answer_icon} onClick={toggleShowAnswer} />
							) : null}
							{isHaveButton(ButtonEnum.PRINT) ? (
									<CButton text={ButtonEnum.PRINT} icon={answer_icon} onClick={handlePrint} />
							) : null}
						</Box>
						<Box className='right-btn self-center'>
							{isHaveButton(ButtonEnum.VOLUME) ? (
									<Box className='inline-block relative h-full'>
										<CButton
												text={ButtonEnum.VOLUME}
												icon={volume_icon}
												isLong={true}
												onClick={() => setIsVolumeModalHide(!isVolumeModalHide)}
										/>
										{!isVolumeModalHide && (
												<Paper elevation={3} className='w-80 bg-white px-8 py-4 absolute right-0'>
													<Box className='flex justify-between items-center mb-3'>
														<Typography className='mb-2 color-[#626576]'>Volume Control</Typography>
														<img
																className='mb-3 cursor-pointer'
																src={x_icon}
																alt='close'
																onClick={() => setIsVolumeModalHide(true)}
														/>
													</Box>

													<Stack spacing={2} direction='row' sx={{ mb: 1 }} alignItems='center'>
														<VolumeDown />
														<Slider
																aria-label='Volume'
																value={volume}
																onChange={handleVolumeChange}
																className='text-black h-2'
														/>
														<VolumeUp />
													</Stack>
												</Paper>
										)}
									</Box>
							) : null}
							{isHaveButton(ButtonEnum.STOP_TALK) ?
									<CButton text={ButtonEnum.STOP_TALK} icon={replay_talk_icon} onClick={handleStopReplayTalk} /> : null}
							{isHaveButton(ButtonEnum.REPLAY_TALK) ? (
									isPlaying ? (
											<CButton
													text={ButtonEnum.REPLAY_TALK_DISABLED}
													icon={replay_talk_icon}
													disabledStyle={true}
											/>
									) : (
											<CButton text={ButtonEnum.REPLAY_TALK} icon={replay_talk_icon} onClick={handleReplayTalk} />
									)
							) : null}
							{isHaveButton(ButtonEnum.REPLAY_TALK_DISABLED) ? (
									<CButton
											text={ButtonEnum.REPLAY_TALK_DISABLED}
											icon={replay_talk_icon}
											disabledStyle={true}
									/>
							) : null}
							{isHaveButton(ButtonEnum.PLAYBACK) ? renderPlayBackResponseBtn() : null}
							{isHaveButton(ButtonEnum.PLAYBACK_DISABLED) ? (
									<CButton
											text={ButtonEnum.PLAYBACK_DISABLED}
											icon={help_icon}
											disabledStyle={true}
											width={'145px'}
									/>
							) : null}
							{isHaveButton(ButtonEnum.RECORD_AGAIN) ? (
									<CButton text={ButtonEnum.RECORD_AGAIN} icon={return_icon} isLong={true} onClick={onBackToPrevious} />
							) : null}
							{isHaveButton(ButtonEnum.RECORD_AGAIN_DISABLED) ? (
									<CButton
											text={ButtonEnum.RECORD_AGAIN_DISABLED}
											icon={return_icon}
											disabledStyle={true}
											isLong={true}
									/>
							) : null}
							{isHaveButton(ButtonEnum.CONFIRM_RESPONSE) ? (
									<CButton text={ButtonEnum.CONFIRM_RESPONSE} icon={next_icon} width={'145px'}
									         onClick={handleConfirmResponse} />
							) : null}
							{isHaveButton(ButtonEnum.CONFIRM_RESPONSE_DISABLED) ? (
									<CButton
											text={ButtonEnum.CONFIRM_RESPONSE_DISABLED}
											icon={next_icon}
											disabledStyle={true}
											width={'145px'}
									/>
							) : null}
							{isHaveButton(ButtonEnum.PROG_REVIEW) ? (
									isPlaying ? (
											<CButton
													text={ButtonEnum.PROG_REVIEW_DISABLED}
													icon={review_icon}
													disabledStyle={true}
													isBlack={true}
											/>
									) : (
											<CButton
													text={ButtonEnum.PROG_REVIEW}
													icon={review_icon}
													onClick={onOpenReview}
													isBlack={true}
											/>
									)
							) : null}
							{isHaveButton(ButtonEnum.PROG_REVIEW_DISABLED) ? (
									<CButton
											text={ButtonEnum.PROG_REVIEW_DISABLED}
											icon={review_icon}
											disabledStyle={true}
											isBlack={true}
									/>
							) : null}
							{isHaveButton(ButtonEnum.HELP) ? (
									isPlaying ? (
											<CButton
													text={ButtonEnum.HELP_DISABLED}
													icon={help_icon}
													disabledStyle={true}
													isBlack={true}
											/>
									) : (
											<CButton
													text={ButtonEnum.HELP}
													icon={help_icon}
													onClick={onNavigateHelp}
													isBlack={true}
											/>
									)
							) : null}
							{isHaveButton(ButtonEnum.HELP_DISABLED) ? (
									<CButton
											text={ButtonEnum.HELP_DISABLED}
											icon={help_icon}
											disabledStyle={true}
											isBlack={true}
									/>
							) : null}
							{isHaveButton(ButtonEnum.PROG_BACK) ? (
									<CButton text={ButtonEnum.PROG_BACK} icon={back_icon} onClick={onBackToPrevious} />
							) : null}
							{isHaveButton(ButtonEnum.PROG_BACK_DISABLED) ? (
									<CButton text={ButtonEnum.PROG_BACK_DISABLED} icon={back_icon} disabledStyle={true} disabled={true} />
							) : null}
							{isHaveButton(ButtonEnum.OK) ? (
									okBtn ? (
											<CButton text={ButtonEnum.OK} icon={ok_icon} onClick={handleOKClick} />
									) : (
											<CButton text={ButtonEnum.OK_DISABLED} icon={ok_icon} disabledStyle={true} disabled={true} />
									)
							) : null}
							{isHaveButton(ButtonEnum.OK_DISABLED) ? (
									okBtn ? (
											<CButton text={ButtonEnum.OK} icon={ok_icon} onClick={handleOKClick} />
									) : (
											<CButton text={ButtonEnum.OK_DISABLED} icon={ok_icon} disabledStyle={true} disabled={true} />
									)
							) : null}
							{isHaveButton(
									ButtonEnum.PROG_NEXT ? ButtonEnum.PROG_NEXT : ButtonEnum.PROG_NEXT_DISABLED,
							) ? (
									isPlaying ? (
											<CButton
													text={ButtonEnum.PROG_NEXT_DISABLED}
													icon={next_icon}
													disabledStyle={true}
													disabled={true}
											/>
									) : enabledNextBtn === true ? (
											<CButton onClick={onContinue} text={ButtonEnum.PROG_NEXT} icon={next_icon} />
									) : (
											<CButton
													text={ButtonEnum.PROG_NEXT_DISABLED}
													icon={next_icon}
													disabledStyle={true}
													disabled={true}
											/>
									)
							) : null}
							{/* fake next button*/}
							{!isHaveButton(ButtonEnum.PROG_NEXT || ButtonEnum.PROG_NEXT_DISABLED) ? (
									<CButton onClick={onContinue} text={'Next_test'} icon={next_icon} />
							) : null}
							{isHaveButton(ButtonEnum.PROG_NEXT_DISABLED) ? (
									<CButton text={ButtonEnum.PROG_NEXT_DISABLED} icon={next_icon} disabledStyle={true} disabled={true} />
							) : null}
							{isHaveButton(ButtonEnum.RETURN_QUESTION) ? (
									<CButton text={ButtonEnum.RETURN_QUESTION} icon={return_question_icon} />
							) : null}
							{isHaveButton(ButtonEnum.RETURN) ? (
									<CButton text={ButtonEnum.RETURN} icon={return_icon} />
							) : null}
							{isHaveButton(ButtonEnum.PROG_CONTINUE) ? (
									<CButton
											text={ButtonEnum.PROG_CONTINUE}
											icon={continue_icon}
											onClick={onContinue}
											isLong={true}
									/>
							) : null}
							{isHaveButton(ButtonEnum.EXIT) ? (
									<CButton
											text={ButtonEnum.EXIT}
											icon={return_icon}
											onClick={() => {
												closeFullScreen();
												navigate('/my-page', { replace: true });
											}}
									/>
							) : null}
						</Box>
					</Box>
				</Box>
				<Box className='bg-[#EDE2A9] h-8 border-b-black border-b flex justify-between px-4'>
					<Box className='self-center flex justify-between'>
						{content.TOTAL_TEST_ITEM_NO && content.TOTAL_TEST_ITEM_NO !== '' ? (
								<Typography className='font-semibold' variant='body1' color={'#313131'}>
									{testName} | {content.SECTION_TITLE} | Question {content.CURRENT_TEST_ITEM_NO} of{' '}
									{content.TOTAL_TEST_ITEM_NO}
								</Typography>
						) : content.SECTION_TITLE && content.SECTION_TITLE !== '' ? (
								<Typography className='font-semibold' variant='body1' color={'#313131'}>
									{content.SECTION_TITLE}
								</Typography>
						) : null}
					</Box>
					{content.FLOW_CNTL_NAME === FlowControlName.Reading && (content.QNOTYPE === 'MSCT' || content.QNOTYPE === 'MSMT') ?
							<Box className={'flex-1 self-center flex justify-center'}>
								<StyledViewTextButton disableElevation={true} disableRipple={true} variant={'text'}
								                      onClick={fetchCurrentPassageStepPassage}>
									<Typography className={'text-xs'}>View Text</Typography>
								</StyledViewTextButton>
								<Divider orientation='vertical' className={'px-1'} />
								<StyledViewQuestionButton disableElevation={true} disableRipple={true} variant={'text'}
								                          onClick={fetchBackToCurrentStepQuestion}>
									<Typography className={'text-xs'}>View Question</Typography>
								</StyledViewQuestionButton>
							</Box>
							: null}
					{data.test_mode === 'T' ? (isPlaying && hideAudioOnListening() ? null : <CountdownRemainTime />) : null}
				</Box>
				{/* Section modals */}
				<PauseModal
						open={openPauseModal}
						handleClose={() => setOpenPauseModal(false)}
						handleConfirm={onConfirmPauseModal}
				/>

				<ScrollBottomModal
						open={openScrollBottomModal}
						handleClose={() => setOpenScrollBottomModal(false)}
				/>
				{/* End section */}

				{/* Playback response hardware check */}
				{audioFile !== '' ?
						<Box sx={{ position: 'absolute', top: 0, left: 0 }}>
							<video ref={vidRef} hidden={true}>
								<source src={audioFile} />
							</video>
						</Box> : null}
				{/* End of section*/}
			</AppBar>
	);
};

export default TopBar;
