import { useEffect, useState } from 'react';

function useHardwareConnect() {
	const [isSpeakerConntected, setIsSpeakerConnected] = useState<boolean>(true);
	const [isMicConntected, setIsMicConnected] = useState<boolean>(true);

	useEffect(() => {
		updateDeviceList();
		navigator.mediaDevices.ondevicechange = (event) => {
			updateDeviceList();
		};
	}, []);

	function updateDeviceList() {
		let speakerConnected = false;
		let micConnected = false;
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			if (devices.length > 0) {
				devices.forEach((device) => {
					const parsedResult: RegExpMatchArray | null = device.kind.match(/(\w+)(input|output)/i);
					if (parsedResult !== null) {
						const [kind, type, direction, label] = parsedResult;

						if (type === 'audio') {
							if (direction === 'input') {
								micConnected = true;
							}
							if (direction === 'output') {
								speakerConnected = true;
							}
						}
					}
				});
				setIsSpeakerConnected(speakerConnected);
				setIsMicConnected(micConnected);
			}
		});
	}

	return [isSpeakerConntected, isMicConntected];
}

export default useHardwareConnect;
