import TitleSection from '../../../../core/components/section/TitleSection';
const GeneralDescriptions = () => {
	return (
		<TitleSection title='General Test Information'>
			<p>
				This is a test of your ability to use English in an academic context. There are 4 sections.
				<br /> <br />
				In the <b>Reading</b> section, you will read several passages and answer questions about
				them.
				<br /> <br /> In the <b>Listening</b> section. you will hear several conversations and
				lectures and answer questions about them. <br /> <br /> You will have a 10-minute break
				after the <b>Listening</b> section. <br /> <br /> in the <b>Speaking</b> section, you will
				answer 4 questions. One of the questions asks you to speak about you own experience. Other
				questions ask you speak about lectures and reading passages. <br /> <br />
				in the <b>Writing</b> section, you will answer 2 questions. THe first question asks you to
				write about the relationship between a lecture you will hear and a passage you will read.
				The second question asks you to write an essay about a topic of general interest based on
				your experience. <br /> <br /> There will be directions before each section. You may dismiss
				these directions at any time by selecting <b>Continue</b>. <br /> <br /> You should work
				quickly but carefully on the Reading and Listening questions. Some questions are more
				difficult than others. but try to answer every one to the best of your ability. If you are
				not sure of the answer to a question, make the best guess that you can. The questions that
				you answer by speaking and by writing are each separately timed. Try to answer every one of
				these questions as completely as possible in the time allowed. <br /> <br /> Please be aware
				that other candidates may be speaking while you are testing. All candidates will not begin
				the Speaking section at the same time. If you think you will be distracted. we suggest you
				wear your headphones during the entire test session. <br /> <br /> Select <b>Continue</b> to
				go on.
			</p>
		</TitleSection>
	);
};

export default GeneralDescriptions;
