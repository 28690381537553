import blackman from '../../../../../assets/icons/blackman.png';
import TitleSection from '../../../../core/components/section/TitleSection';

const HardwareCheckImageText = () => {
	return (
		<TitleSection title='Hardware Check'>
			<div className=''>
				<div className='mb-5'>
					Please make sure your headphone is on. Follow the instructions on each screen. Be sure
					that your microphone is properly positioned and adjusted to allow for the best possible
					recording. Speak directly into the microphone and in your normal speaking voice.
				</div>
				<img className='mb-5 w-[300px]' src={blackman} alt={'black-man'} />
				<div>
					Select <span className='font-bold'>Continue</span> when you are ready to go on.
				</div>
			</div>
		</TitleSection>
	);
};

export default HardwareCheckImageText;
