import TitleSection from '../../../../core/components/section/TitleSection';

const EndSection = () => {
	return (
		<TitleSection title='End of Section'>
			<div className='mt-32'>
				<p className='text-[#000000]'>
					You have finished this section and will now begin the next one.
					<br />
					<br />
					Select <span className='font-bold'>Continue</span> to go in.
				</p>
			</div>
		</TitleSection>
	);
};

export default EndSection;
