import { Box, Paper, Typography } from '@mui/material';
import ArrowTitle from '../components/arrow-title';
const ListenHowToAnswerSub2 = () => {
	const tableArr: string[] = ['Wake up', 'Go to work', '', ''];
	return (
		<Paper className='p-10' elevation={3}>
			<p className='mb-10'>
				For this type of question you will drag your answer choices to the spaces where they belong.
			</p>
			<Typography variant='body1' fontWeight={'bold'} className='mb-8'>
				How to answer:
			</Typography>
			<Box className='mb-8'>
				<p className='ml-4'>● Select an answer choice to darg it to the space where it belongs.</p>
			</Box>
			<Box className='mb-8'>
				<p className='ml-4'>● Release the mouse button when the answer choice is inside the box.</p>
			</Box>

			<Typography variant='body1' fontWeight={'bold'} className='mb-8'>
				How to Change Your Answer Choices:
			</Typography>
			<Box className='mb-8'>
				<p className='ml-4'>
					● To remove an answer choice, select it or drag it back to the answer choices.
				</p>
			</Box>
			<Box className='mb-8'>
				<p className='ml-4'>● Drag your new answer choice to the place where it belongs.</p>
			</Box>

			<Box
				sx={{ width: '400px', paddingY: '3px', paddingX: '32px' }}
				className='border-2 border-dashed border-slate-900 flex flex-col justify-center items-center'
			>
				<p className='text-base'>
					Below is a list of activities. Put the activities in the order that they would take place
					during a typical day.
				</p>
				<Box className='mb-2 py-1 px-10 text-base mx-4' sx={{ backgroundColor: '#DEE4D0' }}>
					<p className='text-center'>
						Select a phrase. Then drag it to the place where it belongs. The first one is done for
						you.
					</p>
				</Box>
				<Box className='w-full pr-5 mb-3'>
					<table className='w-full'>
						<tbody>
							{tableArr.map((val, idx) => (
								<tr key={idx}>
									<td className='w-1 pr-2'>{idx + 1}</td>
									<td className='border border-slate-900 text-base px-2'>{val}</td>
								</tr>
							))}
						</tbody>
					</table>
				</Box>
				<Box className='flex flex-col items-center mb-4'>
					<Typography className='underline font-bold'>Answer Choices:</Typography>
					<Typography>Go home from work</Typography>
					<br />
					<Typography>Eat dinner</Typography>
				</Box>
			</Box>
		</Paper>
	);
};

export default ListenHowToAnswerSub2;
