import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { setAnswer, toeflSelector } from '../../../../core/redux/ToeflSlice';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { arrayMove, insertAtIndex, removeAtIndex } from '../../reading-passage-only-question/utils/array';
import Droppable from './components/Droppable';
import Item from './components/Item';
import { topBarSelector, updateScrollBottom, updateCorrectQuestion } from '../../../../core/redux/TopBarSlice';

const MSOMQuestion = () => {
	const dispatch = useAppDispatch();
	const { content, answer } = useAppSelector(toeflSelector);
	const { showAnswer } = useAppSelector(topBarSelector);

	const [itemGroups, setItemGroups] = useState<any>({
		group1: [],
		group2: [],
	});

	const [backupItemGroups, setBackupItemGroups] = useState<any>({
		group1: [],
		group2: [],
	});
	const [lists, setLists] = useState<any>([]);

	useEffect(() => {
		if (itemGroups.group1.length === 0) {
			dispatch(updateCorrectQuestion(false));
		} else {
			let checkWrong = 0
			for (let i = 1; i <= content.TARGET_ROW_LIST.length; i++) {
				const row = content.TARGET_ROW_LIST[i];
				if (row && row.SRC_ANSWER_NO !== answer.ANSWER) {
					checkWrong++;
				}
			}
			dispatch(updateCorrectQuestion(checkWrong > 0 ? false : true));
		}
	}, [])

	useEffect(() => {
		if (!showAnswer) {
			setBackupItemGroups(itemGroups);
		}
	}, [itemGroups])

	useEffect(() => {
		const groups: any = {
			group1: [],
			group2: []
		}
		if (showAnswer) {
			for (const row of content.TARGET_ROW_LIST) {
				if (row.SRC_ANSWER_NO) {
					for (const src_row of content.SRC_ROW_LIST) {
						for (const src_col of src_row.SRC_COL_LIST) {
							if (row.SRC_ANSWER_NO === src_col.SRC_ITEM_NO) {
								groups.group1.push(src_col.SRC_ITEM_CONTENT)
							}
						}

					}
				}
			}
			groups.group2 = itemGroups.group2.filter((x: any) => !groups.group1.includes(x));
			setItemGroups(groups)

		} else {
			setItemGroups(backupItemGroups)
		}
	}, [showAnswer])

	useEffect(() => {
		dispatch(updateScrollBottom(true));
		initItemGroups();
	}, []);

	useEffect(() => {
		onSetAnswer();
	}, [itemGroups.group1]);

	function onSetAnswer() {
		const answwers: any = [];
		if (itemGroups.group1.length === 0) {
			dispatch(setAnswer({ ANSWER: [] }));
		} else {
			itemGroups.group1.map((item: any) => {
				const find = lists.find((ele: any) => item === ele.SRC_ITEM_CONTENT)
				answwers.push(find.ITEM_SEQ.toString())
			})
		}
		dispatch(setAnswer({ ANSWER: answwers }));
	}


	function initItemGroups() {
		const items: any = [];
		const tempList: any = [];
		content.SRC_ROW_LIST?.map((row: any) => {
			row.SRC_COL_LIST.map((col: any) => {
				items.push(col.SRC_ITEM_CONTENT);
				tempList.push(col);
			});
		});

		const tempItemGroups = _.cloneDeep(itemGroups);
		tempItemGroups.group2 = items;
		setLists(tempList);
		setItemGroups(tempItemGroups);
	}

	const [activeId, setActiveId] = useState(null);

	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const handleDragStart = ({ active }: any) => setActiveId(active.id);

	const handleDragCancel = () => setActiveId(null);

	const handleDragOver = ({ active, over }: any) => {
		const overId = over?.id;

		if (!overId) {
			return;
		}

		const activeContainer = active.data.current.sortable.containerId;
		const overContainer = over.data.current?.sortable.containerId || over.id;

		if (activeContainer !== overContainer) {
			setItemGroups((itemGroups: any) => {
				const activeIndex = active.data.current.sortable.index;
				const overIndex =
					over.id in itemGroups
						? itemGroups[overContainer].length + 1
						: over.data.current.sortable.index;

				return moveBetweenContainers(
					itemGroups,
					activeContainer,
					activeIndex,
					overContainer,
					overIndex,
					active.id,
				);
			});
		}
	};

	const handleDragEnd = ({ active, over }: any) => {
		if (!over) {
			setActiveId(null);
			return;
		}

		if (active.id !== over.id) {
			const activeContainer = active.data.current.sortable.containerId;
			const overContainer = over.data.current?.sortable.containerId || over.id;
			const activeIndex = active.data.current.sortable.index;
			const overIndex =
				over.id in itemGroups
					? itemGroups[overContainer].length + 1
					: over.data.current.sortable.index;

			setItemGroups((itemGroups: any) => {
				let newItems;
				if (activeContainer === overContainer) {
					newItems = {
						...itemGroups,
						[overContainer]: arrayMove(
							itemGroups[overContainer],
							activeIndex,
							overIndex,
						),
					};
				} else {
					newItems = moveBetweenContainers(
						itemGroups,
						activeContainer,
						activeIndex,
						overContainer,
						overIndex,
						active.id,
					);
				}

				return newItems;
			});
		}

		setActiveId(null);
	};

	const moveBetweenContainers = (
		items: any,
		activeContainer: any,
		activeIndex: any,
		overContainer: any,
		overIndex: any,
		item: any,
	) => {
		return {
			...items,
			[activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
			[overContainer]: insertAtIndex(items[overContainer], overIndex, item),
		};
	};
	return (
		<div className='w-full listening-dnd mt-5'>
			{/* <div className='mt-5'>
                {answerArr.map((answer, index) => {
                    return (
                        <div key={index} className="flex">
                            <div className='w-5 mr-2'>{index + 1}</div>
                            <div
                                onClick={() => onRemoveAnswer(answer)}
                                className={index === 0 ? 'px-2 border-t-2 border-b-2 border-r-2 border-l-2 w-full' : 'px-2 border-b-2 border-r-2 border-l-2 w-full'}
                            >
                                {answer.SRC_ITEM_CONTENT}
                            </div>
                        </div>

                    );
                })}
            </div>

            <div className='p-5'>
                <div className='font-bold mb-5 underline'>Answer Choices:</div>
                <div className='w-full'>
                    {choices?.map((choice: any, index: number) => {
                        return (
                            <div onClick={() => onChoice(choice)} className=' mb-5' key={index}>
                                <DragHandleIcon /> {choice.SRC_ITEM_CONTENT}
                            </div>
                        );
                    })}
                </div>
            </div> */}


			<DndContext
				sensors={sensors}
				onDragStart={handleDragStart}
				onDragCancel={handleDragCancel}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
			>
				<div>
					<div className='flex'>
						<div className='row-selected'>
							<div>1</div>
							<div>2</div>
							<div>3</div>
							<div>4</div>
						</div>
						<Droppable
							id='group1'
							items={itemGroups.group1}
							activeId={activeId}
							key='group1'
						/>
					</div>

					<div style={{ marginTop: '50px' }}>
						<div className='font-bold mb-5 underline'>Answer Choices:</div>
						<div className='flex'>
							<div className='row-choices'>
								<div><DragHandleIcon /></div>
								<div><DragHandleIcon /></div>
								<div><DragHandleIcon /></div>
								<div><DragHandleIcon /></div>
							</div>
							<Droppable
								id='group2'
								items={itemGroups.group2}
								activeId={activeId}
								key='group2'
							/>
						</div>

					</div>

				</div>
				<DragOverlay>
					{activeId ? <Item id={activeId} dragOverlay /> : null}
				</DragOverlay>
			</DndContext>
		</div>
	);
};

export default MSOMQuestion;
