import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import blackdot from '../../../../../assets/icons/blackdot.svg';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { setAnswer, toeflSelector, updateGuessAnswer } from '../../../../core/redux/ToeflSlice';

import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import Droppable from './components/Droppable';
import Item from './components/Item';
import { arrayMove, insertAtIndex, removeAtIndex } from '../utils/array';
import { topBarSelector, updateCorrectQuestion, updateScrollBottom } from '../../../../core/redux/TopBarSlice';


const ReadingMSMT = () => {
	const dispatch = useAppDispatch();
	const { showAnswer } = useAppSelector(topBarSelector);
	const { content, answer } = useAppSelector(toeflSelector);
	const [lists, setLists] = useState<any>([]);
	const [itemGroups, setItemGroups] = useState<any>({
		group0: [],
		group1: [],
		group2: [],
		group3: [],
		group4: [],
		group5: [],
	});
	const [backupItemGroups, setBackupItemGroups] = useState<any>({
		group0: [],
		group1: [],
		group2: [],
		group3: [],
		group4: [],
		group5: [],
	});
	const [checkCorrectAnswer, setCheckCorrectAnswer] = useState(false);

	useEffect(() => {
		if (backupItemGroups.group0.length !== content.SRC_ROW_LIST.length) {
			let checkWrong = 0;
			for (let i = 0; i < content.TARGET_TABLE_LIST.length; i++) {
				const target_table = content.TARGET_TABLE_LIST[i];

				if (!backupItemGroups[`group${i + 1}`].length) {
					checkWrong++;
				}

				for (const item of backupItemGroups[`group${i + 1}`]) {
					const col = lists.find((list: any) => list.SRC_ITEM_CONTENT === item);
					const correctList = [];

                    if (col) {
                        for (let j = 0; j < content.TARGET_TABLE_LIST[i].TARGET_ROW_LIST.length; j++) {
                            const colCorrect = content.TARGET_TABLE_LIST[i].TARGET_ROW_LIST[j].TARGET_COL_LIST.find((item: any) => item.COANSSRCNO);
                            correctList.push(colCorrect)
                        }

                        const check = correctList.find((item: any) => item.COANSSRCNO === col.SRC_ITEM_NO)
                        if (!check) {
                            checkWrong++
                        }
                    } else {
                        checkWrong++
                    }


                }
            }
            dispatch(updateCorrectQuestion(checkWrong > 0 ? false : true))
        } else {
            dispatch(updateCorrectQuestion(false))
        }
    }, [showAnswer])

	useEffect(() => {
		const groups: any = {
			group0: [],
			group1: [],
			group2: [],
			group3: [],
			group4: [],
			group5: [],
		};
		if (showAnswer) {
			for (let i = 0; i < content.TARGET_TABLE_LIST.length; i++) {
				const target_table = content.TARGET_TABLE_LIST[i];

                for (let j = 0; j < target_table.TARGET_ROW_LIST.length; j++) {
                    const target_row = target_table.TARGET_ROW_LIST[j];
                    // groups[`group${i + 2}`].push([]);

					for (let k = 0; k < target_row.TARGET_COL_LIST.length; k++) {
						const target_col = target_row.TARGET_COL_LIST[k];

						if (target_col.COANSSRCNO) {

							const col = lists.find((list: any) => list.SRC_ITEM_NO === target_col.COANSSRCNO);

							groups[`group${i + 1}`].push(col.SRC_ITEM_CONTENT);
						}
					}
				}
			}

			for (let i = 0; i < lists.length; i++) {
				const list = lists[i];
				let check = 0;
				for (const group in groups) {

					if (groups[group].length) {

						for (const item of groups[group]) {
							if (list.SRC_ITEM_CONTENT === item) {
								check++;
							}
						}
					}
				}
				if (check === 0) {
					groups.group0.push(list.SRC_ITEM_CONTENT);
				}
			}

			setItemGroups(groups);
		} else {
			setItemGroups(backupItemGroups);
		}
	}, [showAnswer]);
	useEffect(() => {
		if (!showAnswer) {
			setBackupItemGroups(itemGroups);
		}
	}, [itemGroups]);

	useEffect(() => {
		dispatch(updateScrollBottom(true));
		initItemGroups();
	}, []);

	useEffect(() => {
		onSetAnswer();
	}, [itemGroups.group0]);

    function onSetAnswer() {
        const tempItemGroups = _.cloneDeep(itemGroups);
        const answwers: any = [];
        for (let i = 0; i < content.TARGET_TABLE_LIST.length; i++) {
            const table_list = content.TARGET_TABLE_LIST[i];

            for (let j = 0; j < table_list.TARGET_ROW_LIST.length; j++) {
                const row_list = table_list.TARGET_ROW_LIST[j]

                if (!tempItemGroups[`group${i + 1}`][j]) {
                    tempItemGroups[`group${i + 1}`][j] = '';
                }
            }

            tempItemGroups[`group${i + 1}`].map(((item: any) => {

                const find = lists.find((ele: any) => item === ele.SRC_ITEM_CONTENT)
                if (find) {
                    answwers.push(find.ITEM_SEQ.toString())

                } else {
                    answwers.push('')

                }
            }))
        }
        dispatch(setAnswer({ ANSWER: answwers }));
        dispatch(updateGuessAnswer({ GUESS_ANSWER: answwers }));
    }

    function initItemGroups() {

        if (content.USER_CHECK_NO.length) {
            const tempItemGroups = _.cloneDeep(itemGroups);

            const tempList: any = [];
            content.SRC_ROW_LIST?.map((row: any) => {
                row.SRC_COL_LIST.map((col: any) => {
                    tempList.push(col);
                });
            });

            for (const col of tempList) {
                const item = content.USER_CHECK_NO.find((check: any) => check === col.SRC_ITEM_NO)
                if (!item) {

                    tempItemGroups.group0.push(col.SRC_ITEM_CONTENT);
                }
            }


            tempItemGroups.group1 = [tempList.find((check: any) => check.SRC_ITEM_NO === content.USER_CHECK_NO[0])?.SRC_ITEM_CONTENT || '', tempList.find((check: any) => check.SRC_ITEM_NO === content.USER_CHECK_NO[1])?.SRC_ITEM_CONTENT || '']
            tempItemGroups.group2 = [tempList.find((check: any) => check.SRC_ITEM_NO === content.USER_CHECK_NO[2])?.SRC_ITEM_CONTENT || '', tempList.find((check: any) => check.SRC_ITEM_NO === content.USER_CHECK_NO[3])?.SRC_ITEM_CONTENT || '']
            tempItemGroups.group3 = [tempList.find((check: any) => check.SRC_ITEM_NO === content.USER_CHECK_NO[4])?.SRC_ITEM_CONTENT || '']

            setLists(tempList);
            setItemGroups(tempItemGroups);

        } else {
            const tempItemGroups = _.cloneDeep(itemGroups);

            content.TARGET_TABLE_LIST?.map((col: any, index: any) => {
                tempItemGroups[`group${index + 1}`] = [];
            })

            const items: any = [];
            const tempList: any = [];
            content.SRC_ROW_LIST?.map((row: any) => {
                row.SRC_COL_LIST.map((col: any) => {
                    items.push(col.SRC_ITEM_CONTENT);
                    tempList.push(col);
                });
            });

            tempItemGroups.group0 = items;
            setLists(tempList);
            setItemGroups(tempItemGroups);
        }
    }


    const [activeId, setActiveId] = useState(null);

	const sensors = useSensors(
			useSensor(MouseSensor),
			useSensor(TouchSensor),
			useSensor(KeyboardSensor, {
				coordinateGetter: sortableKeyboardCoordinates,
			}),
	);

	const handleDragStart = ({ active }: any) => setActiveId(active.id);

	const handleDragCancel = () => setActiveId(null);

	const handleDragOver = ({ active, over }: any) => {
		const overId = over?.id;

		if (!overId) {
			return;
		}

		const activeContainer = active.data.current.sortable.containerId;
		const overContainer = over.data.current?.sortable.containerId || over.id;

		if (activeContainer !== overContainer) {
			setItemGroups((itemGroups: any) => {
				const activeIndex = active.data.current.sortable.index;
				const overIndex =
						over.id in itemGroups
								? itemGroups[overContainer].length + 1
								: over.data.current.sortable.index;

				return moveBetweenContainers(
						itemGroups,
						activeContainer,
						activeIndex,
						overContainer,
						overIndex,
						active.id,
				);
			});
		}
	};
	const handleDragEnd = ({ active, over }: any) => {
		const nameGroup = active.data.current.sortable.containerId;
		const index = nameGroup.slice(5);
		if (index > 0) {
			const tempItemGroups = _.cloneDeep(itemGroups);
			if (content.TARGET_TABLE_LIST[index - 1].TARGET_ROW_LIST.length < itemGroups[nameGroup].length) {
				tempItemGroups[nameGroup] = tempItemGroups[nameGroup].filter((item: any) => item != active.id);
				tempItemGroups.group0.push(active.id);
				setItemGroups(tempItemGroups);
				return;
			}
		}

		if (!over) {
			setActiveId(null);
			return;
		}
		if (active.id !== over.id) {
			const activeContainer = active.data.current.sortable.containerId;
			const overContainer = over.data.current?.sortable.containerId || over.id;
			const activeIndex = active.data.current.sortable.index;
			const overIndex =
					over.id in itemGroups
							? itemGroups[overContainer].length + 1
							: over.data.current.sortable.index;

			setItemGroups((itemGroups: any) => {
				let newItems;
				if (activeContainer === overContainer) {
					newItems = {
						...itemGroups,
						[overContainer]: arrayMove(
								itemGroups[overContainer],
								activeIndex,
								overIndex,
						),
					};
				} else {
					newItems = moveBetweenContainers(
							itemGroups,
							activeContainer,
							activeIndex,
							overContainer,
							overIndex,
							active.id,
					);
				}

				return newItems;
			});
		}

		setActiveId(null);
	};

	const moveBetweenContainers = (
			items: any,
			activeContainer: any,
			activeIndex: any,
			overContainer: any,
			overIndex: any,
			item: any,
	) => {
		return {
			...items,
			[activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
			[overContainer]: insertAtIndex(items[overContainer], overIndex, item),
		};
	};
	return (
			<div className='reading-msmt w-full' style={{ marginTop: '60px' }}>
				<DndContext
						sensors={sensors}
						onDragStart={handleDragStart}
						onDragCancel={handleDragCancel}
						onDragOver={handleDragOver}
						onDragEnd={handleDragEnd}
				>
					<div className='flex w-full justify-between'>
						<div className='w-2/5'>
							<div className='font-bold mb-5 text-center'>Answer Choices</div>
							<Droppable
									id='group0'
									items={itemGroups.group0}
									activeId={activeId}
									key='group0'
							/>
						</div>

						<div className='w-2/5'>
							{
								content.TARGET_TABLE_LIST?.map((col: any, index: any) => {
									return (
											<div className='mb-5 w-full' key={index}>
												<div className='font-bold mb-5 text-center'>{col.TARGET_TITLE}</div>
												<div className='flex'>
													<div className='row-selected'>
														{
															col.TARGET_ROW_LIST?.map((row: any, i: any) => {
																return (
																		<div key={i} style={{ marginBottom: '5px' }}><img src={blackdot} /></div>
																);
															})
														}
													</div>

													<Droppable
															id={`group${index + 1}`}
															items={itemGroups[`group${index + 1}`]}
															activeId={activeId}
															key={`group${index + 1}`}
													/>
												</div>

											</div>
									);
								})
							}
						</div>
						<DragOverlay>
							{activeId ? <Item id={activeId} dragOverlay /> : null}
						</DragOverlay>
					</div>

				</DndContext>
			</div>
	);
};

export default ReadingMSMT;
