import TitleSection from '../../../../core/components/section/TitleSection';

const EndSectionRemainTime = () => {
	return (
		<TitleSection title='End of Section - Time Remaining'>
			<div className='mt-32'>
				<p className='text-[#000000]'>
					You have seen all of the questions in the Reading section. You have time left to review.
					As long as there is time remaining. you can check your work. Once you leave the Reading
					section. you WILL NOT be able to return to it.
					<br />
					<br />
					Select <span className='font-bold'>Review</span> to go back to the Review screen.
					<br />
					<br />
					Select <span className='font-bold'>Return</span> to go back to the last question in this
					section.
					<br />
					<br />
					Select <span className='font-bold'>Continue</span> to go on to the next section of the
					test.
				</p>
			</div>
		</TitleSection>
	);
};

export default EndSectionRemainTime;
