const useFullScreen = (elem: HTMLElement) => {

	const openFullscreen = async () => {
		if (elem.requestFullscreen) {
			await elem.requestFullscreen();
		}
	};

	const closeFullscreen = async () => {
		if (document.exitFullscreen) {
			await document.exitFullscreen();
		}
	};

	return [openFullscreen, closeFullscreen];
};

export default useFullScreen;