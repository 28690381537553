import { Box, Pagination, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import WritingBaseOnKnowledgeAndExperienceDirections from '../../../test-share/writing/writing-based-on-knowledge/writing-based-on-knowledge';
import WritingBasedOnReadingAndListeningDirections from '../../../test-share/writing/writing-based-on-reading-direction/writing-based-on-reading-direction';

const GroupDirection = () => {
	const [page, setPage] = useState(1);

	function handleChange(event: ChangeEvent<unknown>, selected: number) {
		setPage(selected);
	}

	return (
		<div>
			{page === 1 && <WritingBasedOnReadingAndListeningDirections />}
			{page === 2 && <WritingBaseOnKnowledgeAndExperienceDirections />}
			<Box className='flex justify-center mt-10 bg-white'>
				<Stack className='fixed bottom-20' spacing={2}>
					<Pagination count={2} page={page} onChange={handleChange} color='primary' />
				</Stack>
			</Box>
		</div>
	);
};

export default GroupDirection;
