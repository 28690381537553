import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import arrowRightSky from '../../../../../assets/icons/arrow-right-sky.svg';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { userSelector } from '../../../../core/redux/AuthSlice';
import { onTestController, setAnswer, toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import EditorToolbar, { formats, modules } from './editor-toolbar.js';
import './writing-request.scss';
import { setTracks } from '../../../../core/redux/AudioSlice';
import { useDialog } from '../../../../utils/context/DialogContext';
import WritingEndedDialog from '../../../../core/components/modals/WritingEndedModal';

const WritingRequest = () => {
	const dispatch = useAppDispatch();
	const { content, data } = useAppSelector(toeflSelector);
	const [state, setState] = useState('');
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);
	const [showWordCount, setShowWordCount] = useState(false);
	const [writingLength, setWritingLength] = useState(0);
	const [openDialog, closeDialog] = useDialog();
	let reactQuillRef: any = null;
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];

		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 1,
			});
		});

		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}

	const handleChange = (value: any) => {
		console.log(reactQuillRef.getEditor().getLength());
		setWritingLength(reactQuillRef.getEditor().getLength() - 1);
		dispatch(setAnswer({ WRITTEN_TEXT: value }));
	};

	const cutChange = async (text: string) => {
		if (!text) return;
		await navigator.clipboard.writeText(text);
		const newStr = state.replace(text, '');
		setState(newStr);
	};

	const onNextStep = () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};

		closeDialog();
		dispatch(onTestController({ body }));
	};

	const endedDialog = () => {
		openDialog({
			children: (
					<WritingEndedDialog onClose={onNextStep} />
			),
		});
	};

	const nextStepAfterWritingTime = useCallback(
			() => {
				if (content.WRITING_TIME) {
					setTimeout(() => {
						endedDialog();
					}, content.WRITING_TIME * 1000);
				}
			}, [SESSION, USER_ID, content.WRITING_TIME, data.next_step, data.test_code, data.test_mode, dispatch]);

	useEffect(() => {
		if (content.ATTACH_FILE_LIST) {
			renderAudio();
		}
	}, [content.ATTACH_FILE_LIST]);

	useEffect(() => {
		nextStepAfterWritingTime();
	}, [nextStepAfterWritingTime]);

	return (
			<div className='writing-container flex flex-col h-auto'>
				<div className='w-full border-2 p-5' style={{ backgroundColor: '#DFF1FE' }}>
					<div contentEditable='false' dangerouslySetInnerHTML={{ __html: content.DIRECTION }}></div>
				</div>
				<div className='w-full h-full flex border-r-2 border-l-2 border-b-2'>
					<div className='w-2/4 p-5 border-r-2 h-auto'>
						{content.FLOW_CNTL_NAME === 'Writing WKE' ? (
								<div
										className='qno-content'
										contentEditable='false'
										dangerouslySetInnerHTML={{ __html: content.QNO_CONTENT }}
								></div>
						) : (
								content.PASSAGELIST.map((passage: any, index: number) => {
									return (
											<div key={index} className='mb-5'>
												{passage.PASSAGE}
											</div>
									);
								})
						)}
					</div>
					<div className='w-2/4 p-5'>
						<div className='w-full p-2 flex justify-between items-center mb-3'>
							<div className='font-bold flex'>
								<img className='mr-2' src={arrowRightSky} alt='right arrow' />
								This is test essay
							</div>
							<div className='flex items-center'>
								<button
										onClick={() => setShowWordCount(!showWordCount)}
										className='bg-white rounded border-2 border-black text-black font-bold p-1'
								>
									Hide Word Count
								</button>
								{showWordCount ? null : <div className='ml-5'>Word count: {writingLength}</div>}
							</div>
						</div>
						<div className='text-editor'>
							<EditorToolbar />
						</div>
						<ReactQuill
								ref={(el) => {
									reactQuillRef = el;
								}}
								theme='snow'
								onChange={handleChange}
								modules={modules}
								formats={formats}
						/>
					</div>
				</div>
				{content.ATTACH_FILE_LIST ? <AudioPlayer visible={false} /> : ''}
			</div>
	);
};

export default WritingRequest;
