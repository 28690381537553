import { AppBar, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import return_icon from '../../../../assets/icons/Arrow-transparent-left.svg';
import logo from '../../../../assets/icons/G-LOGO.svg';
import Footer from '../../../core/common/footer/Footer';
import CButton from '../../../core/components/Button/CButton';
import { ButtonEnum } from '../../../core/constants/button.enum';
import HelpTab from './components/help-tab';
import GeneralDescriptions from './pages/general-descriptions';
import ListenHowToAnswer from './pages/how-to-answer';
import ReadingThePassage from './pages/reading-the-passage';
import SectionDirections from './pages/section-directions';
import TestingTools from './pages/testing-tools';
import { useAppDispatch } from '../../../core/hooks';
import { updateNextBtn } from '../../../core/redux/TopBarSlice';

const enum listenHelpPageType {
	HELP_PAGE_TYPE_TESTING_TOOLS = 0,
	HELP_PAGE_TYPE_HOW_TO_ANSWER = 1,
	HELP_PAGE_TYPE_READING_THE_PASSAGE = 2,
	HELP_PAGE_TYPE_GENERAL_DESCRIPTION = 3,
	HELP_PAGE_TYPE_SECTION_DIRECTIONS = 4,
}

const ListeningHelp = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState<number>(
			listenHelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS,
	);

	const returnTesting = () => {
		dispatch(updateNextBtn(true));
		navigate('/testing', { replace: true });
	};

	return (
			<div className='flex flex-col h-screen'>
				<AppBar position='sticky' className='pt-[19px]' sx={{ backgroundColor: '#313131' }}>
					<Box className='flex justify-between px-[25px] pb-4'>
						<Box
								className='self-center mb-2'
								component={'img'}
								src={logo}
								alt={'logo'}
								sx={{ height: '38.9px', marginRight: 2 }}
						/>
						<Box>
							<CButton text={ButtonEnum.RETURN} icon={return_icon} onClick={returnTesting} />
						</Box>
					</Box>
					<Box className='bg-[#EDE2A9] h-8 border-b-black border-b flex '>
						<nav className='px-10 self-end'>
							<ul className='flex'>
								<HelpTab
										isActive={currentPage === listenHelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS}
										handleClick={() => setCurrentPage(listenHelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS)}
										title='Testing Tools'
								/>
								<HelpTab
										isActive={currentPage === listenHelpPageType.HELP_PAGE_TYPE_HOW_TO_ANSWER}
										handleClick={() => setCurrentPage(listenHelpPageType.HELP_PAGE_TYPE_HOW_TO_ANSWER)}
										title='How to Answer'
								/>
								<HelpTab
										isActive={currentPage === listenHelpPageType.HELP_PAGE_TYPE_READING_THE_PASSAGE}
										handleClick={() =>
												setCurrentPage(listenHelpPageType.HELP_PAGE_TYPE_READING_THE_PASSAGE)
										}
										title='Reading the Passage'
								/>
								<HelpTab
										isActive={currentPage === listenHelpPageType.HELP_PAGE_TYPE_GENERAL_DESCRIPTION}
										handleClick={() =>
												setCurrentPage(listenHelpPageType.HELP_PAGE_TYPE_GENERAL_DESCRIPTION)
										}
										title='General Descriptions'
								/>
								<HelpTab
										isActive={currentPage === listenHelpPageType.HELP_PAGE_TYPE_SECTION_DIRECTIONS}
										handleClick={() =>
												setCurrentPage(listenHelpPageType.HELP_PAGE_TYPE_SECTION_DIRECTIONS)
										}
										title='Section Directions'
								/>
							</ul>
						</nav>
					</Box>
				</AppBar>
				<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						className='mt-[10px] flex-1 overflow-auto'
				>
					{currentPage === listenHelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS && <TestingTools />}
					{currentPage === listenHelpPageType.HELP_PAGE_TYPE_HOW_TO_ANSWER && <ListenHowToAnswer />}
					{currentPage === listenHelpPageType.HELP_PAGE_TYPE_READING_THE_PASSAGE && (
							<ReadingThePassage />
					)}
					{currentPage === listenHelpPageType.HELP_PAGE_TYPE_GENERAL_DESCRIPTION && (
							<GeneralDescriptions />
					)}
					{currentPage === listenHelpPageType.HELP_PAGE_TYPE_SECTION_DIRECTIONS && (
							<SectionDirections />
					)}
				</motion.div>
				<Footer />
			</div>
	);
};

export default ListeningHelp;
