import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { setAnswer, toeflSelector, updateGuessAnswer } from '../../../core/redux/ToeflSlice';
import './reading-passage-only-question.scss';
import blackdot from '../../../../assets/icons/blackdot.svg';

import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import Droppable from './components/Droppable';
import Item from './components/Item';
import { arrayMove, insertAtIndex, removeAtIndex } from './utils/array';
import { topBarSelector, updateScrollBottom } from '../../../core/redux/TopBarSlice';
import ReadingMSMT from './reading-msmt/reading-msmt';
import correctIcon from '../../../../assets/icons/Vector.png';
import wrongIcon from '../../../../assets/icons/Vector-1.png';
import ReadingPassage from '../reading-passage/reading-passage';

const ReadingPassageOnlyQuestion = () => {
	const dispatch = useAppDispatch();
	const { showAnswer, MSCTViewMode, correctQuestion } = useAppSelector(topBarSelector);
	const { content, answer } = useAppSelector(toeflSelector);
	const [itemGroups, setItemGroups] = useState<any>({
		group1: [],
		group2: [],
	});

	const [backupItemGroups, setBackupItemGroups] = useState<any>({
		group1: [],
		group2: [],
	});
	const [checkCorrectAnswer, setCheckCorrectAnswer] = useState(true);
	useEffect(() => {
		if (backupItemGroups.group1.length) {
			let checkWrong = 0;

			for (const row of content.SRC_ROW_LIST) {
				for (const col_list of row.SRC_COL_LIST) {
					const choice = backupItemGroups.group1.find((item: any) => item == col_list.SRC_ITEM_CONTENT);
					if (choice && col_list.CORRECT_ANSWER_YN === 'N') {
						checkWrong++;
					}
				}
			}
			setCheckCorrectAnswer(checkWrong > 0 ? false : true);
		} else {
			setCheckCorrectAnswer(false);
		}
	}, [showAnswer]);

	useEffect(() => {
		setCheckCorrectAnswer(correctQuestion);
	}, [correctQuestion]);

	useEffect(() => {
		if (!showAnswer) {
			setBackupItemGroups(itemGroups);
		}
	}, [itemGroups]);

	useEffect(() => {
		const groups: any = {
			group1: [],
			group2: [],
		};
		if (showAnswer) {
			for (const row of content.SRC_ROW_LIST) {
				for (const col of row.SRC_COL_LIST) {
					if (col.COL_FLAG === 'true') {
						groups.group1.push(col.SRC_ITEM_CONTENT);
					} else {
						groups.group2.push(col.SRC_ITEM_CONTENT);

					}
				}
			}
			setItemGroups(groups);
		} else {
			setItemGroups(backupItemGroups);
		}
	}, [showAnswer]);

	useEffect(() => {
		dispatch(updateScrollBottom(true));
		initItemGroups();
	}, []);

	useEffect(() => {
		onSetAnswer();
	}, [itemGroups.group1]);

	function onSetAnswer() {
		const answwers: any = [];
		if (itemGroups.group1.length === 0) {

		} else {
			content.SRC_ROW_LIST?.map((row: any) => {
				row.SRC_COL_LIST.map((col: any) => {
					if (itemGroups.group1.includes(col.SRC_ITEM_CONTENT)) {
						answwers.push(col.ITEM_SEQ.toString());
					}
				});
			});
		}
		dispatch(setAnswer({ ...answer, ANSWER: answwers }));
		dispatch(updateGuessAnswer({ GUESS_ANSWER: answwers }));
	}


	const [activeId, setActiveId] = useState(null);

	const sensors = useSensors(
			useSensor(MouseSensor),
			useSensor(TouchSensor),
			useSensor(KeyboardSensor, {
				coordinateGetter: sortableKeyboardCoordinates,
			}),
	);

	function initItemGroups() {
		if (content.USER_CHECK_NO.length) {
			const tempItemGroups = _.cloneDeep(itemGroups);

			content.SRC_ROW_LIST?.map((row: any) => {
				row.SRC_COL_LIST.map((col: any) => {
					if (content.USER_CHECK_NO.includes(col.SRC_ITEM_NO)) {
						tempItemGroups.group1.push(col.SRC_ITEM_CONTENT)
					} else {
						tempItemGroups.group2.push(col.SRC_ITEM_CONTENT)
					}
				});
			});
			setItemGroups(tempItemGroups);
			setBackupItemGroups(tempItemGroups);

		} else {
			const items: any = [];
			content.SRC_ROW_LIST?.map((row: any) => {
				row.SRC_COL_LIST.map((col: any) => {
					items.push(col.SRC_ITEM_CONTENT);
				});
			});
			const tempItemGroups = _.cloneDeep(itemGroups);
			tempItemGroups.group2 = items;
			setItemGroups(tempItemGroups);
			setBackupItemGroups(tempItemGroups);
		}

	}

	const handleDragStart = ({ active }: any) => setActiveId(active.id);

	const handleDragCancel = () => setActiveId(null);

	const handleDragOver = ({ active, over }: any) => {
		const overId = over?.id;

		if (!overId) {
			return;
		}

		const activeContainer = active.data.current.sortable.containerId;
		const overContainer = over.data.current?.sortable.containerId || over.id;

		if (activeContainer !== overContainer) {
			setItemGroups((itemGroups: any) => {
				const activeIndex = active.data.current.sortable.index;
				const overIndex =
						over.id in itemGroups
								? itemGroups[overContainer].length + 1
								: over.data.current.sortable.index;

				return moveBetweenContainers(
						itemGroups,
						activeContainer,
						activeIndex,
						overContainer,
						overIndex,
						active.id,
				);
			});
		}
	};

	const handleDragEnd = ({ active, over }: any) => {
		if (!over) {
			setActiveId(null);
			return;
		}

		if (active.id !== over.id) {
			const activeContainer = active.data.current.sortable.containerId;
			const overContainer = over.data.current?.sortable.containerId || over.id;
			const activeIndex = active.data.current.sortable.index;
			const overIndex =
					over.id in itemGroups
							? itemGroups[overContainer].length + 1
							: over.data.current.sortable.index;

			setItemGroups((itemGroups: any) => {
				let newItems;
				if (activeContainer === overContainer) {
					newItems = {
						...itemGroups,
						[overContainer]: arrayMove(
								itemGroups[overContainer],
								activeIndex,
								overIndex,
						),
					};
				} else {
					newItems = moveBetweenContainers(
							itemGroups,
							activeContainer,
							activeIndex,
							overContainer,
							overIndex,
							active.id,
					);
				}

				return newItems;
			});
		}

		setActiveId(null);
	};

	const moveBetweenContainers = (
			items: any,
			activeContainer: any,
			activeIndex: any,
			overContainer: any,
			overIndex: any,
			item: any,
	) => {
		return {
			...items,
			[activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
			[overContainer]: insertAtIndex(items[overContainer], overIndex, item),
		};
	};


	if (MSCTViewMode === 'question') {
		return (
				<div className='w-full flex items-center justify-center flex-col'>
					<div className='w-3/4 mb-5'>
						{
							showAnswer && content.QNOTYPE === 'MSMT' ?
									<img className='float-left mr-2' src={checkCorrectAnswer ? correctIcon : wrongIcon} alt='' /> : ''
						}
						<div
								className='mb-5'
								contentEditable='false'
								dangerouslySetInnerHTML={{ __html: content.QNO_CONTENT }}
						></div>
						<div
								className='bg-neutral-300 p-3 text-center mb-5'
								contentEditable='false'
								dangerouslySetInnerHTML={{ __html: content.RESPONSE_DIRECTION }}
						></div>
						{
							content.QNOTYPE === 'MSMT' ? ''
									:
									<div className='text-center font-bold'>
										{
											showAnswer ? <img className='float-left mr-2' src={checkCorrectAnswer ? correctIcon : wrongIcon}
											                  alt='' /> : ''
										}
										Scholars have wondered about the meaning of the subjects, location, and overpainting of
										lascaux cave Images.
									</div>
						}
					</div>
					{
						content.QNOTYPE === 'MSMT' ? <ReadingMSMT /> :
								<div className='reading-dnd'>
									<DndContext
											sensors={sensors}
											onDragStart={handleDragStart}
											onDragCancel={handleDragCancel}
											onDragOver={handleDragOver}
											onDragEnd={handleDragEnd}
									>
										<div className='container'>
											<div className='flex border-2 p-10 mb-10 w-full'>
												<div className='row-selected'>
													<div><img className='mr-5' src={blackdot} alt='right arrow' /></div>
													<div><img className='mr-5' src={blackdot} alt='right arrow' /></div>
													<div><img className='mr-5' src={blackdot} alt='right arrow' /></div>
												</div>
												<Droppable
														id='group1'
														items={itemGroups.group1}
														activeId={activeId}
														key='group1'
														active={false}
												/>
											</div>

											<Droppable
													id='group2'
													items={itemGroups.group2}
													activeId={activeId}
													key='group2'
													active={itemGroups.group2.length === 3}
											/>
										</div>
										<DragOverlay>
											{activeId ? <Item id={activeId} dragOverlay /> : null}
										</DragOverlay>
									</DndContext>
								</div>
					}
				</div>
		);
	}

	return <ReadingPassage />;
};

export default ReadingPassageOnlyQuestion;
