import { Box, Paper } from '@mui/material';
import ArrowTitle from '../components/arrow-title';
import checkGroup from '../../../../../assets/imgs/check-group.png';
import checkbox from '../../../../../assets/icons/checkbox.svg';

const HowToAnswerSub3 = () => {
	return (
		<Paper className='p-10' elevation={3}>
			<p className='mb-5'>
				For this type of question, you will select more than one answer choice.
			</p>
			<ArrowTitle title='How to answer:' />
			<Box className='mb-10'>
				<p className='ml-10'>
					● Select your answer choices. You may select the box or anywhere on the answer choice.
					<br />
				</p>
				<p className='ml-10 flex'>
					Your answer choices will be indicated by <b>Square Check Box.</b>
					<img src={checkbox} className='ml-3' alt='checkbox'></img>
				</p>
			</Box>

			<ArrowTitle title='How to Change Your Answer Choices:' />
			<Box className='mb-5'>
				<p className='ml-10'>
					● Select the answer choice you want to change. THe “x” will erase and you can select
					another answer choice.
				</p>
			</Box>

			<div className='border-2 border-dashed border-gray-400 p-5 w-96 ml-10 mb-5'>
				<p className='mb-3'>Which two cities are in the United States of America?</p>
				<p className='bg-gray-200 px-5 py-2 text-center mx-auto mb-5'>Select 2 answers</p>
				<img className='w-28 mx-auto' alt='radio group' src={checkGroup} />

				{/* <FormGroup className='text-center'>
					<FormControlLabel control={<Checkbox defaultChecked />} label='Chicago' />
					<FormControlLabel control={<Checkbox />} label='Montreal' />
					<FormControlLabel control={<Checkbox />} label='Rome' />
					<FormControlLabel control={<Checkbox defaultChecked />} label='New York' />
				</FormGroup> */}
			</div>
			<p>
				Select <b>Return</b> to go on.
			</p>
		</Paper>
	);
};

export default HowToAnswerSub3;
