interface ProgressElementProps {
	color: 'none' | 'good' | 'bad';
	idx: number;
	progress: number;
}

const ProgressElement = ({ color = 'none', idx, progress }: ProgressElementProps) => {
	return (
		<div
			className={
				'w-5 h-10 rounded-full ' +
				(color === 'none' || idx > progress
					? 'bg-gray-300'
					: color === 'good'
					? 'bg-green-600'
					: 'bg-red-600')
			}
		></div>
	);
};

export default ProgressElement;
