import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../core/hooks';
import { settleData, ToeflState } from '../../core/redux/ToeflSlice';
import FinishedList from './finishedlist/finished-list';
import IngList from './inglist/ing-list';
import StartList from './startlist/start-list';
import { resetTopBarState } from '../../core/redux/TopBarSlice';
import { resetAudioState } from '../../core/redux/AudioSlice';
import { LocalStorageService } from '../../core/services/storage.service';
import { User } from '../../core/models/userResponse.model';

const MyPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const storage = new LocalStorageService();
	const user: User = storage.getItemWithValue('USER');

	const resetToefl: ToeflState = {
		data: {
			setlcd: '',
			curr_step: '',
			test_code: '',
			test_mode: '',
			next_step: '',
			prev_step: '',
			direction_mode: '',
			control_type: '',
			flow_cntl_name: '',
			section_id: '',
			remain_time: 0,
		},
		content: {},
		answer: {},
		expired_time: 0,
		expired: false,
		playback: {},
		guess_answer: {},
		highlight: {},
		reading: {
			current_passage_step: '',
			reading_content: {},
		},
		printComponent: null,
		testName: '',
		status: 'idle',
		strikeout: {},
		highlights: [],
	};

	const onLogout = () => {
		dispatch(settleData(resetToefl));
		dispatch(resetTopBarState());
		dispatch(resetAudioState());
		localStorage.clear();
		navigate('/login', { replace: true });
	};

	useEffect(() => {
		dispatch(settleData(resetToefl));
		dispatch(resetTopBarState());
		dispatch(resetAudioState());
	}, []);

	return (
			<Box paddingX={2}>
				<Box className='flex justify-end px-5 mt-4'>
					<Button
							size='small'
							variant='contained'
							color='warning'
							onClick={onLogout}
							className='bg-gradient-to-b from-[#D5D5D5] to-[#656565] border border-black border-solid w-20 h-9'
					>
						로그아웃
					</Button>
				</Box>
				<StartList user={user} />
				<IngList user={user} />
				<FinishedList user={user} />
			</Box>
	);
};

export default MyPage;
