import { AppBar, Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import return_icon from '../../../assets/icons/Arrow-transparent-left.svg';
import logo from '../../../assets/icons/G-LOGO.svg';
import section_exit_icon from '../../../assets/icons/log-out-04.svg';
import pause_icon from '../../../assets/icons/pause-circle.svg';
import return_question_icon from '../../../assets/icons/return-circle.svg';
import Footer from '../../core/common/footer/Footer';
import CButton from '../../core/components/Button/CButton';
import CountdownRemainTime from '../../core/components/countdown/CountdownRemainTime';
import { ButtonEnum } from '../../core/constants/button.enum';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { User } from '../../core/models/userResponse.model';
import { userSelector } from '../../core/redux/AuthSlice';
import { onReview, onTestController, toeflSelector } from '../../core/redux/ToeflSlice';
import TestRepository from '../../core/repositories/TestRepository';
import { LocalStorageService } from '../../core/services/storage.service';
import PassageTable from './components/passage-table';
import './review.scss';

interface ReviewResponse {
	BUTTON_LIST: [];
	PASSAGE_LIST: [{ PASSAGE_TITLE: string; TEST_ITEM_LIST: [] }];
	TOTAL_COUNT: number;
}

export interface SelectedRowProps {
	SEQ: number;
	TEST_ITEM_RESULT_NAME: string;
	TEST_ITEM_FLOWSEQ: string;
}

const Review = () => {
	const appDispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);

	const { data, content, expired_time, answer } = useAppSelector(toeflSelector);
	const [review, setReview] = useState<ReviewResponse>();

	const storage = useMemo(() => new LocalStorageService(), []);
	const testRepository = new TestRepository();
	const [selectedRow, setSelectedRow] = useState<SelectedRowProps>({
		SEQ: -1,
		TEST_ITEM_RESULT_NAME: '',
		TEST_ITEM_FLOWSEQ: '',
	});

	const onSuccess = (data: any) => {
		setReview(data.BODY);
	};

	const onRequestReview = useCallback(() => {
		const user: User = storage.getItemWithValue('USER');
		if (!user) {
			return;
		}
		const body = {
			HEADER: {
				SESSION: user.SESSION,
				USER_ID: user.USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		};

		if (Object.keys(answer).length > 0) {
			body.BODY = { ...body.BODY, ...answer };
		}

		appDispatch(onReview({ body, onSuccess }));
	}, [appDispatch, data.curr_step, data.test_code, storage]);

	const returnTesting = () => {
		navigate('/testing', { replace: true });
	};

	const returnToQuestion = async () => {
		if (selectedRow.TEST_ITEM_RESULT_NAME === 'Not Seen') {
			return;
		}
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: selectedRow.TEST_ITEM_FLOWSEQ,
				TEST_MODE: data.test_mode,
			},
		};

		const successReturn = await appDispatch(onTestController({ body }));
		if (successReturn.meta.requestStatus === 'fulfilled') {
			returnTesting();
		}
	};

	/* Section Pause button handle */
	const onPauseButton = async () => {
		const pause = await testRepository.onPauseTest({
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		});

		if (pause.RESPONSE.CODE === '1000') {
			navigate('/my-page', { replace: true });
		}
	};
	/* End section */

	/* Section exit handle */
	const onSectionExit = async () => {
		const section = await testRepository.onSectionTest({
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
				REMAIN_TIME: Math.ceil((expired_time - Date.now()) / 1000),
			},
		});

		appDispatch(onTestController(section));

		navigate('/testing', { replace: true });
	};
	/* End section */

	useEffect(() => {
		onRequestReview();
	}, [onRequestReview]);

	return (
			<div className='flex flex-col h-screen'>
				<AppBar position='sticky' className='pt-[19px]' sx={{ backgroundColor: '#313131' }}>
					<Box className='flex justify-between px-[25px] pb-4'>
						<Box
								className='self-center mb-2'
								component={'img'}
								src={logo}
								alt={'logo'}
								sx={{ height: '38.9px', marginRight: 2 }}
						/>
						<Box className='flex flex-1 justify-between'>
							<Box className='left-col'>
								<CButton text={ButtonEnum.PAUSE} icon={pause_icon} onClick={onPauseButton} />
								<CButton
										text={ButtonEnum.SECTION_EXIT}
										icon={section_exit_icon}
										onClick={onSectionExit}
								/>
							</Box>
							<Box className='right-col'>
								<CButton text={ButtonEnum.RETURN} icon={return_icon} onClick={returnTesting} />
								<CButton
										text={ButtonEnum.RETURN_QUESTION}
										icon={return_question_icon}
										width={'155px'}
										onClick={returnToQuestion}
								/>
							</Box>
						</Box>
					</Box>
					<Box className='bg-[#EDE2A9] h-8 border-b-black border-b flex px-5'>
						{content.SECTION_TITLE && content.SECTION_TITLE !== '' ? (
								<Box className='self-center'>
									<Typography className='font-semibold' variant='body1' color={'#313131'}>
										{content.SECTION_TITLE}
									</Typography>
								</Box>
						) : null}
						{content.TEST_MODE && content.TEST_MODE === 'T' ? <CountdownRemainTime /> : null}
					</Box>
				</AppBar>
				<Box className='mt-[10px] flex-1 overflow-auto'>
					{review ? (
							<Box className='w-full max-w-[1024px] mx-auto mt-10 mb-5'>
								<Box className='px-4'>
									<Typography variant='h6'>Review</Typography>
									<hr className='my-4' />
									<p className='text-[14px]'>
										Following is the list of questions in this part of the Reading Section. The question
										you were on last is highlighted when you enter Review. The Status column shows if a
										question has been Answered. Not Answered. or Not Encountered. When a question is
										worth more than one point. the Status column will indicate that the question has
										been answered. even if it is only partially answered.
										<br />
										<br />
										To review a specific question from the list. select the question to highlight it.
										then select <strong>Go to Question</strong>. To sort the list by number or status,
										select the column heading. When there are more questions than will fit on the
										screen. you can use the scroll bar to access the others.
										<br />
										<br />
										To leave Review and return to where you were in the test, select{' '}
										<strong>Return</strong>.
									</p>
								</Box>
								<Box className='h-[60px]' />
								<Box className='flex flex-col justify-center text-center'>
									<Typography variant='h5'>30 Questions sorted by Number in Ascending Order</Typography>
									<Box className='my-4' />
									{review?.PASSAGE_LIST ? (
											<PassageTable
													listPassage={review?.PASSAGE_LIST}
													selectedRow={selectedRow}
													setSelectedRow={setSelectedRow}
											/>
									) : null}
								</Box>
							</Box>
					) : (
							<Box className='flex items-center justify-center h-screen'>
								<CircularProgress />
							</Box>
					)}
				</Box>
				<Footer />
			</div>
	);
};

export default Review;
