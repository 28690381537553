import TitleSection from '../../../core/components/section/TitleSection';

const HardwareCheck = () => {
	return (
		<TitleSection title='Hardware Check'>
			<div>
				<div className='mb-5'>
					Before the test begins, we will check the microphone and headset volume.
				</div>
				<div>
					Select <span className='font-bold'>Continue</span> to go on.
				</div>
			</div>
		</TitleSection>
	);
};

export default HardwareCheck;
