import ApiService from '../services/api.service';

class MyPageRepository {
	async getStartList(SESSION: string) {
		try {
			const body = {
				HEADER: { SESSION },
				BODY: {},
			};
			const data = await ApiService.POST('/mypage/test/startlist.svc', body);
			if (data.RESPONSE.CODE === '1000') {
				return data;
			} else {
				throw data.RESPONSE;
			}
		} catch (error: any) {
			throw error;
		}
	}

	async getIngList(SESSION: string) {
		try {
			const body = {
				HEADER: { SESSION },
				BODY: {},
			};
			const data = await ApiService.POST('/mypage/test/inglist.svc', body);
			if (data.RESPONSE.CODE === '1000') {
				return data;
			} else {
				throw data.RESPONSE;
			}
		} catch (error: any) {
			throw error;
		}
	}

	async getFinishedList(SESSION: string) {
		try {
			const body = {
				HEADER: { SESSION },
				BODY: {},
			};
			const data = await ApiService.POST('/mypage/test/finishedlist.svc', body);
			if (data.RESPONSE.CODE === '1000') {
				return data;
			} else {
				throw data.RESPONSE;
			}
		} catch (error: any) {
			throw error;
		}
	}
}

export default MyPageRepository;
