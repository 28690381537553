import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import TitleSection from '../../../../core/components/section/TitleSection';
import { useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { userSelector } from '../../../../core/redux/AuthSlice';

const DirBreak = () => {
	const { testName } = useAppSelector(toeflSelector);
	const { user: { USER_ID } } = useAppSelector(userSelector);
	return (
			<TitleSection title='Break'>
				<Box>
					<p>Your 10 minutes break has begun. The remain time is indicate on this screen.</p>
					<br />
					<p>
						The record is on hold and you answers have been recorded. If you choose to leave this test
						room, please see the test center staff and take any personal belongings with you. The test
						center will not be responsible for personal belongings.
					</p>
					<br />
					<p>
						Please return to this testing station within the time limit. The test center staff will
						need to see you photo ID when you return, so be sure to take it with you.
					</p>
					<br />
					<p>You may NOT take any papers from the testing station with you.</p>
					<br />
					<p>You may leave now. Please return on time.</p>
					<br />
					<Box className='w-[70%] flex flex-col justify-center mx-auto'>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell sx={{ width: '250px' }} className={'border-none'} padding={'none'} align={'right'}>
										<Typography sx={{ fontWeight: 'bold' }}>Test:</Typography>
									</TableCell>
									<TableCell sx={{ width: '200px' }} className={'border-none pl-1'} padding={'none'}>
										<Typography sx={{ fontWeight: 'bold' }} className={'underline'}>TOEFL®</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={'border-none'} padding={'none'} align={'right'}>
										<Typography sx={{ fontWeight: 'bold' }}>Name:</Typography>
									</TableCell>
									<TableCell className={'border-none pl-1'} padding={'none'}>
										<Typography sx={{ fontWeight: 'bold' }} className={'underline'}>{testName}</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={'border-none'} padding={'none'} align={'right'}>
										<Typography sx={{ fontWeight: 'bold' }}>ExamineID:</Typography>
									</TableCell>
									<TableCell className={'border-none pl-1'} padding={'none'}>
										<Typography sx={{ fontWeight: 'bold' }} className={'underline'}>{USER_ID}</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				</Box>
			</TitleSection>
	);
};

export default DirBreak;
