import { Fragment } from 'react';
import { SelectedRowProps } from '..';

interface PassageProps {
	listPassage: [{ PASSAGE_TITLE: string; TEST_ITEM_LIST: [] }];
	selectedRow: SelectedRowProps;
	setSelectedRow: (val: SelectedRowProps) => void;
}

const PassageTable = ({ listPassage, selectedRow, setSelectedRow }: PassageProps) => {
	return (
		<table className='border-solid border-[3px] border-slate-900'>
			<thead>
				<tr>
					<td className='border border-slate-900 font-bold' align='center'>
						Number
					</td>
					<td className='border border-slate-900 w-[600px] font-bold' align='center'>
						Description
					</td>
					<td className='border border-slate-900 font-bold' align='center'>
						Status
					</td>
				</tr>
			</thead>
			<tbody>
				{listPassage.map((val, index) => (
					<Fragment key={index}>
						<tr>
							<td align='center' colSpan={3} className='font-bold'>
								{val.PASSAGE_TITLE}
							</td>
						</tr>
						{val.TEST_ITEM_LIST.map((item: any) => (
							<tr
								key={item.SEQ}
								onClick={() =>
									setSelectedRow({
										SEQ: item.SEQ,
										TEST_ITEM_RESULT_NAME: item.TEST_ITEM_RESULT_NAME,
										TEST_ITEM_FLOWSEQ: item.TEST_ITEM_FLOWSEQ,
									})
								}
								className='cursor-pointer'
								style={{ backgroundColor: selectedRow.SEQ === item.SEQ ? '#cdebf9' : '' }}
							>
								<td className='border border-slate-900' align='center'>
									{item.TEST_ITEM_NO}
								</td>
								<td className='border border-slate-900 pl-2' align='left'>
									<div dangerouslySetInnerHTML={{ __html: item.TEST_ITEM_CONTENT }}></div>
								</td>
								<td className='border border-slate-900' align='center'>
									{item.TEST_ITEM_RESULT_NAME}
								</td>
							</tr>
						))}
					</Fragment>
				))}
			</tbody>
		</table>
	);
};

export default PassageTable;
