import { Typography } from '@mui/material';
import { useAppSelector } from '../../hooks';
import { toeflSelector } from '../../redux/ToeflSlice';
import './Footer.scss';

const Footer = () => {
	const { content } = useAppSelector(toeflSelector);

	return (
		<footer className='footer flex flex-col items-center'>
			<Typography variant='body2' className='self-center'>
				Copyright {content.COPYRIGHT} by Educational Testing Service.
			</Typography>
			<Typography variant='body2' className='self-center text-center'>
				All rights reserved. EDUCATIONAL TESTING SERVICE, ETS, the ETS logo, TOEFL and TOEFL iBT are
				registered trademarks of Educational Testing Service (ETS) in the United States and other
				countries.
			</Typography>
		</footer>
	);
};

export default Footer;
