import { Pagination, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { unwrapResult } from '@reduxjs/toolkit';
import { motion } from 'framer-motion';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrowRightSky from '../../../../assets/icons/arrow-right-sky.svg';
import SkeletonTable from '../../../core/components/SkeletonTable/SkeletonTable';
import { MY_PAGE_PAGE_SIZE } from '../../../core/constants';
import { useAppDispatch } from '../../../core/hooks';
import { onTestStart, updateExpiredTime, updateTestName } from '../../../core/redux/ToeflSlice';
import MyPageRepository from '../../../core/repositories/MyPageRepository';
import { useDialog } from '../../../utils/context/DialogContext';
import { User } from '../../../core/models/userResponse.model';
import EndSessionModal from '../../../core/components/modals/EndSessionModal';

interface MyPageProps {
	user: User;
}

const IngList = ({ user }: MyPageProps) => {
	const myPageRepository = useCallback(() => new MyPageRepository(), []);
	const [openDialog, closeDialog] = useDialog();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [list, setList] = useState<any[]>([]);
	const [totalPage, setTotalPage] = useState(0);
	const [page, setPage] = useState(1);
	const [pageItem, setPageItem] = useState<any[]>([]);

	const onCloseDialog = () => {
		closeDialog();
		navigate('/login', { replace: true });
	};

	const onOpenDialog = () => {
		openDialog({
			children: (
					<EndSessionModal closeDialog={onCloseDialog} />
			),
		});
	};

	const getList = useCallback(
			async () => {
				try {
					const res = await myPageRepository().getIngList(user.SESSION);
					if (res) {
						setTotalPage(Math.ceil(res.BODY.TEST_LIST.length / MY_PAGE_PAGE_SIZE));
						setList(res.BODY.TEST_LIST);
					}
				} catch (error) {
					onOpenDialog();
				}
			}, []);

	const StyledTableRow = styled(TableRow)((_) => ({
		'&:last-child td, &:last-child th': { border: 0 },
		'&:nth-of-type(odd)': { backgroundColor: '#F1F2F3' },
	}));

	useEffect(() => {
		getList();
	}, [getList]);

	useEffect(() => {
		setPageItem(list.slice(0, MY_PAGE_PAGE_SIZE));
	}, [list]);


	const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
		setPage(value);
		setPageItem(list.slice((page - 1) * MY_PAGE_PAGE_SIZE, page * MY_PAGE_PAGE_SIZE));
	};

	const handleTestStart = async (item: any) => {
		const body = {
			HEADER: {
				SESSION: user.SESSION,
				USER_ID: user.USER_ID,
			},
			BODY: {
				TEST_CODE: item.TEST_CODE,
				SETTLE_CODE: item.SETLCD,
			},
		};

		dispatch(updateTestName(item.ITEM_NAME));

		const onSuccess = (data: any) => {
			navigate(`/testing`, { replace: true });
		};

		try {
			const testStart = await dispatch(onTestStart({ body, onSuccess }));
			const { content } = unwrapResult(testStart);
			if (content.TEST_MODE === 'T' && content.REMAIN_TIME > 0) {
				dispatch(updateExpiredTime(Date.now() + content.REMAIN_TIME * 1000));
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
			<div className='p-5 w-full mb-3'>
				<div className='flex items-center gap-2 mb-4'>
					<img src={arrowRightSky} alt='right arrow' />
					<div className='text-base font-bold'>진행 중인 시험</div>
				</div>

				<TableContainer component={Paper} className='w-full'>
					<Table size='small' sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									TEST
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									응시일
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									구매일
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									유효일
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									남은기간
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold w-36' align='center'>
									응시
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list.length >= 1 ? (
									pageItem.map((item, index) => (
											<StyledTableRow key={index}>
												<TableCell align='left' width='450' height='50'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.ITEM_NAME}
													</motion.p>
												</TableCell>
												<TableCell align='center' width='150'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.UPDTM}
													</motion.p>
												</TableCell>
												<TableCell align='center' width='133'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.PURCHASE_DATE}
													</motion.p>
												</TableCell>
												<TableCell align='center' width='133'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.VALID_DATE}
													</motion.p>
												</TableCell>
												<TableCell align='center' width='133'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.REMAINDAY} days
													</motion.p>
												</TableCell>
												<TableCell align='center' className='w-36'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														<Button
																size='small'
																onClick={() => handleTestStart(item)}
																variant='contained'
																color='warning'
																className='bg-gradient-to-b from-[#E7D15D] to-[#B56200] border border-black border-solid w-20 h-9'
														>
															계속하기
														</Button>
													</motion.p>
												</TableCell>
											</StyledTableRow>
									))
							) : (
									list.length === 0 ? <StyledTableRow>
												<TableCell colSpan={6} align='center' width='550' height='50'>
													No test found
												</TableCell>
											</StyledTableRow> :
											<SkeletonTable col={6} row={1} />
							)}
						</TableBody>
					</Table>
					{totalPage > 1 ? (
							<div className='w-full flex justify-center py-5'>
								<Pagination page={page} onChange={handleChangePage} count={totalPage} color='primary'
								            onDoubleClick={(e) => e.stopPropagation()} />
							</div>
					) : null}
				</TableContainer>
			</div>
	);
};

export default IngList;
