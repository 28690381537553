export enum ButtonEnum {
	PAUSE = 'BTN_PAUSE_TEST',
	SECTION_EXIT = 'BTN_SECTION_EXIT',
	HELP = 'BTN_PROG_HELP',
	HELP_DISABLED = 'BTN_PROG_HELP_DISABLED',
	PROG_CONTINUE = 'BTN_PROG_CONTINUE',
	PROG_REVIEW = 'BTN_PROG_REVIEW',
	PROG_REVIEW_DISABLED = 'BTN_PROG_REVIEW_DISABLED',
	PROG_BACK = 'BTN_PROG_BACK',
	PROG_BACK_DISABLED = 'BTN_PROG_BACK_DISABLED',
	PROG_NEXT = 'BTN_PROG_NEXT',
	PROG_NEXT_DISABLED = 'BTN_PROG_NEXT_DISABLED',
	REPLAY_TALK = 'BTN_PROG_REPLAY_TALK',
	REPLAY_TALK_DISABLED = 'BTN_PROG_REPLAY_TALK_DISABLED',
	STOP_TALK = 'BTN_PROG_STOP_TALK',
	VOLUME = 'BTN_PROG_VOLUME',
	OK = 'BTN_PROG_OK',
	OK_DISABLED = 'BTN_PROG_OK_DISABLED',
	RETURN = 'BTN_RETURN',
	REVIEW_RECT = 'BTN_PROG_REVIEW_RECT',
	PLAYBACK = 'BTN_PROG_PLAY_BACK',
	PLAYBACK_DISABLED = 'BTN_PROG_PLAY_BACK_DISABLED',
	RECORD_AGAIN = 'BTN_PROG_RECORD_AGAIN',
	RECORD_AGAIN_DISABLED = 'BTN_PROG_RECORD_AGAIN_DISABLED',
	CONFIRM_RESPONSE = 'BTN_PROG_CONFIRM_RESPONSE',
	CONFIRM_RESPONSE_DISABLED = 'BTN_PROG_CONFIRM_RESPONSE_DISABLED',
	ANSWER = 'BTN_SHOWSOLUTION',
	GUESS = 'BTN_GUESS',
	RETURN_QUESTION = 'BTN_RETURN_QUESTION',
	PRINT = 'BTN_PROG_PRINT',
	EXIT = 'BTN_PROG_EXIT',
}
