import { Box, Paper, Stack } from '@mui/material';
import CButton from '../../../../core/components/Button/CButton';
import continue_icon from '../../../../../assets/icons/Arrow-transparent-right.svg';
import help_icon from '../../../../../assets/icons/help-circle.svg';
import review_icon from '../../../../../assets/icons/review.svg';
import volume_icon from '../../../../../assets/icons/volume-max.svg';
import TitleSection from '../../../../core/components/section/TitleSection';

const TestingTools = () => {
	return (
			<TitleSection title='Testing Tools'>
				<Box>
					<Paper elevation={3}>
						<Stack className='flex flex-col gap-5 justify-center divide-solid divide-y px-10'>
							<Box className='flex items-center pt-10 pb-5'>
								<CButton
										text='BTN_PROG_NEXT'
										icon={continue_icon}
										variant='text'
										color='success'
								></CButton>
								<p className='ml-10 flex-[4_4_0%]'>
									After you answer a question, select Next. You may still change the answer after
									selecting Next.
								</p>
							</Box>
							<Box className='flex items-center py-5'>
								<CButton
										text='BTN_PROG_BACK'
										icon={continue_icon}
										variant='text'
										color='success'
								></CButton>
								<p className='ml-10 flex-[4_4_0%]'>
									Selecting Back will take you to the previous screen or question.
								</p>
							</Box>
							<Box className='flex items-center py-5'>
								<CButton
										text='BTN_PROG_HELP_DISABLED'
										icon={help_icon}
										isBlack={true}
								></CButton>
								<p className='ml-10 flex-[4_4_0%]'>
									Selecting Help will bring you to Help. From Help you can get information on
									different topics by selecting one of the tabs on the screen.
								</p>
							</Box>
							<Box className='flex items-center py-5'>
								<CButton
										text='BTN_PROG_REVIEW_DISABLED'
										icon={review_icon}
										isBlack={true}
								></CButton>
								<p className='ml-10 flex-[4_4_0%]'>
									Selecting Review will allow you to access the Review screen. The Review screen
									lists all of the questions in their part of the reading section and their status.
									The Status column indicates if a question has been answered. not answered. or not
									encountered. The question you were on list is highlighted when you enter the
									Review screen.
								</p>
							</Box>
							<Box className='flex items-center pt-5 pb-10'>
								<CButton
										text='BTN_PROG_VOLUME'
										icon={volume_icon}
										variant='text'
										color='success'
								></CButton>
								<p className='ml-10 flex-[4_4_0%]'>
									Selecting Volume will open the volume control. To change the volume. move the
									volume indicator to the left or to the right. To close the volume control. select
									Volume again.
								</p>
							</Box>
						</Stack>
					</Paper>
				</Box>
				<p className='mt-5'>
					Select <b>Return</b> to go on
				</p>
			</TitleSection>
	);
};

export default TestingTools;
