import { Card, CardContent } from '@mui/material';
import { useCallback, useEffect } from 'react';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { AUDIO_SAMPLE } from '../../../../core/constants';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { audioSelector, setTracks } from '../../../../core/redux/AudioSlice';

export interface AudioProps {
	AUDIO_FILE: string;
	IMG_FILE: string;
	WAIT_TIME: number;
}

const ImageAndListeningAudio = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	const { tracks } = useAppSelector(audioSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	const mapImgAndAudioToArray = useCallback(() => {
		const tempTracks: AudioProps[] = [];
		const refList: AudioProps[] = content.MM_RESOURCE_LIST;
		const fileList: any[] = content.ATTACH_FILE_LIST;

		for (let i = 0; i < refList.length; i++) {
			const audioIdx = fileList.findIndex((val) => refList[i].AUDIO_FILE === val.REF_CODE);
			const imgIdx = fileList.findIndex((val) => refList[i].IMG_FILE === val.REF_CODE);
			if (audioIdx !== -1 && imgIdx !== -1) {
				tempTracks.push({
					AUDIO_FILE: fileList[audioIdx].FILE_DATA,
					IMG_FILE: fileList[imgIdx].FILE_DATA,
					WAIT_TIME: refList[i].WAIT_TIME,
				});
			} else if (audioIdx === -1 && imgIdx !== -1) {
				tempTracks.push({
					AUDIO_FILE: AUDIO_SAMPLE,
					IMG_FILE: fileList[imgIdx].FILE_DATA,
					WAIT_TIME: refList[i].WAIT_TIME,
				});
			}
		}
		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}, [content.ATTACH_FILE_LIST, content.MM_RESOURCE_LIST, dispatch]);

	useEffect(() => {
		mapImgAndAudioToArray();
	}, [content, mapImgAndAudioToArray]);

	return (
			<div className='w-full max-w-[860px] mx-auto mt-10 mb-5'>
				<Card>
					<CardContent>{tracks.length > 0 ? <AudioPlayer /> : null}</CardContent>
				</Card>
			</div>
	);
};

export default ImageAndListeningAudio;
