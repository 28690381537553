import { Box, Paper } from '@mui/material';
import ArrowTitle from '../components/arrow-title';
const HowToAnswerSub2 = () => {
	return (
		<Paper className='p-10' elevation={3}>
			<p className='mb-5'>
				For this type of question, you will add a sentence at an appropriate place in the passage.
			</p>
			<ArrowTitle title='How to answer:' />
			<Box className='mb-10'>
				<p className='ml-10'>● This is done by selecting one of the squares ■ in the passage.</p>
			</Box>

			<ArrowTitle title='How to Change Your Answer Choices:' />
			<Box className='mb-5'>
				<p className='ml-10'>
					● Select a different square, or select the sentence you added, then select a different
					square.
				</p>
			</Box>

			<p className='border-2 border-dashed border-gray-400 p-3 w-96 ml-10 mb-5'>
				The sentence can be added by selecting one of the squares ■. The sentence will appear in the
				passage at the place where you selected the square. THe sentence will be bold text.
			</p>
			<p>
				Select <b>Return</b> to go on.
			</p>
		</Paper>
	);
};

export default HowToAnswerSub2;
