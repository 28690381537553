import { Box } from '@mui/material';
import TitleSection from '../../../../core/components/section/TitleSection';
import headphone_icon from '../../../../../assets/icons/G-Headphone.svg';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { toeflSelector } from '../../../../core/redux/ToeflSlice';
import { AudioProps } from '../image-and-listening-audio/image-and-listening-audio';
import { setTracks } from '../../../../core/redux/AudioSlice';

const ListenSectionDirection = () => {
	const dispatch = useAppDispatch();
	const { content } = useAppSelector(toeflSelector);
	// const [tracks, setTracks] = useState<AudioProps[]>([{} as AudioProps]);

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 1,
			});
		});

		// setTracks(tempTracks);
		dispatch(setTracks(tempTracks));
	}

	useEffect(() => {
		renderAudio();
	}, []);

	return (
			<TitleSection title='Listening Section Directions'>
				<Box>
					<p>
						This section measures your ability to understand conversations and lectures in English. It
						is divided into 2 separately timed parts. In each part you will listen to 1 conversation
						and 1 or 2 lectures. You will hear each conversation or lecture only one time.
					</p>
					<br />
					<p>
						After each conversation or lecture, you will answer some questions about it. Answer the
						questions based on what is stated or implied by the speakers.
					</p>
					<br />
					<p>
						A clock that is provided will show you how much time is remaining. The clock will not
						count down while you are listening. The clock will count down only while you are answering
						questions.
					</p>
					<br />
					<p>
						You may take notes while you listen. You may use your notes to help you answer the
						questions. Your notes will not be scored.
					</p>
					<br />
					<p>
						If you need to change the volume while you listen, select the <strong>Volume</strong> icon
						at the top of the screen.
					</p>
					<br />
					<p className='flex align-bottom'>
						In some questions, you will see this icon:
						<img src={headphone_icon} alt={'headphone'} className='w-[35px] mx-2' />. This means that
						you will hear, but not see, part of the question.
					</p>
					<br />
					<p>
						Most questions are worth 1 point. If a question is worth more than 1 point, it will have
						special directions that indicate how many points you can receive.
					</p>
					<br />
					<p>
						You must answer each question. After you answer, select <strong>Next</strong>. Then select{' '}
						<strong>OK</strong> to confirm your answer and go on to the next question. After you
						select <strong>OK</strong>, you cannot return to previous questions.
					</p>
					<br />
					<p>You will now begin the first part of the Listening section.</p>
					<br />
					<p className='font-bold'>(Select continue at any time to dismiss these directions.)</p>
				</Box>
				<AudioPlayer visible={false} />
			</TitleSection>
	);
};

export default ListenSectionDirection;
