import logo from '../../../../assets/copyright-logo.svg';
import TitleSection from '../../../core/components/section/TitleSection';

const CopyrightInformation = () => {
	return (
		<TitleSection title='Copyright Information'>
			<div>
				<img src={logo} className='mb-10' alt='asdasd' />
				<div className='mb-5 text-[#626576]'>
					Copyright 2020 by Educational Testing Service. All rights reserved. ETS, the ETS logo,
					TOEFL and TOEFL IBT are registered trademarks of Educational Testing Service (ETS). This
					TOEFL iBT© test, its test delivery system, and all questions contained in the following
					program are the unpublished confidential and proprietary materials of Educational Testing
					Service. No reproduction or disclosure permitted. Unauthorized reproduction in part or in
					whole of this test is prohibited. Violators will be prosecuted to the full extent of
					applicable laws
				</div>
				<div className='text-[#626576]'>
					Select <span className='text-[#313131] font-bold'>Continue</span> to go on.
				</div>
			</div>
		</TitleSection>
	);
};

export default CopyrightInformation;
