import { Outlet } from 'react-router-dom';
import TopBar from '../../topbar/TopBar';

const MainLayout = () => {
	return (
			<>
				<TopBar />
				<div className='w-full max-w-[128rem] mx-auto'>
					<Outlet />
				</div>
			</>
	);
};

export default MainLayout;
