import TitleSection from '../../../../core/components/section/TitleSection';
const SectionDirections = () => {
	return (
		<TitleSection title='Reading Section Directions'>
			<p>
				This section measures your ability to understand academic passages in English. You will have
				<b>54 minutes</b> to read and answer questions about 3 <b>passages</b>. A clock that is
				provided during the test will show you how much time is remaining.
				<br />
				<br /> Most questions are worth 1 point. but the last question for each passage is worth
				more than 1 point. The directions for the last question indicate how many points you may
				receive.
				<br />
				<br />
				<span className='text-gray-400'>
					Some passages include a word or phrase that is{' '}
					<u className='text-[#0014C3] cursor-pointer'>underlined in blue.</u> Select the word or
					phrase to see a definition, explanation, or illustration.
				</span>
				<br />
				<br /> Within this section, you can move to the next question by selecting <b>Next</b>. You
				can skip questions and go back to them later as long as there is time remaining. If you want
				to return to previous questions. select <b>Back</b>.
				<br />
				<br /> You can select <b>Review</b> at any time and the review screen will show you which
				questions you have answered and which you have not answered. From the review screen, you may
				go directly to any question you have already encounted.
				<br />
				<br /> You will now begin the Reading section.
				<br />
				<br /> Select <b>Continue</b> to go on.
			</p>
		</TitleSection>
	);
};

export default SectionDirections;
