import axios from 'axios';
import TestRepository from '../repositories/TestRepository';
import { useDialog } from '../../utils/context/DialogContext';

const API_URL = process.env.REACT_APP_BASE_URL;
const testController = new TestRepository();

const API = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

const resolveFulfilled = (response: any) => {
	//   if (response?.data?.message) {
	//     response.data.messages = response.data.message;
	//   }

	//   if (isArray(response.data.messages)) {
	//     response.data.message = response.data.messages.reduce(
	//       (previous, current) => `${previous + current}\n`,
	//       ''
	//     );
	//   }

	const data = response.RESPONSE;

	if (data.CODE === '-10010') {
		testController.onSessionEnded();
	}

	return response;
};

// const saveUrlAndNavigateLogin = () => {
//   const currentUrl = window.location.pathname;
//   localStorage.setItem('redirectUrl', currentUrl);
//   setTimeout(() => {
//     history.push({
//       pathname: '/login',
//     });
//   }, 500);
// };

// const getAccessTokenByRefreshToken = () => {
//   const { refreshToken } = globalStore.getState().auth.login;
//   globalStore.dispatch(setLoading(true));
//   axios
//     .get(`${API_URL}/auth/accessToken`, {
//       headers: {
//         Authorization: `Bearer ${refreshToken}`,
//       },
//     })
//     .then((result) => {
//       const { accessToken } = result?.data?.data;
//       globalStore.dispatch(updateAccessToken(accessToken));
//       window.location.reload();
//     })
//     .catch((e) => {
//       globalStore.dispatch(resetLogin());
//       globalStore.dispatch(setLoading(false));
//       saveUrlAndNavigateLogin();
//     });
// };

const resolveRejected = (err: string) => {
	//   let errorResponse = {
	//     errorCode: 'Bad Request',
	//     data: {},
	//   };
	//   const errText = err?.response?.data?.statusName;
	//   if (isString(errText) && errText.toUpperCase() === 'UNAUTHORIZED') {
	//     // try to login again using accessToken
	//     getAccessTokenByRefreshToken();
	//   }
	//   if (err.message === 'Network Error') {
	//     errorResponse.message = 'There was a network error.';
	//     console.log(errorResponse);
	//   } else if (err?.response?.data) {
	//     errorResponse = {
	//       ...errorResponse,
	//       messages: err.response.data.message,
	//       ...err.response.data,
	//     };

	//     if (isArray(errorResponse.messages)) {
	//       errorResponse.message = errorResponse.messages.reduce(
	//         (previous, current) => `${previous + current}\n`,
	//         ''
	//       );
	//     }
	//   }

	return err;
};

const GET = async (url: string, params = {}) => {
	/* return new Promise<AxiosResponse>((resolve, reject) => {
		API.get(url, { params })
			.then((response) => {
				resolve(resolveFulfilled(response));
			})
			.catch((err) => {
				reject(resolveRejected(err));
			});
	}); */
	try {
		const res = await fetch(`${API_URL + url}`, {
			method: 'GET',
			// headers: headers,
		});
		const data = await res.json();
		return resolveFulfilled(data);
	} catch (err: any) {
		throw resolveRejected(err.toString());
	}
};

const POST = async (url: string, body = {}) => {
	// return new Promise<AxiosResponse>((resolve, reject) => {
	// 	API.post(url, data)
	// 		.then((response) => {
	// 			resolve(resolveFulfilled(response));
	// 		})
	// 		.catch((err) => {
	// 			reject(resolveRejected(err));
	// 		});
	// });

	try {
		const res = await fetch(`${API_URL + url}`, {
			method: 'POST',
			body: JSON.stringify(body),
			// headers: headers,
		});

		const escape = function(str: string) {
			return str.replace(/(\r\n|\n|\r)/gm, '');
		};

		const raw = await res.text();
		const data = JSON.parse(escape(raw));

		return resolveFulfilled(data);
	} catch (error: any) {
		throw resolveRejected(error.toString());
	}
};

/* const PUT = (url: string, data = {}) => {
	return new Promise<AxiosResponse>((resolve, reject) => {
		API.put(url, data)
			.then((response) => {
				resolve(resolveFulfilled(response));
			})
			.catch((err) => {
				reject(resolveRejected(err));
			});
	});
};

const DELETE = (url: string, params = {}) => {
	return new Promise<AxiosResponse>((resolve, reject) => {
		API.delete(url, { params })
			.then((response) => {
				resolve(resolveFulfilled(response));
			})
			.catch((err) => {
				reject(resolveRejected(err));
			});
	});
}; */

const ApiService = { GET, POST, API_URL, API };

export default ApiService;
