import React, { MouseEventHandler } from 'react';
type HelpTabProps = {
	isActive: boolean;
	handleClick: MouseEventHandler;
	title: string;
};
const HelpTab = ({ isActive, handleClick, title }: HelpTabProps) => {
	return (
		<li
			className={
				'rounded-t-md mr-4 px-3 py-1 text-xs cursor-pointer ' +
				(isActive ? 'bg-gray-100 text-black' : 'bg-black text-white hover:bg-gray-800 ')
			}
			onClick={handleClick}
		>
			{title}
		</li>
	);
};

export default HelpTab;
