import { Skeleton, styled, TableCell, TableRow } from '@mui/material';

interface SkeletonTableProps {
	col: number;
	row: number;
}

const SkeletonTable = ({ col, row }: SkeletonTableProps) => {
	const StyledTableRow = styled(TableRow)((_) => ({
		'&:last-child td, &:last-child th': { border: 0 },
		'&:nth-of-type(odd)': { backgroundColor: '#F1F2F3' },
	}));

	const loopCol = (col: number) => {
		return Array.from({ length: col }, (_, index: number) => (
			<TableCell key={index}>
				<Skeleton variant='rounded' width={'100%'} height={50} />
			</TableCell>
		));
	};

	const loopRow = (row: number, col: number) => {
		return Array.from({ length: row }, (_, index: number) => (
			<StyledTableRow key={index}>{loopCol(col)}</StyledTableRow>
		));
	};

	return <>{loopRow(row, col)}</>;
};

export default SkeletonTable;
