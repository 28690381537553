import { useRef, useState, useCallback, useEffect } from 'react';
import useInterval from './useInterval';
function useMicVolumeCheck(recordState: 'NONE' | 'READY' | 'RECORDING') {
	const analyser = useRef<AnalyserNode | null>(null);
	const volumes = useRef<Uint8Array>(new Uint8Array());

	const [volume, setVolume] = useState<number>(0);

	useEffect(() => {
		if (recordState === 'RECORDING') {
			init();
		}
	}, [recordState]);

	const init = async () => {
		try {
			const audioStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					echoCancellation: true,
				},
			});
			const audioContext = new AudioContext();
			const audioSource = audioContext.createMediaStreamSource(audioStream);
			analyser.current = audioContext.createAnalyser();
			analyser.current.fftSize = 512;
			analyser.current.minDecibels = -127;
			analyser.current.maxDecibels = 0;
			analyser.current.smoothingTimeConstant = 0.4;
			audioSource.connect(analyser.current);
			volumes.current = new Uint8Array(analyser.current.frequencyBinCount);
			volumeCallback();
		} catch (e) {
			console.error('Failed to initialize volume visualizer, simulating instead...', e);
			// Simulation
			//TODO remove in production!
			//   let lastVolume = 50;
			//   volumeCallback = () => {
			// 	const volume = Math.min(
			// 	  Math.max(Math.random() * 100, 0.8 * lastVolume),
			// 	  1.2 * lastVolume
			// 	);
			// 	lastVolume = volume;
			// 	console.log(lastVolume);
			// 	volumeVisualizer.style.setProperty("--volume", volume + "%");
			//   };
		}
	};

	const volumeCallback = useCallback(() => {
		analyser.current?.getByteFrequencyData(volumes.current);
		let volumeSum = 0;

		volumes.current.forEach((volume) => (volumeSum += volume));

		const averageVolume = volumeSum / volumes.current.length;

		const calVolume = (averageVolume * 100) / 127;

		setVolume(calVolume);
	}, []);

	useInterval(
		() => {
			volumeCallback();
		},
		recordState === 'RECORDING' ? 100 : null
	);
	return volume;
}

export default useMicVolumeCheck;
