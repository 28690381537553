import { Box, Paper, Stack } from '@mui/material';
import continue_icon from '../../../../../assets/icons/Arrow-transparent-right.svg';
import help_icon from '../../../../../assets/icons/help-circle.svg';
import volume_icon from '../../../../../assets/icons/volume-max.svg';
import CButton from '../../../../core/components/Button/CButton';
import TitleSection from '../../../../core/components/section/TitleSection';

const TestingTools = () => {
	return (
		<TitleSection title='Testing Tools'>
			<div>
				<Box>
					<Paper elevation={3}>
						<Stack className='flex flex-col gap-5 justify-center divide-solid divide-y px-10'>
							<Box className='flex items-center pt-5'>
								<CButton
									text='BTN_PROG_NEXT'
									icon={continue_icon}
									variant='text'
									color='success'
								></CButton>
								<p className='ml-3 flex-[4_4_0%]'>
									After you answer a question, select <strong>Next</strong>. You may still change
									the answer after selecting <strong>Next</strong>.
								</p>
							</Box>
							<Box className='flex items-center pt-5'>
								<CButton text='BTN_PROG_HELP_DISABLED' icon={help_icon} isBlack={true}></CButton>
								<p className='ml-3 flex-[4_4_0%]'>
									Selecting <strong>Help</strong> will bring you to Help. From Help you can get
									information on different topics by selecting one of the tabs on the screen.
								</p>
							</Box>
							<Box className='flex items-center pt-5 pb-10'>
								<CButton
									text='BTN_PROG_VOLUME'
									icon={volume_icon}
									variant='text'
									color='success'
								></CButton>
								<p className='ml-3 flex-[4_4_0%]'>
									Selecting <strong>Volume</strong> will open the volume control. To change the
									volume. move the volume indicator to the left or to the right. To close the volume
									control. select Volume again.
								</p>
							</Box>
						</Stack>
					</Paper>
				</Box>
				<p className='mt-5'>
					Select <strong>Return</strong> to go on
				</p>
			</div>
		</TitleSection>
	);
};

export default TestingTools;
