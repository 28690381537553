import { Pagination, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { motion } from 'framer-motion';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrowRightSky from '../../../../assets/icons/arrow-right-sky.svg';
import SkeletonTable from '../../../core/components/SkeletonTable/SkeletonTable';
import { MY_PAGE_PAGE_SIZE } from '../../../core/constants';
import { useAppDispatch } from '../../../core/hooks';
import { onTestStart, updateTestName } from '../../../core/redux/ToeflSlice';
import MyPageRepository from '../../../core/repositories/MyPageRepository';
import { User } from '../../../core/models/userResponse.model';

interface MyPageProps {
	user: User;
}

const FinishedList = ({ user }: MyPageProps) => {
	const myPageRepository = new MyPageRepository();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [list, setList] = useState<any[]>([]);
	const [totalPage, setTotalPage] = useState(0);
	const [page, setPage] = useState(1);
	const [pageItem, setPageItem] = useState<any[]>([]);

	const StyledTableRow = styled(TableRow)((_) => ({
		'&:last-child td, &:last-child th': { border: 0 },
		'&:nth-of-type(odd)': { backgroundColor: '#F1F2F3' },
	}));

	const getList = useCallback(async () => {
		try {
			const res = await myPageRepository.getFinishedList(user.SESSION);
			if (res) {
				setTotalPage(Math.ceil(res.BODY.END_TEST_LIST.length / MY_PAGE_PAGE_SIZE));
				setList(res.BODY.END_TEST_LIST);
			}
		} catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		getList();
	}, [getList]);

	useEffect(() => {
		setPageItem(list.slice(0, MY_PAGE_PAGE_SIZE));
	}, [list]);

	const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
		setPage(value);
		setPageItem(list.slice((page - 1) * MY_PAGE_PAGE_SIZE, page * MY_PAGE_PAGE_SIZE));
	};

	const onFinishTest = async (item: any) => {
		const body = {
			HEADER: {
				SESSION: user.SESSION,
				USER_ID: user.USER_ID,
			},
			BODY: {
				TEST_CODE: item.TEST_CODE,
				SETTLE_CODE: item.SETLCD,
			},
		};

		dispatch(updateTestName(item.ITEM_NAME));

		const onSuccess = (data: any) => {
			navigate(`/testing`, { replace: true });
		};

		dispatch(onTestStart({ body, onSuccess }));
	};

	return (
			<div className='p-5 w-full mb-3'>
				<div className='flex items-center gap-2 mb-4'>
					<img src={arrowRightSky} alt='right arrow' />
					<div className='text-base font-bold'>완료한 시험</div>
				</div>

				<TableContainer component={Paper} className='w-full'>
					<Table size='small' sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									TEST
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									구매일
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold' align='center'>
									시험완료일
								</TableCell>
								<TableCell style={{ color: '#225CA1' }} className='font-bold w-36' align='center'>
									응시
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list.length >= 1 ? (
									pageItem.map((item, index) => (
											<StyledTableRow key={index}>
												<TableCell align='left' width='600'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.ITEM_NAME}
													</motion.p>
												</TableCell>
												<TableCell align='center' width='200'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.PURCHASE_DATE}
													</motion.p>
												</TableCell>
												<TableCell align='center' width='200'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														{item.TESTENDTM}
													</motion.p>
												</TableCell>
												<TableCell align='center' className='w-36'>
													<motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
													          transition={{
														          duration: 0.5,
														          ease: 'linear',
													          }}>
														<Button
																size='small'
																onClick={() => onFinishTest(item)}
																variant='contained'
																className='bg-gradient-to-b from-[#70B1FF] to-[#225CA1] border border-black border-solid w-20 h-9'
														>
															성적보기
														</Button>
													</motion.p>
												</TableCell>
											</StyledTableRow>
									))
							) : (
									list.length === 0 ? <StyledTableRow>
												<TableCell colSpan={4} align='center' width='550' height='50'>
													No score report found
												</TableCell>
											</StyledTableRow> :
											<SkeletonTable col={4} row={1} />
							)}
						</TableBody>
					</Table>
					{totalPage > 1 ? (
							<div className='w-full flex justify-center py-5'>
								<Pagination page={page} onChange={handleChangePage} count={totalPage} color='primary' />
							</div>
					) : null}
				</TableContainer>
			</div>
	);
};

export default FinishedList;
