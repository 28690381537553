import React from 'react';

type ArrowTitleProps = {
	title: string;
};

const ArrowTitle = ({ title }: ArrowTitleProps) => {
	return (
		<div className='flex items-center gap-1 pb-5'>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fill-rule='evenodd'
					clip-rule='evenodd'
					d='M10.0002 19.1663C15.0628 19.1663 19.1668 15.0623 19.1668 9.99967C19.1668 4.93706 15.0628 0.833008 10.0002 0.833008C4.93755 0.833008 0.833496 4.93706 0.833496 9.99967C0.833496 15.0623 4.93755 19.1663 10.0002 19.1663ZM8.08942 6.42226C7.76398 6.09683 7.76398 5.56919 8.08942 5.24375C8.41485 4.91831 8.94249 4.91831 9.26793 5.24375L13.4346 9.41042C13.76 9.73585 13.76 10.2635 13.4346 10.5889L9.26793 14.7556C8.94249 15.081 8.41485 15.081 8.08942 14.7556C7.76398 14.4302 7.76398 13.9025 8.08942 13.5771L11.6668 9.99967L8.08942 6.42226Z'
					fill='#70B1FF'
				/>
			</svg>
			<span className='font-bold'>{title}</span>
		</div>
	);
};

export default ArrowTitle;
