import { Box } from '@mui/material';
import TitleSection from '../../../core/components/section/TitleSection';

const RemainTimeAdvise = () => {
	return (
		<TitleSection title='Time Remaining on Essay Item'>
			<Box>
				<p>
					You still have time to write your response. As long as there is time remaining. You can
					keep writing or revising your response.
				</p>
				<p>
					Select <strong>Return</strong> to keep writing or revising. Select{' '}
					<strong>Continue</strong> to leave this question.
				</p>
				<p>Once you leave this question you WILL NOT be able to return to it.</p>
			</Box>
		</TitleSection>
	);
};

export default RemainTimeAdvise;
