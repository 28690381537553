import TitleSection from '../../../../core/components/section/TitleSection';

const EndL2 = () => {
	return (
		<TitleSection title='End of Section'>
			<div style={{ marginTop: '100px' }}>
				You have finished this section and will now begin the next one. Select continue to go on.
			</div>
		</TitleSection>
	);
};

export default EndL2;
