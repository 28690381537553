import { Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import TitleSection from '../../../../core/components/section/TitleSection';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { userSelector } from '../../../../core/redux/AuthSlice';
import { onTestController, toeflSelector } from '../../../../core/redux/ToeflSlice';

const SpeakingEndSection = () => {
	const dispatch = useAppDispatch();
	const { data } = useAppSelector(toeflSelector);
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);

	const nextSection = useCallback(() => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};

		dispatch(onTestController({ body }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTimeout(() => {
			nextSection();
		}, 60 * 1000);
	}, [nextSection]);

	return (
		<TitleSection title='End of Section'>
			<Box>
				<Typography>
					This is the end of the speaking section. The writing section will begin automatically in
					60 seconds.
				</Typography>
				<Typography>If you do not wish to wait, click Continue Button.</Typography>
			</Box>
		</TitleSection>
	);
};

export default SpeakingEndSection;
