export enum ControlType {
	A = 'A',
	B = 'B',
	BR = 'BR',
	C1 = 'C1',
	C2 = 'C2',
	C3 = 'C3',
	BL = 'BL',
	D1 = 'D1',
	D2 = 'D2',
	D3 = 'D3',
	BS = 'BS',
	E1 = 'E1',
	E2 = 'E2',
	E3 = 'E3',
	E4 = 'E4',
	E5 = 'E5',
	BW = 'BW',
	F1 = 'F1',
	F2 = 'F2',
	F3 = 'F3',
	F4 = 'F4',
	F5 = 'F5',
	R = 'R',
	G = 'G',
}

export enum FlowControlName {
	STM = 'STM',
	GCI = 'GCI',
	GTI = 'GTI',
	HC1 = 'HC1',
	HC2 = 'HC2',
	HCCV = 'HCCV',
	HCAM = 'HCAM',
	DIR_R = 'DIR_R',
	END_R = 'END_R',
	Reading = 'Reading',
	HEADSET_L = 'HEADSET_L',
	CHG_VOL = 'CHG_VOL',
	DIR_L1 = 'DIR_L1',
	Listening1_setLeader = 'Listening 1 - setLeader',
	Listening1_question = 'Listening 1 - question',
	Listening1_stimulus = 'Listening 1 - stimulus',
	Listening2_setLeader = 'Listening 2 - setLeader',
	Listening2_question = 'Listening 2 - question',
	Listening2_stimulus = 'Listening 2 - stimulus',
	END_L1 = 'END_L1',
	DIR_L2 = 'DIR_L2',
	END_L2 = 'END_L2',
	DIR_BREAK = 'DIR_BREAK',
	HEADSET_S = 'HEADSET_S',
	ADJ_VOL = 'ADJ_VOL',
	ADJ_VOL_CONFIRM = 'ADJ_VOL_CONFIRM',
	DIR_S1 = 'DIR_S1',
	Speaking_listen_carefully = 'Speaking - listen carefully',
	Speaking_question = 'Speaking - question',
	Speaking_confirm_record = 'Speaking - confirm record',
	Speaking_stimulus_listening = 'Speaking - stimulus listening',
	Speaking_stimulus_reading = 'Speaking - stimulus reading',
	END_S1 = 'END_S1',
	DIR_W1 = 'DIR_W1',
	DIR_WRL = 'DIR_WRL',
	Writing_WRL = 'Writing WRL',
	Writing_stimulus = 'Writing - stimulus',
	Writing_notice_the_remain_time = 'Writing - notice the remain time',
	DIR_WKE = 'DIR_WKE',
	Writing_WKE = 'Writing WKE',
	END_W1 = 'END_W1',
	설문조사 = '설문조사',
	EOT = 'EOT',
	Score_Report = 'Score Report',
}
