import { Box, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import Microphone from '../../../../../assets/icons/Microphone.svg';
import Record from '../../../../../assets/icons/record.svg';
import RecordWhite from '../../../../../assets/icons/record-white.svg';

import TitleSection from '../../../../core/components/section/TitleSection';
import MicProgressBar from '../../../../core/components/ProgressBar/MicProgressBar';
import useInterval from '../../../../core/customhooks/useInterval';
import { AudioProps } from '../../listening/image-and-listening-audio/image-and-listening-audio';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { onTestController, toeflSelector } from '../../../../core/redux/ToeflSlice';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import { audioSelector, setAudioFile, setTracks } from '../../../../core/redux/AudioSlice';
import useMicVolumeCheck from '../../../../core/customhooks/useMicVolumeCheck';
import useHardwareConnect from '../../../../core/customhooks/useHardwareConnect';
import Button from '@mui/material/Button';
import { useDialog } from '../../../../utils/context/DialogContext';
import { userSelector } from '../../../../core/redux/AuthSlice';

import { useReactMediaRecorder } from 'react-media-recorder';

const HardwareCheckMicrophone = () => {
	let { isAudioEnded } = useAppSelector(audioSelector);
	const [timer, setTimer] = useState(0);
	let { startRecording, stopRecording, mediaBlobUrl } =
		useReactMediaRecorder({
			video: false,
			blobPropertyBag: {
				type: 'audio/mp3',
			},
		});

	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);
	const [openDialog, closeDialog] = useDialog();
	const RECORDING_READY_TIME = 3;
	const MIC_MIN_VOLUME = 30;
	const MIC_MAX_VOLUME = 80;

	const dispatch = useAppDispatch();
	const { content, data } = useAppSelector(toeflSelector);
	const [progress, setProgress] = useState<number>(7);
	const [pageType, setPageType] = useState<'DIRECTION' | 'RECORDING'>('DIRECTION');
	const [recordState, setRecordState] = useState<'NONE' | 'READY' | 'RECORDING'>('NONE');
	const [readyTime, setReadyTime] = useState<number>(RECORDING_READY_TIME);
	const [isMicConnected, isSpeakerConnected] = useHardwareConnect();
	const [start, setStart] = useState(false);
	const firstStart: any = useRef(true);
	const tick: any = useRef();
	const volume: number = useMicVolumeCheck(recordState);
	const [checkSuccess, setChecksuccess] = useState(0);

	useEffect(() => {
		if (isAudioEnded) {
			setPageType('RECORDING');
			initState();
		}
	}, [isAudioEnded]);

	useEffect(() => {
		if (mediaBlobUrl) {
			dispatch(setAudioFile(mediaBlobUrl));
		}
	}, [dispatch, mediaBlobUrl]);

	useEffect(() => {
		if (volume === 0) {
			setProgress(-1);
		} else if (volume < MIC_MIN_VOLUME) {
			const divider = MIC_MIN_VOLUME / 5;
			setProgress(Math.round(volume / divider));
		} else if (volume < MIC_MAX_VOLUME) {
			const divider = (MIC_MAX_VOLUME - MIC_MIN_VOLUME) / 5;
			setProgress(Math.round((volume - MIC_MIN_VOLUME) / divider) + 5);
		} else {
			if (volume > MIC_MAX_VOLUME + 10) {
				setProgress(11);
			} else if (volume > MIC_MAX_VOLUME + 20) {
				setProgress(12);
			} else if (volume > MIC_MAX_VOLUME + 30) {
				setProgress(13);
			} else if (volume > MIC_MAX_VOLUME + 40) {
				setProgress(14);
			} else {
				setProgress(15);
			}
		}
	}, [volume]);

	const initState = () => {
		setRecordState('NONE');
		setReadyTime(RECORDING_READY_TIME);
	};

	const handleRecording = async () => {
		if (recordState === 'NONE') {
			setRecordState('READY');
			startRecording();
		} else if (recordState === 'RECORDING') {
			stopRecording();
			initState();

		}
	};

	const successDialog = () => {
		openDialog({
			children: (
				<>
					<DialogTitle
						className='py-3 font-bold'
						sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
						id='alert-dialog-title'
					>
						Success
					</DialogTitle>
					<DialogContent className='px-4 pt-4'>
						<Box className='mb-5'>Your microphone volume has been successfully adjusted.</Box>

						<Box>Select <span className='font-bold'>Continue</span> to go on.</Box>
					</DialogContent>
					<DialogActions className='pb-6 px-4'>
						<Button
							variant='contained'
							sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
							color='primary'
							onClick={() => {
								closeDialog();
								onNext();
							}}
						>
							<p className='text-white'>Continue</p>
						</Button>
					</DialogActions>
				</>
			),
		});
	};

	//Todo Render Audio
	function renderAudio() {
		const fileList: any[] = content.ATTACH_FILE_LIST;
		const tempTracks: AudioProps[] = [];
		fileList.forEach((file) => {
			tempTracks.push({
				AUDIO_FILE: file.FILE_DATA,
				IMG_FILE: '',
				WAIT_TIME: 99999999,
			});
		});
		dispatch(setTracks(tempTracks));
	}

	useInterval(
		() => {
			if (readyTime >= 1) {
				setReadyTime(readyTime - 1);
			} else {
				setRecordState('RECORDING');
				setStart(true);
			}
		},
		recordState === 'READY' ? 1000 : null,
	);

	useEffect(() => {
		renderAudio();
	}, []);

	useEffect(() => {
		if (recordState === 'RECORDING') {
			if (progress == 6 || progress == 7 || progress == 8 || progress == 9 || progress == 10) {
				setChecksuccess(checkSuccess + 1)
			} else {
				setChecksuccess(0)
			}
		}
	}, [progress]);

	useEffect(() => {
		if (checkSuccess === 25) {
			stopRecording();
			successDialog();
		}
	}, [checkSuccess])

	function onNext() {
		setRecordState('NONE');
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};
		dispatch(onTestController({ body }));
	}

	const dispSecondsAsMins = (seconds: any) => {
		if (seconds <= 0) {
			stopRecording();
		}
		if (seconds > 0) {
			return secondsToTime(seconds);
		} else {
			return '00:00:00';
		}
	};

	useEffect(() => {
		if (firstStart.current) {
			firstStart.current = !firstStart.current;
			return;
		}

		if (start) {
			tick.current = setInterval(() => {
				setTimer((timer) => timer + 1);
			}, 1000);
		} else {
			clearInterval(tick.current);
		}

		return () => clearInterval(tick.current);
	}, [start]);

	const secondsToTime = (secs: number) => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		return <>
			{hours !== 0 ? (hours < 10 ? `0${hours}` : hours) : `00`}:
			{minutes !== 0 ? (minutes < 10 ? `0${minutes}` : minutes) : `00`}:
			{seconds !== 0 ? (seconds < 10 ? `0${seconds}` : seconds) : `00`}
		</>;
	};

	return (
		<Box className='flex max-w-[1080px] mx-auto items-center gap-3'>
			{/* <button
				className='border-2 text-slate-500 border-slate-200 px-4 py-3 rounded-md'
				onClick={() => {
					if (pageType === 'DIRECTION') {
						setPageType('RECORDING');
					} else {
						setPageType('DIRECTION');
					}
					initState();
				}}
			>
				<img
					alt='Left Arrow'
					src={LeftArrow}
					className={
						'transition-transform duration-300 ' +
						(pageType === 'DIRECTION' ? 'origin-center rotate-0' : 'origin-center rotate-180')
					}
				/>
			</button> */}

			<Paper className='w-full h-[560px] mt-10 rounded-xl px-10 py-2' elevation={4}>
				{pageType === 'DIRECTION' && (
					<TitleSection title='Adjusting the Microphone'>
						<>
							<Box className='mb-5'>
								In order to check your microphone volume, you will speak into the microphone using
								your normal tone and volume. While you speak, the microphone will adjust
								automatically. You will speak about the city you live in.
							</Box>
							<Box className='mb-5'>Example:</Box>
							<Box className={'flex justify-center w-full'}>
								<img className='mr-4 mb-5' src={Microphone} alt={'microphone'} />
							</Box>
							<Box>
								For best recording results, your choice level should remain generally within the{' '}
								<span className='font-bold'>Good range.</span>
								<span className='font-bold'>Continue.</span>
							</Box>
							{/* <div>Select <span className='font-bold' onClick={() => navigate('/hardware-check-microphone', { state: { TEST_CODE, mode } })}>Continue</span> when you are ready to go on.</div> */}
						</>
					</TitleSection>
				)}
				{pageType === 'RECORDING' && (
					<Box className='my-5'>
						<p className='mb-5 border-b border-b-slate-300 pb-5 text-[#626576]'>
							Select the Start Recording button. A timer will countdown until the system is ready to
							record. When the recording begins, speak using your normal tone and volume.
						</p>
						<p className='text-[#626576]'>
							Practice speaking question:
							<b className='text-gray-800'>“Describe the city you live in”</b>
						</p>

						<Box className='flex justify-center mt-10 '>
							<Box
								onClick={() => handleRecording()}
								className={
									'relative w-24 h-24 flex justify-center items-center rounded-full ring ring-gray-400 ring-offset-4 cursor-pointer shadow-xl ' +
									(recordState === 'RECORDING'
										? 'bg-red-500 ring-red-500 ring-opacity-50 ring-offset-0 ring-[10px] animate-pulse'
										: 'bg-[#E1E2E5]')
								}
							>
								<img
									className={recordState === 'RECORDING' ? '' : 'opacity-20'}
									src={recordState === 'RECORDING' ? RecordWhite : Record}
									alt={'record'}
								/>
								<p className={
									'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-xs font-extrabold ' +
									(recordState === 'READY' ? 'text-5xl' : '')
								}>
									{recordState === 'NONE' ? (
										<span>
											START
											<br />
											RECORDING
										</span>
									) : recordState === 'READY' ? (
										<span>{readyTime}</span>
									) : (
										<></>
									)}
								</p>
							</Box>
						</Box>
						<Box className='my-10 flex justify-center'>
							<span>{dispSecondsAsMins(timer)}</span>
						</Box>
						<Box className='flex justify-center'>
							{/* Todo: Control Progress */}
							<MicProgressBar progress={progress} />
						</Box>
					</Box>
				)}
			</Paper>
			<AudioPlayer visible={false} />
		</Box>
	);
};

export default HardwareCheckMicrophone;
