import { Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { userSelector } from '../../../../core/redux/AuthSlice';
import { onTestController, toeflSelector } from '../../../../core/redux/ToeflSlice';

const ShortReading = () => {
	const dispatch = useAppDispatch();
	const { content, data } = useAppSelector(toeflSelector);
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);

	const nextStepAfterReadingTime = useCallback(() => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};

		dispatch(onTestController({ body }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTimeout(() => {
			nextStepAfterReadingTime();
		}, parseInt(content.READ_TIME) * 1000);
	}, [content.READ_TIME, nextStepAfterReadingTime]);

	return (
		<Box className='w-full max-w-[1024px] mx-auto mt-10 mb-5'>
			<Typography>{content.READ_INTRODUCTION}</Typography>
			<Box className='flex flex-col items-center mt-40 pb-6 border-b-slate-900 border-b'>
				<Typography
					className='font-bold text-2xl mb-10'
					dangerouslySetInnerHTML={{ __html: content.READ_TITLE }}
				></Typography>
				<Typography
					className='text-sm text-justify'
					dangerouslySetInnerHTML={{ __html: content.READ_CONTENT }}
				></Typography>
			</Box>
		</Box>
	);
};

export default ShortReading;
