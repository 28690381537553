import React from 'react';

type TitleSectionProps = {
	children: JSX.Element | JSX.Element[];
	title: string;
};

const TitleSection = ({ children, title }: TitleSectionProps) => {
	return (
			<div className='w-full max-w-[1024px] mx-auto mt-10 mb-5'>
				<h3 className='text-2xl font-bold border-b border-b-gray-300 pb-2 mb-10'>{title}</h3>
				{children}
			</div>
	);
};

export default TitleSection;
