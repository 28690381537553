import { CircularProgress } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { store } from './app/core/store';
import router from './app/routes/router';
import { DialogProvider } from './app/utils/context/DialogContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<DialogProvider>
		<Provider store={store}>
			<RouterProvider router={router} fallbackElement={<CircularProgress />} />
		</Provider>
	</DialogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
