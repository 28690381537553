import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from '../../core/hooks';
import { login } from '../../core/redux/AuthSlice';

interface LoginB2C {
	LOGIN_ID: string;
	LOGIN_PASSWD: string;
}

const B2CYup = yup.object().shape({
	LOGIN_ID: yup
		.string()
		.required('아이디를 입력해주세요.')
		.max(30, '비밀번호를 입력해주세요')
		.matches(/^[a-zA-Z0-9]+$/, '유저 이름을 다시 입력해주세요.'),
	LOGIN_PASSWD: yup.string().required('비밀번호를 입력해주세요'),
	// .matches(/^[a-zA-Z0-9]+$/, '유저 이름을 다시 입력해주세요.'),
});

const B2CForm = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [logged, setLogged] = useState<string>('');

	let initialValues = {
		LOGIN_ID: '',
		LOGIN_PASSWD: '',
	};

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = useForm<LoginB2C>({
		mode: 'onSubmit',
		defaultValues: initialValues,
		resolver: yupResolver(B2CYup),
	});

	const onRejected = (res: any) => {
		setLogged(res.ERROR_MESSAGE);
		reset();
	};

	const onSubmit: SubmitHandler<LoginB2C> = async (data) => {
		if (isValid) {
			const logged = await dispatch(login({ loginBody: data, onRejected }));
			if (logged.meta.requestStatus === 'fulfilled') {
				navigate('/my-page');
			}
		}
	};

	const fetchDefaultValue = useCallback(() => {
		reset({
			LOGIN_ID: 'syskwon',
			LOGIN_PASSWD: '#com513007',
		});
	}, [reset]);

	useEffect(() => {
		fetchDefaultValue();
	}, [fetchDefaultValue]);

	return (
		<>
			<Box className='flex flex-col items-center mb-8 px-20'>
				{logged !== '' ? (
					<Typography className='text-sm italic text-[#FA0000]'>*{logged}</Typography>
				) : null}
				<form className='w-full'>
					<Typography variant='body1' className='mb-2'>
						아이디
					</Typography>
					<Controller
						name='LOGIN_ID'
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								className='w-full bg-white mb-5'
								variant='outlined'
								type={'text'}
								required
								value={value || ''}
								onChange={(e) => onChange(e.target.value)}
								error={error !== undefined}
								helperText={error?.message}
								inputProps={{ maxLength: 30 }}
								placeholder='아이디를 입력해 주세요.'
							/>
						)}
					/>

					<Typography variant='body1' className='mb-2'>
						비밀번호
					</Typography>
					<Controller
						name='LOGIN_PASSWD'
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								className='w-full bg-white'
								variant='outlined'
								required
								value={value || ''}
								type='password'
								onChange={(e) => onChange(e.target.value)}
								error={error !== undefined ? true : false}
								helperText={error?.message}
								placeholder='비밀번호를 입력해 주세요.'
							/>
						)}
					/>
				</form>
			</Box>
			<Box className='flex justify-center gap-5'>
				<Button
					className='bg-gradient-to-b from-[#5495E2] to-[#0F4C95] rounded-lg'
					sx={{ width: '200px', height: '45px' }}
					color='primary'
					variant='contained'
					onClick={handleSubmit(onSubmit)}
				>
					Continue
				</Button>
				<Button
					className=' bg-gradient-to-b from-[#5B5A5A] to-[#272727] rounded-lg'
					sx={{ width: '200px' }}
					color='secondary'
					variant='contained'
					id='quit-app-exclusive'
				>
					Cancel
				</Button>
			</Box>
		</>
	);
};

export default B2CForm;
