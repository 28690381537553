import { Navigate, Outlet } from 'react-router-dom';
import { LocalStorageService } from '../core/services/storage.service';

const PrivateRoute = () => {
	const storage = new LocalStorageService();
	// const user = useContext(AuthContext);

	return storage.getItem('USER') ? <Outlet /> : <Navigate to={'/login'} replace />;
};

export default PrivateRoute;
