import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';

interface SelectModeModalProps {
	open: boolean;
	handleClose: () => void;
}

const SelectModeModal = ({ open, handleClose }: SelectModeModalProps) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			sx={{ borderRadius: '24px' }}
		>
			<DialogTitle
				className='py-3 border-b border-black'
				sx={{ backgroundColor: '#F6EAAA' }}
				id='alert-dialog-title'
			>
				<Typography variant='body1' className='font-bold text-lg' sx={{ color: '#313131' }}>
					Select Mode
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box id='alert-dialog-description' className='flex flex-col px-4 pt-10 text-[#626576]'>
					<Typography variant='body1'>Select a mode to start a test.</Typography>
					<br />
					<hr className='' />
				</Box>
			</DialogContent>
			<DialogActions className='pb-6 px-4'>
				<Button
					variant='contained'
					sx={{
						border: '1px solid #313131',
						backgroundColor: '#144279',
						paddingX: '18px',
					}}
					className='hover:bg-[#144279]'
					onClick={handleClose}
					autoFocus
				>
					<p className='text-white'>Return</p>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SelectModeModal;
