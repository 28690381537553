import { AppBar, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import return_icon from '../../../../assets/icons/Arrow-transparent-left.svg';
import logo from '../../../../assets/icons/G-LOGO.svg';
import Footer from '../../../core/common/footer/Footer';
import CButton from '../../../core/components/Button/CButton';
import { ButtonEnum } from '../../../core/constants/button.enum';
import { HelpPageType } from '../../../core/constants/help-page.enum';
import HelpTab from './components/help-tab';
import GeneralDescriptions from './pages/general-descriptions';
import HowToAnswer from './pages/how-to-answer';
import ReadingThePassage from './pages/reading-the-passage';
import SectionDirections from './pages/section-directions';
import TestingTools from './pages/testing-tools';

const ReadingHelp = () => {
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState<number>(HelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS);

	const returnTesting = () => {
		navigate('/testing', { replace: true });
	};

	return (
		<div className='flex flex-col h-screen'>
			<AppBar position='sticky' className='pt-[19px]' sx={{ backgroundColor: '#313131' }}>
				<Box className='flex justify-between px-[25px] pb-4'>
					<Box
						className='self-center mb-2'
						component={'img'}
						src={logo}
						alt={'logo'}
						sx={{ height: '38.9px', marginRight: 2 }}
					/>
					<Box>
						<CButton text={ButtonEnum.RETURN} icon={return_icon} onClick={returnTesting} />
					</Box>
				</Box>
				<Box className='bg-[#EDE2A9] h-8 border-b-black border-b flex '>
					<nav className='px-10 self-end'>
						<ul className='flex'>
							<HelpTab
								isActive={currentPage === HelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS}
								handleClick={() => setCurrentPage(HelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS)}
								title='Testing Tools'
							/>
							<HelpTab
								isActive={currentPage === HelpPageType.HELP_PAGE_TYPE_HOW_TO_ANSWER}
								handleClick={() => setCurrentPage(HelpPageType.HELP_PAGE_TYPE_HOW_TO_ANSWER)}
								title='How to Answer'
							/>
							<HelpTab
								isActive={currentPage === HelpPageType.HELP_PAGE_TYPE_READING_THE_PASSAGE}
								handleClick={() => setCurrentPage(HelpPageType.HELP_PAGE_TYPE_READING_THE_PASSAGE)}
								title='Reading the Passage'
							/>
							<HelpTab
								isActive={currentPage === HelpPageType.HELP_PAGE_TYPE_GENERAL_DESCRIPTION}
								handleClick={() => setCurrentPage(HelpPageType.HELP_PAGE_TYPE_GENERAL_DESCRIPTION)}
								title='General Descriptions'
							/>
							<HelpTab
								isActive={currentPage === HelpPageType.HELP_PAGE_TYPE_SECTION_DIRECTIONS}
								handleClick={() => setCurrentPage(HelpPageType.HELP_PAGE_TYPE_SECTION_DIRECTIONS)}
								title='Section Directions'
							/>
						</ul>
					</nav>
				</Box>
			</AppBar>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				className='mt-[10px] flex-1 overflow-auto'
			>
				{currentPage === HelpPageType.HELP_PAGE_TYPE_TESTING_TOOLS && <TestingTools />}
				{currentPage === HelpPageType.HELP_PAGE_TYPE_HOW_TO_ANSWER && <HowToAnswer />}
				{currentPage === HelpPageType.HELP_PAGE_TYPE_READING_THE_PASSAGE && <ReadingThePassage />}
				{currentPage === HelpPageType.HELP_PAGE_TYPE_GENERAL_DESCRIPTION && <GeneralDescriptions />}
				{currentPage === HelpPageType.HELP_PAGE_TYPE_SECTION_DIRECTIONS && <SectionDirections />}
			</motion.div>
			<Footer />
		</div>
	);
};

export default ReadingHelp;
