import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { onTestController, toeflSelector } from '../../../../core/redux/ToeflSlice';
import { useReactMediaRecorder } from 'react-media-recorder';
import { audioSelector, setAudioFile, setTracks } from '../../../../core/redux/AudioSlice';
import AudioPlayer from '../../../../core/components/AudioPlayBack/AudioPlayer';
import mic_start from '../../../../../assets/icons/mic-start.svg';
import { useDialog } from '../../../../utils/context/DialogContext';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { userSelector } from '../../../../core/redux/AuthSlice';
import { motion } from 'framer-motion';
import './speaking-question.scss';
import TestRepository from '../../../../core/repositories/TestRepository';

interface BlobPromiseInterface {
	UPFILE_NAME: string;
	UPFILE: any;
}

const SpeakingQuestion = () => {
	const dispatch = useAppDispatch();
	const testRepository = new TestRepository();
	const { content, data } = useAppSelector(toeflSelector);
	let { isPlaying } = useAppSelector(audioSelector);
	const [step, setStep] = useState(0);
	const [openDialog, closeDialog] = useDialog();
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);

	const [timer, setTimer] = useState(parseFloat(content.READY_TIME));
	const [start, setStart] = useState(false);
	const firstStart: any = useRef(true);
	const tick: any = useRef();

	useEffect(() => {
		if (firstStart.current) {
			firstStart.current = !firstStart.current;
			return;
		}

		if (start) {
			tick.current = setInterval(() => {
				setTimer((timer) => timer - 1);
			}, 1000);
		} else {
			clearInterval(tick.current);
		}

		return () => clearInterval(tick.current);
	}, [start]);

	const { startRecording, stopRecording, mediaBlobUrl } =
			useReactMediaRecorder({ video: false });

	useEffect(() => {
		if (!isPlaying) {
			if (step === 0) {
				setStep(step + 1);
			}

			if (step === 1) {
				setStep(step + 1);
			}

			if (step === 2) {
				setStart(true);

				setTimeout(() => {
					setStep(step + 1);
					setStart(false);
				}, parseFloat(content.READY_TIME) * 1000);
			}
			if (step === 3) {
				setStep(step + 1);
				setStart(true);
				setTimer(parseFloat(content.RESPONSE_TIME));
				startRecording();
			}
		}
	}, [isPlaying]);

	useEffect(() => {
		onPlayAudio();
	}, [step]);

	function onPlayAudio() {
		let audioType = '';
		switch (step) {
			case 1:
				audioType = content.QNO_AUDIO;
				break;
			case 2:
				audioType = content.READY_AUDIO;
				break;
			case 3:
				audioType = content.RESP_AUDIO;
				break;
		}
		if (!audioType) return;
		const file = content.ATTACH_FILE_LIST.filter((item: any) => item.REF_CODE === audioType);
		const tempTracks: any = [{
			AUDIO_FILE: file[0].FILE_DATA,
			IMG_FILE: '',
			WAIT_TIME: 1,
		}];
		dispatch(setTracks(tempTracks));
	}

	useEffect(() => {
		handleSave();

		return () => {
			stopRecording();
		};
	}, [mediaBlobUrl]);

	const handleSave = () => {
		if (mediaBlobUrl && timer < 0) {
			openDialog({
				children: (
						<>
							<DialogTitle
									className='py-3 w-full'
									sx={{ backgroundColor: '#B5EDFF', color: '#55737C' }}
									id='alert-dialog-title'
							>
								Success
							</DialogTitle>
							<DialogContent className='px-4 pt-4'>
								<Box className={'px-24 py-10 border-b-2 border-slate-200'}>
									<Typography>Response time has ended.</Typography>
									<br />
									<Typography>
										Please wait we are currently saving your
										response.
									</Typography>
								</Box>
							</DialogContent>
							<DialogActions className='pb-6 px-4'>
								<Button
										variant='contained'
										sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
										color='primary'
										onClick={onNext}>
									<p className='text-white'>Continue</p>
								</Button>
							</DialogActions>
						</>
				),
			});
		}

		if (mediaBlobUrl && timer > 0) {
			onNext();
		}
	};

	const blobPromise = (): Promise<BlobPromiseInterface> => {
		return new Promise(async (resolve) => {
			const audioBlob = await fetch(mediaBlobUrl as string).then((r) => r.blob());
			const reader = new FileReader();
			reader.readAsDataURL(audioBlob);
			reader.onloadend = () => {
				let base64data: any = reader.result;
				dispatch(setAudioFile(base64data));
				base64data = base64data?.slice(22);
				resolve({
					UPFILE_NAME: 'audiofile.mp3',
					UPFILE: base64data,
				});
			};
		});
	};

	const onNext = async () => {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				CURRENT_STEP: data.curr_step,
			},
		};
		const blob = await blobPromise();
		if (blob) {
			body.BODY = { ...body.BODY, ...blob };

			const submitRecord = await testRepository.nextAfterRecording(body);
			if (submitRecord.RESPONSE.CODE === '1000') {
				closeDialog();
				goNext();
			} else {
				closeDialog();
			}
		}
	};

	function goNext() {
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};
		dispatch(onTestController({ body }));
	}

	function onStopSpeakingTest() {
		setStart(false);
		stopRecording();
	}

	const secondsToTime = (secs: number) => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		return <>
			{hours !== 0 ? (hours < 10 ? `0${hours}` : hours) : `00`}:
			{minutes !== 0 ? (minutes < 10 ? `0${minutes}` : minutes) : `00`}:
			{seconds !== 0 ? (seconds < 10 ? `0${seconds}` : seconds) : `00`}
		</>;
	};

	const dispSecondsAsMins = (seconds: any) => {
		if (step === 4 && seconds <= 0) {
			stopRecording();
		}
		if (seconds > 0) {
			return secondsToTime(seconds);
		} else {
			return '00:00:00';
		}
	};

	const spinnerTransition = {
		repeat: Infinity,
		duration: 2,
		ease: 'linear',
	};

	return (
			<Box className='w-full flex flex-col h-full items-center'>
				<Box className='w-2/4 flex flex-col items-center mt-56'>
					{
						step >= 1 ?
								<Box
										contentEditable='false'
										dangerouslySetInnerHTML={{ __html: content.QNO_CONTENT }}
										className={'border-b border-slate-900 py-5 text-base'}
								></Box>
								: ''
					}


					{
						step >= 2 ?
								<Box className='flex flex-col items-center my-10'>
									<Typography>Preparation time : {content.READY_TIME} Seconds</Typography>

									<Typography>Response time : {content.RESPONSE_TIME} Seconds</Typography>
								</Box>
								: ''
					}
					{
						step >= 2 ?
								<Box className='w-1/3 mt-5 flex flex-col items-center justify-center'>
									<Box className='w-full flex-col items-center justify-center border-black border-2 rounded-lg'>
										<Box
												className='w-full text-center bg-black text-white p-1 '>{step === 2 ? 'Preparation time' : 'Response time'} </Box>
										<Box className='w-full flex justify-evenly items-center px-5 py-1'>
											{step >= 2 && step < 4 ?
													<Box sx={{ borderRadius: '50%' }} className={'border-4 border-[#FFB800]'}>
														<img src={mic_start} alt='' />
													</Box> : ''}
											{step === 4 ?
													<Box sx={{ position: 'relative' }}>
														<motion.span className={'circleSpinner'} animate={{ rotate: 360 }}
														             transition={spinnerTransition}>
														</motion.span>
														<Box className={'center'}
														     sx={{ backgroundColor: '#FFF', width: '3rem', height: '3rem', borderRadius: '50%' }}>
															<img src={mic_start} alt='' />
														</Box>
													</Box> : ''}
											{step > 4 ?
													<Box sx={{ borderRadius: '50%' }} className={'border-4 border-[#0047FF]'}>
														<img className={'p-0'} src={mic_start} alt='' />
													</Box> : ''}
											<Box className='text-3xl text-slate-500'>{dispSecondsAsMins(timer)}</Box>
										</Box>
									</Box>
									{
										step >= 4 ?
												<button onClick={onStopSpeakingTest} className='bg-black text-white py-2 px-4 rounded-md mt-5'>
													Stop Recording
												</button> : ''
									}

								</Box>
								: ''
					}
				</Box>
				<AudioPlayer visible={false} />
			</Box>
	);
};

export default SpeakingQuestion;
