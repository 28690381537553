import { Box, Typography } from '@mui/material';

const SpeakingConfirmRecord = () => {
	return (
			<Box className='flex flex-col h-full justify-center'>
				<Typography fontWeight={'bold'}>Click on <span className='underline'>Playback Response</span> to go back to the
					question and hear
					your
					recording.
				</Typography>
				<Typography fontWeight={'bold'} className='mb-5'>Once you have heard your response, you will have the
					opportunity to record your
					response
					again or confirm your response.
				</Typography>
				<Typography fontWeight={'bold'}>Click on <span className='underline'>Record Again</span> to go back to the
					question and immediately
					record
					your response again.
				</Typography>
				<Typography fontWeight={'bold'} className='mb-5'>Click on <span className='underline'>Confirm Response</span> to
					accept the
					recording now
					and to continue on to the next screen. Once you have chosen Confirm Response, you WILL NOT be able to return
					to this question.
				</Typography>
				<Typography fontWeight={'bold'} className='mb-5'>Click on <span className='underline'>Replay Talk</span> if you
					want to listen to
					the talk
					again.
				</Typography>
				<Typography fontWeight={'bold'} className='mb-5'>Click on <span className='underline'>Pause Test</span> to pause
					the test until you
					decide
					to continue.
				</Typography>
				<Typography fontWeight={'bold'}>Please select your choice above.</Typography>
			</Box>
	);
};

export default SpeakingConfirmRecord;