import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import TestRepository from '../repositories/TestRepository';
import { RootState } from '../store';
import { ReactInstance } from 'react';

export interface RejectType {
	CODE: string;
	MESSAGE: string;
}

interface ToeflInstance {
	test_code: string;
	curr_step: string;
	next_step: string;
	prev_step: string;
	test_mode: string;
	control_type: string;
	flow_cntl_name: string;
	direction_mode: string;
	remain_time: number;
	section_id: string;
	setlcd: string;
}

interface ReadingState {
	current_passage_step: string;
	reading_content: { [key: string]: any };
}

export interface ToeflState {
	data: ToeflInstance;
	content: { [key: string]: any };
	playback: { [key: string]: any };
	answer: { [key: string]: any };
	guess_answer: { [key: string]: any };
	strikeout: { [key: string]: any };
	highlight: { [key: string]: any };
	reading: ReadingState;
	testName: string;
	expired_time: number;
	expired: boolean;
	printComponent: ReactInstance | null;
	status: 'idle' | 'loading' | 'failed';
	highlights: any;
}

const testRepository = new TestRepository();

const initialState: ToeflState = {
	data: {
		setlcd: '',
		curr_step: '',
		test_code: '',
		test_mode: '',
		next_step: '',
		prev_step: '',
		direction_mode: '',
		control_type: '',
		flow_cntl_name: '',
		section_id: '',
		remain_time: 0,
	},
	content: {},
	answer: {},
	highlight: {},
	guess_answer: {},
	playback: {},
	reading: {
		current_passage_step: '',
		reading_content: {},
	},
	expired_time: 0,
	expired: false,
	testName: '',
	printComponent: null,
	status: 'idle',
	strikeout: {},
	highlights: [],
};

const ToeflSlice = createSlice({
	name: 'toefl',
	initialState,
	reducers: {
		settleData: (state, action: PayloadAction<ToeflState>) => {
			state.data = action.payload.data;
			state.answer = action.payload.answer;
			state.content = action.payload.content;
			state.status = action.payload.status;
		},
		setAnswer: (state, action) => {
			state.answer = action.payload;
		},
		resetAnswer: (state, action) => {
			state.answer = action.payload;
		},
		updateTestName: (state, action: PayloadAction<string>) => {
			state.testName = action.payload;
		},
		updateExpiredTime: (state, action: PayloadAction<number>) => {
			state.expired_time = action.payload;
		},
		updateSectionID: (state, action: PayloadAction<string>) => {
			state.data.section_id = action.payload;
		},
		setStrikeout: (state, action) => {
			state.strikeout = action.payload;
		},
		resetStrikeout: (state, action) => {
			state.strikeout = action.payload;
		},
		erasePlayBack: (state) => {
			state.playback = {};
		},
		injectPrintFunc: (state, action) => {
			state.printComponent = action.payload;
		},
		setHighlights: (state, action) => {
			state.highlights = action.payload;
			state.answer = { ...state.answer, HIGHLIGHT: action.payload };
		},
		resetHighlights: (state, action) => {
			state.highlights = action.payload;
		},
		updateReadingCurrentPassageStep: (state, action: PayloadAction<string>) => {
			state.reading.current_passage_step = action.payload;
		},
		updateGuessAnswer: (state, action) => {
			state.guess_answer = action.payload;
		},
		resetGuessAnswer: (state, action) => {
			state.guess_answer = action.payload;
		},
		updateExpired: (state, action: PayloadAction<boolean>) => {
			state.expired = action.payload;
		},
		resetExpired: (state, action: PayloadAction<boolean>) => {
			state.expired = action.payload;
		},
	},
	extraReducers(builder) {
		builder
				.addCase(onTestController.pending, (state, _) => {
					state.status = 'loading';
				})
				.addCase(onTestController.fulfilled, (state, action) => {
					const { onSuccess } = action.meta.arg;
					state.data = action.payload.data;
					state.content = action.payload.content;
					onSuccess && onSuccess(action.payload.content);
					state.status = 'idle';
				})
				.addCase(onTestController.rejected, (state, action) => {
					const { onRejected } = action.meta.arg;
					onRejected && onRejected(action.payload as RejectType);
					state.status = 'idle';
				})
				.addCase(onTestStart.pending, (state, _) => {
					state.status = 'loading';
				})
				.addCase(onTestStart.fulfilled, (state, action) => {
					const { onSuccess } = action.meta.arg;
					state.data = action.payload.data;
					state.content = action.payload.content;
					onSuccess && onSuccess(action.payload.content);
					state.status = 'idle';
				})
				.addCase(onReview.pending, (state, _) => {
					state.status = 'loading';
				})
				.addCase(onReview.fulfilled, (state, action) => {
					const { onSuccess } = action.meta.arg;
					onSuccess && onSuccess(action.payload);
					state.status = 'idle';
				})
				.addCase(onReplayTalk.pending, (state) => {
					state.status = 'loading';
				})
				.addCase(onReplayTalk.fulfilled, (state, action) => {
					state.content = action.payload.content;
					state.status = 'idle';
				})
				.addCase(onReplayTalk.rejected, (state, action) => {
					const { onRejected } = action.meta.arg;
					onRejected && onRejected();
					state.status = 'idle';
				})
				.addCase(onPlayBackResponseRequest.pending, (state) => {
					state.status = 'loading';
				})
				.addCase(onPlayBackResponseRequest.fulfilled, (state, action) => {
					state.playback = action.payload;
					state.status = 'idle';
				})
				.addCase(onFetchCurrentPassage.pending, (state) => {
					state.status = 'loading';
				})
				.addCase(onFetchCurrentPassage.fulfilled, (state, action) => {
					state.reading.reading_content = action.payload;
					state.status = 'idle';
				});
	},
});

export const onTestStart = createAsyncThunk(
		'toefl/testStart',
		async (args: { body: object; onSuccess: (args: object) => void }, { rejectWithValue }) => {
			try {
				const res = await testRepository.onTestStart(args.body);

				if (res.RESPONSE.CODE === '1000') {
					return {
						data: {
							setlcd: res.BODY.SETLCD,
							button_list: res.BODY.BUTTON_LIST,
							curr_step: res.BODY.CURRENT_STEP,
							test_code: res.BODY.TEST_CODE,
							control_type: res.BODY.CONTROL_TYPE,
							direction_mode: res.BODY.DIRECTION_MODE,
							flow_cntl_name: res.BODY.FLOW_CNTL_NAME,
							prev_step: res.BODY.PREV_STEP,
							next_step: res.BODY.NEXT_STEP,
							test_mode: res.BODY.TEST_MODE,
							remain_time: res.BODY.REMAIN_TIME,
							section_id: res.BODY.SECTION_ID,
						} as ToeflInstance,
						content: res.BODY,
					};
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		},
);

export const onTestController = createAsyncThunk(
		'toefl/testController',
		async (
				args: {
					body: object;
					onSuccess?: (args: object) => void;
					onRejected?: (args: RejectType) => void;
				},
				{ rejectWithValue },
		) => {
			try {
				const res = await testRepository.onTestController(args.body);
				if (res.RESPONSE.CODE === '1000') {
					return {
						data: {
							setlcd: res.BODY.SETLCD,
							curr_step: res.BODY.CURRENT_STEP,
							test_code: res.BODY.TEST_CODE,
							control_type: res.BODY.CONTROL_TYPE,
							direction_mode: res.BODY.DIRECTION_MODE,
							flow_cntl_name: res.BODY.FLOW_CNTL_NAME,
							prev_step: res.BODY.PREV_STEP,
							next_step: res.BODY.NEXT_STEP,
							test_mode: res.BODY.TEST_MODE,
							remain_time: res.BODY.REMAIN_TIME,
						} as ToeflInstance,
						content: res.BODY,
					};
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		},
);

export const onReview = createAsyncThunk(
		'toefl/review',
		async (args: { body: object; onSuccess: (data: any) => void }, { rejectWithValue }) => {
			try {
				const res = await testRepository.onRequestReview(args.body);
				if (res.RESPONSE.CODE === '1000') {
					return res;
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		},
);

export const saveCurrentStatus = createAsyncThunk(
		'toefl/saveCurrentStatus',
		async (body: object, { rejectWithValue }) => {
			try {
				const res = await testRepository.onSaveCurrentStatus(body);
				if (res.RESPONSE.CODE !== '1000') {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		},
);

export const onExpiredTime = createAsyncThunk(
		'toefl/expiredTime',
		async (body: object, { rejectWithValue }) => {
			try {
				const res = await testRepository.onExpiredTime(body);
				if (res.RESPONSE.CODE === '1000') {
					return res.BODY;
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		},
);

export const onReplayTalk = createAsyncThunk(
		'toefl/replayTalk',
		async (args: { body: object; onRejected: () => void }, { rejectWithValue }) => {
			try {
				const res = await testRepository.onReplayTalk(args.body);
				if (res.RESPONSE.CODE === '1000') {
					return {
						content: res.BODY,
					};
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		});

export const onPlayBackResponseRequest = createAsyncThunk(
		'toefl/playbackResponse',
		async (body: object, { rejectWithValue }) => {
			try {
				const res = await testRepository.onTestController(body);
				if (res.RESPONSE.CODE === '1000') {
					return res.BODY;
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		});

export const onFetchCurrentPassage = createAsyncThunk(
		'toefl/currentPassage',
		async (body: object, { rejectWithValue }) => {
			try {
				const res = await testRepository.onTestController(body);
				if (res.RESPONSE.CODE === '1000') {
					return res.BODY;
				} else {
					return rejectWithValue(res.RESPONSE);
				}
			} catch (error) {
				return rejectWithValue(error);
			}
		});

export const sessionEnded = createAsyncThunk('toefl/sessionEnd', () => {
	const navigate = useNavigate();
	localStorage.clear();
	navigate('/login', { replace: true });
});

export const logout = createAsyncThunk('toefl/logout', () => {
	const navigate = useNavigate();
	localStorage.clear();
	navigate('/login', { replace: true });
});

export const {
	settleData,
	setAnswer,
	updateTestName,
	resetAnswer,
	updateExpiredTime,
	updateSectionID,
	setStrikeout,
	erasePlayBack,
	injectPrintFunc,
	setHighlights,
	resetHighlights,
	resetStrikeout,
	updateReadingCurrentPassageStep,
	updateGuessAnswer,
	resetGuessAnswer,
	updateExpired,
	resetExpired,
} = ToeflSlice.actions;

export const toeflSelector = (state: RootState) => state.toefls;
export default ToeflSlice.reducer;
