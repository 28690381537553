import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface TopBarState {
	guessMode: boolean;
	showAnswer: boolean;
	scrollBottom: boolean;
	enabledNextBtn: boolean;
	MSCTViewMode: string;
	correctQuestion: boolean;
}

const initialState: TopBarState = {
	guessMode: false,
	showAnswer: false,
	scrollBottom: false,
	enabledNextBtn: true,
	MSCTViewMode: 'question',
	correctQuestion: false
};

const TopBarlSlice = createSlice({
	name: 'topbar',
	initialState,
	reducers: {
		resetTopBarState: () => initialState,
		updateGuessMode: (state, action: PayloadAction<boolean>) => {
			state.guessMode = action.payload;
		},
		updateShowAnswer: (state, action: PayloadAction<boolean>) => {
			state.showAnswer = action.payload;
		},
		updateScrollBottom: (state, action: PayloadAction<boolean>) => {
			state.scrollBottom = action.payload;
		},
		updateNextBtn: (state, action: PayloadAction<boolean>) => {
			state.enabledNextBtn = action.payload;
		},
		toggleMSCTViewMode: (state, action: PayloadAction<string>) => {
			state.MSCTViewMode = action.payload;
		},
		updateCorrectQuestion: (state, action: PayloadAction<boolean>) => {
			state.correctQuestion = action.payload;
		},
	},
});

export const {
	updateGuessMode,
	updateScrollBottom,
	updateShowAnswer,
	resetTopBarState,
	updateNextBtn,
	toggleMSCTViewMode,
	updateCorrectQuestion
} = TopBarlSlice.actions;

export const topBarSelector = (state: RootState) => state.topbar;
export default TopBarlSlice.reducer;
