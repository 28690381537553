import { Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ControlType, FlowControlName } from '../../core/constants/type.enum';
import { useAppSelector } from '../../core/hooks';
import { toeflSelector } from '../../core/redux/ToeflSlice';
import { useDialog } from '../../utils/context/DialogContext';
import EndSectionRemainTime from '../non-functional/reading-section/end-section-remain-time/end-section-remain-time';
import EndSectionTimeOut from '../non-functional/reading-section/end-section-time-out/end-section-time-out';
import EndSection from '../non-functional/reading-section/end-section/end-section';
import RemainTimeAdvise from '../non-functional/remain-time-advise/remain-time-advise';
import ReadingSectionDirections from '../reading-section-directions/reading-section-directions';
import StartScreen from '../start-screen/start-screen';
import CopyrightInformation from './copyright-infomation/copyright-information';
import EndOfTest from './end-of-test/end-of-test';
import GeneralTestInformation from './generate-test-information/general-test-information';
import HardwareCheck from './hardware-check/hardware-check';
import HardwareCheckImageText from './hardware-check/hardware-check-image-text/hardware-check-image-text';
import HardwareCheckMicrophone from './hardware-check/hardware-check-microphone/hardware-check-microphone';
import HardwareCheckText from './hardware-check/hardware-check-text/hardware-check-text';
import DirL2 from './listening/dir-l2/dir-l2';
import DirBreak from './listening/dir_break/dir_break';
import EndL1 from './listening/end-l1/end-l1';
import EndL2 from './listening/end_l2/end-l2';
import ImageAndListeningAudio from './listening/image-and-listening-audio/image-and-listening-audio';
import ListenSectionDirection from './listening/listen-section-direction/listen-section-direction';
import ListeningQuestion from './listening/listening-question/listening-question';
import ReadingPassageAndQuestion from './reading-passage-and-question/reading-passage-and-question';
import ReadingPassageOnlyQuestion from './reading-passage-only-question/reading-passage-only-question';
import ReadingPassage from './reading-passage/reading-passage';
import ScoreReport from './score-report/score-report';
import ShortListening from './listening/short-listening/short-listening';
import ShortReading from './speaking/short-reading/short-reading';
import SpeakingSectionDirection from './speaking/speaking-section-direction/speaking-section-direction';
import WritingEndScreen from './writing/end_w1/end_w1';
import WritingBaseOnKnowledgeAndExperienceDirections
	from './writing/writing-based-on-knowledge/writing-based-on-knowledge';
import WritingBasedOnReadingAndListeningDirections
	from './writing/writing-based-on-reading-direction/writing-based-on-reading-direction';
import WritingRequest from './writing/writing-request/writing-request';
import WritingSectionDirection from './writing/writing-section-direction/writing-section-direction';
import WritingWrl from './writing/writing-wrl/writing-wrl';
import ListenCarefully from './speaking/listen-carefully/listen-carefully';
import SpeakingEndSection from './speaking/speaking-end/speaking-end';
import Survey from './survey/survey';
import AdjConfirm from './hardware-check/adj-confirm/adj-confirm';
import SpeakingQuestion from './speaking/speaking-question/speaking-question';
import SpeakingConfirmRecord from './speaking/speaking-confirm-record/speaking-confirm-record';
import { useEffect } from 'react';
import useFullScreen from '../../core/customhooks/useFullScreen';
import TestReloadedDialog from '../../core/components/modals/TestReloadedDialog';

const TestCommonScreen = () => {
	// const storage = new LocalStorageService();
	const [openDialog, closeDialog] = useDialog();
	const [openFullScreen, closeFullScreen] = useFullScreen(document.documentElement);
	const { content, status, expired_time } = useAppSelector(toeflSelector);
	const navigate = useNavigate();

	const noMatchTestNavigate = () => {
		openDialog({
			children: (
					<TestReloadedDialog closeDialog={closeDialog} navigate={navigate} />
			),
		})
		;
	};

	// Check if browser is full screen
	// const openFullscreen = useCallback(async () => {
	// 	if (elem.requestFullscreen) {
	// 		await elem.requestFullscreen();
	// 	}
	// }, [elem]);

	// const isFullScreen = useCallback(() => {
	// 	return (!window.screenTop && !window.screenY);
	// }, []);

	useEffect(() => {
		setTimeout(async () => {
			await openFullScreen();
		}, 1000);
	}, [status]);

	const switchCaseTestingScreen = (cntl_type: string, flow_cntl_name: string) => {
		if (flow_cntl_name && flow_cntl_name.includes('_NEW')) {
			flow_cntl_name = flow_cntl_name.replace('_NEW', '');
		}
		switch (cntl_type) {
			case ControlType.A:
				switch (flow_cntl_name) {
					case FlowControlName.STM:
						return <StartScreen />;
				}
				break;
			case ControlType.B:
				switch (flow_cntl_name) {
					case FlowControlName.GCI:
						return <CopyrightInformation />;
					case FlowControlName.GTI:
						return <GeneralTestInformation />;
					case FlowControlName.HC1:
						return <HardwareCheck />;
					case FlowControlName.HC2:
						return <HardwareCheckImageText />;
					case FlowControlName.HCCV:
						return <HardwareCheckText />;
					case FlowControlName.HCAM:
						return <HardwareCheckMicrophone />;
					case FlowControlName.DIR_BREAK:
						return <DirBreak />;
					case FlowControlName.EOT:
						return <EndOfTest />;
				}
				break;
			case ControlType.BR:
				switch (flow_cntl_name) {
					case FlowControlName.DIR_R:
						return <ReadingSectionDirections />;
					case FlowControlName.END_R:
						if (content.TEST_MODE === 'U') {
							return <EndSection />;
						} else {
							if (expired_time > Date.now()) {
								return <EndSectionRemainTime />;
							} else {
								return <EndSectionTimeOut />;
							}
						}
				}
				break;
			case ControlType.C1:
				switch (flow_cntl_name) {
					case FlowControlName.Reading:
						return <ReadingPassage />;
				}
				break;
			case ControlType.C2:
				switch (flow_cntl_name) {
					case FlowControlName.Reading:
						return <ReadingPassageAndQuestion />;
				}
				break;
			case ControlType.C3:
				switch (flow_cntl_name) {
					case FlowControlName.Reading:
						return <ReadingPassageOnlyQuestion />;
				}
				break;
			case ControlType.BL:
				switch (flow_cntl_name) {
					case FlowControlName.HEADSET_L:
						return <HardwareCheckImageText />;
					case FlowControlName.CHG_VOL:
						return <HardwareCheckText />;
					case FlowControlName.DIR_L1:
						return <ListenSectionDirection />;
					case FlowControlName.END_L1:
						return <EndL1 />;
					case FlowControlName.DIR_L2:
						return <DirL2 />;
					case FlowControlName.END_L2:
						return <EndL2 />;
					case FlowControlName.DIR_BREAK:
						return <DirBreak />;
				}
				break;
			case ControlType.D1:
				switch (flow_cntl_name) {
					case FlowControlName.Listening1_setLeader:
						return <ImageAndListeningAudio />;
					case FlowControlName.Listening2_setLeader:
						return <ImageAndListeningAudio />;
				}
				break;
			case ControlType.D2:
				switch (flow_cntl_name) {
					case FlowControlName.Listening1_question:
						return <ListeningQuestion />;
					case FlowControlName.Listening2_question:
						return <ListeningQuestion />;
				}
				break;
			case ControlType.D3:
				switch (flow_cntl_name) {
					case FlowControlName.Listening1_stimulus:
						return <ImageAndListeningAudio />;
					case FlowControlName.Listening2_stimulus:
						return <ImageAndListeningAudio />;
				}
				break;
			case ControlType.BS:
				switch (flow_cntl_name) {
					case FlowControlName.HEADSET_S:
						return <HardwareCheckImageText />;
					case FlowControlName.ADJ_VOL:
						return <HardwareCheckMicrophone />;
					case FlowControlName.ADJ_VOL_CONFIRM:
						return <AdjConfirm />;
					case FlowControlName.DIR_S1:
						return <SpeakingSectionDirection />;
					case FlowControlName.END_S1:
						return <SpeakingEndSection />;
				}
				break;
			case ControlType.E5:
				switch (flow_cntl_name) {
					case FlowControlName.Speaking_listen_carefully:
						return <ListenCarefully />;
				}
				break;
			case ControlType.E1:
				switch (flow_cntl_name) {
					case FlowControlName.Speaking_question:
						return <SpeakingQuestion />;
				}
				break;
			case ControlType.E2:
				switch (flow_cntl_name) {
					case FlowControlName.Speaking_confirm_record:
						return <SpeakingConfirmRecord />;
				}
				break;
			case ControlType.E4:
				switch (flow_cntl_name) {
					case FlowControlName.Speaking_stimulus_listening:
						return <ShortListening />;
				}
				break;
			case ControlType.E3:
				switch (flow_cntl_name) {
					case FlowControlName.Speaking_stimulus_reading:
						return <ShortReading />;
				}
				break;
			case ControlType.BW:
				switch (flow_cntl_name) {
					case FlowControlName.DIR_W1:
						return <WritingSectionDirection />;
					case FlowControlName.DIR_WRL:
						return <WritingBasedOnReadingAndListeningDirections />;
					case FlowControlName.DIR_WKE:
						return <WritingBaseOnKnowledgeAndExperienceDirections />;
					case FlowControlName.END_W1:
						return <WritingEndScreen />;
				}
				break;
			case ControlType.F1:
				switch (flow_cntl_name) {
					case FlowControlName.Writing_WRL:
						return <WritingWrl />;
				}
				break;
			case ControlType.F4:
				switch (flow_cntl_name) {
					case FlowControlName.Writing_stimulus:
						return <ImageAndListeningAudio />;
				}
				break;
			case ControlType.F5:
				switch (flow_cntl_name) {
					case FlowControlName.Writing_WRL:
						return <WritingRequest />;
				}
				break;
			case ControlType.F3:
				switch (flow_cntl_name) {
					case FlowControlName.Writing_WKE:
						return <WritingRequest />;
				}
				break;
			case ControlType.F2:
				switch (flow_cntl_name) {
					case FlowControlName.Writing_notice_the_remain_time:
						return <RemainTimeAdvise />;
				}
				break;
			case ControlType.R:
				switch (flow_cntl_name) {
					case FlowControlName.설문조사:
						return <Survey />;
				}
				break;
			case ControlType.G:
				switch (flow_cntl_name) {
					case FlowControlName.Score_Report:
						return <ScoreReport />;
				}
				break;
			default:
				noMatchTestNavigate();
				break;
		}
	};

	return (
			<>
				{status === 'loading' ? (
						<Box className='flex items-center justify-center h-full'>
							<CircularProgress />
						</Box>
				) : (
						switchCaseTestingScreen(content.CONTROL_TYPE, content.FLOW_CNTL_NAME)
				)}
			</>
	);
};

export default TestCommonScreen;
