import { Box, Paper, Typography } from '@mui/material';
import check_bold_icon from '../../../../../assets/imgs/listen-table-check.svg';

interface tableProps {
	name: string;
	isAsia: boolean;
}

const ListenHowToAnswerSub1 = () => {
	const tableArr: tableProps[] = [
		{
			name: 'United States',
			isAsia: true,
		},
		{
			name: 'Japan',
			isAsia: false,
		},
		{
			name: 'China',
			isAsia: false,
		},
		{
			name: 'Canada',
			isAsia: true,
		},
	];

	return (
		<Paper className='p-10 ' elevation={3}>
			<p className='mb-10'>For this type of question you will answer by selecting boxes.</p>
			<Typography variant='body1' fontWeight={'bold'} className='mb-4'>
				How to answer:
			</Typography>
			<Box className='mb-4'>
				<p className='ml-10'>● Select one box in each row.</p>
			</Box>

			<Typography variant='body1' fontWeight={'bold'} className='mb-4'>
				How to Change an Answer:
			</Typography>
			<Box className='mb-4'>
				<p className='ml-10'>
					● Select a box to erase an answer choice or select another box in the row.
				</p>
			</Box>

			<Box
				sx={{ width: '483px', paddingY: '15px', paddingX: '24px' }}
				className='border-2 border-dashed border-slate-900 flex flex-col justify-center items-center'
			>
				<p className='text-base mb-4'>
					Indicate which countries are found in North America and which countries are found in Asia.
				</p>
				<Box className='mb-4 py-1 px-10 text-base' sx={{ backgroundColor: '#D9D9D9' }}>
					<p>Select the correct boxes.</p>
				</Box>
				<table className='mb-10'>
					<thead>
						<tr>
							<td className='border border-slate-900 w-[241px]'></td>
							<td align='center' className='border border-slate-900 w-[98.5px] text-xs px-5 py-1'>
								Asia
							</td>
							<td align='center' className='border border-slate-900 w-[98.5px] text-xs px-5 py-1'>
								North America
							</td>
						</tr>
					</thead>
					<tbody>
						{tableArr.map((val, idx) => (
							<tr key={idx}>
								<td align='center' className='border border-slate-900 text-xs py-2'>
									{val.name}
								</td>
								<td align='center' className='border border-slate-900 self-center'>
									{val.isAsia ? <img src={check_bold_icon} alt='check_icon' /> : null}
								</td>
								<td align='center' className='border border-slate-900 self-center'>
									{!val.isAsia ? <img src={check_bold_icon} alt='check_icon' /> : null}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Box>
			<Typography variant='body1' className='mt-4'>
				Select <strong>Return</strong> to go on.
			</Typography>
		</Paper>
	);
};

export default ListenHowToAnswerSub1;
