import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from '../../core/hooks';
import { login } from '../../core/redux/AuthSlice';

interface LoginB2B {
	INST: string;
	NAME: string;
	PRN1: string;
	PRN2: string;
}

const B2BYup = yup.object().shape({
	INST: yup
		.string()
		.required('기관명을 입력해주세요')
		.matches(/^[a-zA-Z0-9]+$/, '기관명을 다시 입력해주세요.'),
	NAME: yup
		.string()
		.required('응시자명을 입력해주세요')
		.matches(/^[a-zA-Z0-9]+$/, '기관명을 다시 입력해주세요.'),
	PRN1: yup
		.string()
		.required('등록번호를 입력해주세요')
		.matches(/^[0-9]+$/, '기관명을 다시 입력해주세요.'),
	PRN2: yup
		.string()
		.required('등록번호를 입력해주세요')
		.matches(/^[0-9]+$/, '기관명을 다시 입력해주세요.'),
});

const B2BForm = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginB2B>({
		mode: 'onSubmit',
		resolver: yupResolver(B2BYup),
	});

	const onRejected = () => {
		console.log('rejected');
	};

	const onSubmit: SubmitHandler<LoginB2B> = (data) => {
		dispatch(login({ loginBody: data, onRejected })).then((_) => {
			navigate('/my-page');
		});
	};

	return (
		<>
			<Box className=' mb-8 px-20'>
				<form>
					<Typography variant='body1' className='mb-2'>
						기관명
					</Typography>
					<Controller
						name='INST'
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								className='w-full bg-white mb-5'
								variant='outlined'
								required
								value={value || ''}
								onChange={(e) => onChange(e.target.value)}
								error={error !== undefined ? true : false}
								helperText={error?.message}
								placeholder='기관명을 입력해 주세요.'
							/>
						)}
					/>

					<Typography variant='body1' className='mb-2'>
						응시자명
					</Typography>
					<Controller
						name='NAME'
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								className='w-full bg-white mb-5'
								variant='outlined'
								required
								name='company-user-name'
								value={value || ''}
								onChange={(e) => onChange(e.target.value)}
								error={error !== undefined ? true : false}
								helperText={error?.message}
								placeholder='응시자명을 입력해 주세요.'
							/>
						)}
					/>

					<Typography variant='body1' className='mb-2'>
						등록번호
					</Typography>
					<Box className='flex items-center gap-3'>
						<Controller
							name='PRN1'
							control={control}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<TextField
									className='w-full bg-white'
									variant='outlined'
									required
									name='company-user-number1'
									value={value || ''}
									onChange={(e) => onChange(e.target.value)}
									error={error !== undefined ? true : false}
									placeholder='첫 번째 개인번호'
								/>
							)}
						/>

						<div className='text-3xl'>-</div>
						<Controller
							name='PRN2'
							control={control}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<TextField
									className='w-full bg-white'
									variant='outlined'
									required
									name='company-user-number1'
									value={value || ''}
									onChange={(e) => onChange(e.target.value)}
									error={error !== undefined ? true : false}
									placeholder='두 번째 개인번호'
								/>
							)}
						/>
					</Box>
					{errors.PRN1 || errors.PRN2 ? (
						<Box className='px-3 py-1' sx={{ backgroundColor: '#fff' }}>
							<p className='text-xs text-[#d32f2f]'>
								{errors.PRN1?.message ?? errors.PRN2?.message}
							</p>
						</Box>
					) : null}
				</form>
			</Box>
			<Box className='flex justify-center gap-5'>
				<Button
					className='bg-gradient-to-b from-[#5495E2] to-[#0F4C95] rounded-lg'
					sx={{ width: '200px', height: '45px' }}
					color='primary'
					variant='contained'
					onClick={handleSubmit(onSubmit)}
				>
					Continue
				</Button>
				<Button
					className=' bg-gradient-to-b from-[#5B5A5A] to-[#272727] rounded-lg'
					sx={{ width: '200px' }}
					color='secondary'
					variant='contained'
				>
					Cancel
				</Button>
			</Box>
		</>
	);
};

export default B2BForm;
