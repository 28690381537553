import { AppBar, Box, Card, CardContent, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/icons/G-LOGO.svg';
import { LocalStorageService } from '../../core/services/storage.service';
import B2BForm from './b2b-form';
import B2CForm from './b2c-form';

enum UserType {
	NormalUser = 'NormalUser',
	CompanyUser = 'CompanyUser',
}

function Login() {
	const navigate = useNavigate();

	const [loginMode, setLoginMode] = useState<UserType>(UserType.NormalUser);

	//Todo CompanyUser Login//////////////////////////////
	//Sample company user data
	// "INST" : "BuildTest",    --> companyName
	// "NAME" : "brickmate1",   --> companyUserName
	// "PRN1" : "999999",       --> companyUserNumber1
	// "PRN2" : "001"           --> companyUserNumber2
	//////////////////////////////////////////////////////

	const onLoggedIn = useCallback(() => {
		const storage = new LocalStorageService();
		if (storage.getItem('USER')) {
			navigate('/my-page', { replace: true });
		}
	}, [navigate]);

	useEffect(() => {
		onLoggedIn();
	}, [onLoggedIn]);

	return (
		<>
			<AppBar position='sticky' className='pt-[19px]' sx={{ backgroundColor: '#313131' }}>
				<Box className='flex justify-between px-[25px] pb-4'>
					<Box
						className='self-center mb-2'
						component={'img'}
						src={logo}
						alt={'logo'}
						sx={{ height: '38.9px', marginRight: 2 }}
					/>
				</Box>
				<Box className='bg-[#EDE2A9] h-8 border-b-black border-b flex px-5'></Box>
			</AppBar>
			<Box className='flex items-center justify-center h-[80vh]'>
				<Card sx={{ width: '577px', borderRadius: '12px', border: '1px solid #0257BC' }}>
					<CardContent className='flex flex-col justify-center p-0 bg-[#F7F7F7] pb-7'>
						<Box className='mb-7 flex'>
							<Typography
								className={
									'w-full text-md text-white flex justify-center items-center py-4 cursor-pointer  ' +
									(loginMode === 'NormalUser'
										? 'bg-[#5495E2] hover:bg-[#0F4C95] '
										: 'bg-[#313131] hover:bg-black')
								}
								onClick={() => setLoginMode(UserType.NormalUser)}
							>
								일반사용자 로그인
							</Typography>
							<Typography
								className={
									'w-full text-md text-white flex justify-center items-center py-4 cursor-pointer  ' +
									(loginMode === 'CompanyUser'
										? 'bg-[#5495E2] hover:bg-[#0F4C95]'
										: 'bg-[#313131] hover:bg-black')
								}
								onClick={() => setLoginMode(UserType.CompanyUser)}
							>
								기관사용자 로그인
							</Typography>
						</Box>
						<Box className='flex flex-col self-center mb-5'>
							<Typography className='font-extrabold text-[40px]'>Login</Typography>
						</Box>
						{loginMode === 'NormalUser' ? <B2CForm /> : <B2BForm />}
					</CardContent>
				</Card>
			</Box>
		</>
	);
}

export default Login;
