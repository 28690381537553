import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AudioProps {
	AUDIO_FILE: string;
	IMG_FILE: string;
	WAIT_TIME: number;
}

export interface AudioState {
	isPlaying: boolean;
	isAudioEnded: boolean;
	volumeCntl: number;
	duration: number;
	tracks: AudioProps[];
	audioFile: string;
}


const initialState: AudioState = {
	isPlaying: false,
	isAudioEnded: false,
	duration: 0,
	volumeCntl: 1,
	tracks: [{ WAIT_TIME: 0, IMG_FILE: '', AUDIO_FILE: '' }],
	audioFile: '',
};

const AudioSlice = createSlice({
	name: 'audio',
	initialState,
	reducers: {
		resetAudioState: (state) => {
			state.isPlaying = false;
			state.isAudioEnded = false;
			state.tracks = [{ WAIT_TIME: 0, IMG_FILE: '', AUDIO_FILE: '' }];
			state.duration = 0;
		},
		setTracks: (state, action: PayloadAction<AudioProps[]>) => {
			state.tracks = action.payload;
		},
		updatePlayingTrack: (state, action: PayloadAction<boolean>) => {
			state.isPlaying = action.payload;
		},
		updateAudioEnded: (state, action: PayloadAction<boolean>) => {
			state.isAudioEnded = action.payload;
		},
		updateVolumeCntl: (state, action: PayloadAction<number>) => {
			state.volumeCntl = action.payload;
		},
		setAudioFile: (state, action) => {
			state.audioFile = action.payload;
		},
		resetAudioFile: (state) => {
			state.audioFile = '';
		},
	},
});

export const {
	resetAudioState,
	setTracks,
	updatePlayingTrack,
	updateAudioEnded,
	updateVolumeCntl,
	setAudioFile,
	resetAudioFile,
} = AudioSlice.actions;

export const audioSelector = (state: RootState) => state.audio;
export default AudioSlice.reducer;