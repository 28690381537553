import { Box, Paper } from '@mui/material';

import ArrowTitle from '../components/arrow-title';
import table1 from '../../../../../assets/imgs/table1.png';
import table2 from '../../../../../assets/imgs/table2.png';

const HowToAnswerSub1 = () => {
	return (
		<Paper className='p-10 ' elevation={3}>
			<ArrowTitle title='How to answer:' />
			<Box className='mb-10 text-[#626576]'>
				<li>Use the mouse to point to the object.</li>
				<li>
					Hold down the left mouse button and then move the object to the place specified by the
					question.
				</li>
				<li>Release the mouse button when the object is inside the box.</li>
			</Box>

			<ArrowTitle title='How to Change Your Answer Choices:' />
			<Box className='mb-5 text-[#626576]'>
				<li>To remove an answer choice, select it or drag it back to the answer choices.</li>
				<li>Drag your new answer choice to the place where it belongs.</li>
			</Box>

			<p>Which countries are found in North America and which countries are found in Asia?</p>

			<p className='flex items-center gap-3 px-5 py-3 bg-[#DEE4D0] text-gray-600 rounded-md mt-3 mb-5'>
				<svg
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='20' height='20' fill='white' fill-opacity='0.01' />
					<circle cx='9.99984' cy='10.0003' r='8.33333' stroke='#626576' stroke-width='1.33333' />

					<path d='M10.8332 7.91699H9.1665V14.5837H10.8332V7.91699Z' fill='#626576' />
					<circle cx='9.99984' cy='6.25033' r='0.833333' fill='#626576' />
				</svg>
				Select a word. Then drag it to the place where it belongs.
			</p>
			<img className='w-96 mb-10' alt='table1' src={table1} />
			<p className='mb-5'>Answer Choices:</p>
			<img className='w-96 mb-10' alt='table2' src={table2} />
		</Paper>
	);
};

export default HowToAnswerSub1;
