import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";

import "./Droppable.scss";

const Droppable = ({ id, items, active }: any) => {
    const { setNodeRef } = useDroppable({ id });

    return (
        <SortableContext disabled={active} id={id} items={items} strategy={rectSortingStrategy}>
            <ul className={id + ' droppable'} ref={setNodeRef}>
                {items.map((item: any) => (
                    <SortableItem key={item} id={item} group={id} />
                ))}
            </ul>
        </SortableContext>
    );
};

export default Droppable;
