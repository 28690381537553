import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/userResponse.model';
import AuthRepository from '../repositories/AuthRepository';
import { LocalStorageService } from './../services/storage.service';
import { RootState } from './../store';

export interface UserState {
	user: User;
	status: 'idle' | 'loading' | 'failed';
}

export interface LoginCredentials {
	id: string;
	password: string;
}

const storage = new LocalStorageService();
const authRepository = new AuthRepository();

const initialState = {
	user: {},
	status: 'idle',
} as UserState;

const authSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(login.fulfilled, (state, action: PayloadAction<User>) => {
				state.user = action.payload;
				state.status = 'idle';
			})
			.addCase(login.rejected, (state, action) => {
				const { onRejected } = action.meta.arg;
				onRejected && onRejected(action.payload);
				state.status = 'idle';
			})
			.addCase(loadedUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.status = 'idle';
			});
	},
});

export const login = createAsyncThunk(
	'users/login',
	async (args: { loginBody: object; onRejected?: (args: any) => void }, { rejectWithValue }) => {
		const body = {
			HEADER: {},
			BODY: { ...args.loginBody },
		};
		try {
			const user = await authRepository.login(body);

			if (user.RESPONSE.CODE === '1000') {
				storage.setItem('USER', {
					SESSION: user.HEADER.SESSION,
					USER_ID: user.BODY.USER_INFO.USER_ID,
				});
			} else {
				return rejectWithValue(user.RESPONSE);
			}
			return {
				SESSION: user!.HEADER.SESSION,
				USER_ID: user!.BODY.USER_INFO.USER_ID,
			};
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const loadedUser = createAsyncThunk('user/profile', async () => {
	let user: User;

	const local = storage.getItemWithValue('USER');
	user = {
		SESSION: local.SESSION,
		USER_ID: local.USER_ID,
	};
	return user;
});

export const userSelector = (state: RootState) => state.users;
export default authSlice.reducer;
