import TitleSection from '../../../../core/components/section/TitleSection';

const WritingEndScreen = () => {
	return (
		<TitleSection title='End of Section'>
			<div className='mt-32'>
				<p className='text-[#000000]'>This is the end of the writing section.</p>
				<br />
				<p>
					Select <span className='font-bold'>Continue</span> to go in.
				</p>
			</div>
		</TitleSection>
	);
};

export default WritingEndScreen;
