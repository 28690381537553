import { Box } from '@mui/material';
import writing_table from '../../../../../assets/imgs/table-listen.png';
import TitleSection from '../../../../core/components/section/TitleSection';
const HowToAnswer = () => {
	return (
		<TitleSection title='Essay'>
				<p className='mb-5'>Tools:</p>
				<p className='mb-5'>
					<span className='font-bold'>Cut</span> - To delete something you have typed.
				</p>
				<Box className='ml-5'>
					<p className='mb-5'>● Select the text</p>
					<p className='mb-5'>
						● Select <span className='font-bold'>Cut</span>
					</p>
				</Box>

				<p className='mb-5'>
					<span className='font-bold'>Paste</span> - To put text in a new spot
				</p>
				<Box className='ml-5'>
					<p className='mb-5'>● Select the text</p>
					<p className='mb-5'>
						● Select <span className='font-bold'>Cut</span>
					</p>
					<p className='mb-5'>● Select the spot where you want to place the text</p>
					<p className='mb-5'>
						● Select <span className='font-bold'>Paste</span>
					</p>
				</Box>

				<p className='mb-5'>
					<span className='font-bold'>Undo</span> - Select Undo to reverse or cancel your last
					action. Each time you select Undo, it reverse one more action.
				</p>

				<p className='mb-5'>
					<span className='font-bold'>Redo</span> - Select Redo to reverse the action of the Undo
					command. Each time you select Redo, it reverse one more Undo command.
				</p>

				<p className='mb-5'>
					<span className='font-bold'>Word Count</span> - Select Hide Word Count to remove the word
					count. Select Show Word Count to show the word count
				</p>

				<p className='mb-5 font-bold'>Keyboard Keys:</p>
				<p className='mb-5'>
					Use <strong>Backspace</strong> to remove text to the left of the cursor
				</p>
				<p className='mb-5'>
					Use <strong>Delete</strong> to remove the text to the right of the cursor
				</p>

				<div className='border-2 border-dashed border-slate-600 p-1 w-[53%] mb-5'>
					<img src={writing_table} alt='writing_table' />
				</div>

				<p>
					Select <strong>Return</strong> to go on.
				</p>
		</TitleSection>
	);
};

export default HowToAnswer;
