import {
	Box,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { injectPrintFunc, toeflSelector } from '../../../core/redux/ToeflSlice';
import { useCallback, useEffect, useRef, useState } from 'react';

const ScoreReport = () => {
	const dispatch = useAppDispatch();
	const { content, testName } = useAppSelector(toeflSelector);
	const componentRef = useRef<HTMLElement>(null);

	const onBeforeGetContentResolve = useRef<any | null>(null);

	const [loading, setLoading] = useState(false);

	const handleAfterPrint = useCallback(() => {
		console.log('`onAfterPrint` called'); // tslint:disable-line no-console
	}, []);

	const handleBeforePrint = useCallback(() => {
		console.log('`onBeforePrint` called'); // tslint:disable-line no-console
	}, []);

	const handleOnBeforeGetContent = useCallback(() => {
		console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
		setLoading(true);

		return new Promise<void>((resolve) => {
			if (onBeforeGetContentResolve.current) {
				onBeforeGetContentResolve.current = resolve;
			}

			setTimeout(() => {
				setLoading(false);
				resolve();
			}, 2000);
		});
	}, [setLoading]);

	useEffect(() => {
		if (componentRef.current) {
			dispatch(injectPrintFunc(componentRef.current));
		}
	});

	return (
			<Box className='w-full max-w-[80rem] mx-auto mt-10 mb-5'>
				<Paper elevation={2}>
					<Box className='p-10' ref={componentRef}>
						<Box className='flex flex-col items-center mb-4'>
							<Typography className='text-[#154b9d] text-[42px] font-bold text-center'>
								TOEFL® Practice Online
							</Typography>
							<Typography className='text-[#305084] text-xl text-center'>{testName}</Typography>
							<Typography className='text-[#305084] text-xl text-center'>
								{content.GDSNM} Score Report
							</Typography>
						</Box>
						<Typography className='text-sm mb-6 text-justify'>
							The purpose of this practice test is to help you prepare for the TOEFL iBT®. Performance
							on this test is not necessary a predictor of how you might perform during an actual TOEFL®
							administration because you are not taking this test under regular testing conditions.
							However, you are encouraged to use this practice test to get a better sense of the TOEFL
							iBT® content and format, as well as receive scoring information on this preparation
							experience.{' '}
							<span className='italic'>Scores and information presented in this score report are for preparation use only and are not official test scores:</span>
						</Typography>

						<Typography className='text-sm'>Test Taker Profile</Typography>
						<TableContainer className='mb-2'>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Name
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Test Name
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Test Start
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Test End
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Total Scaled Score
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.USER_NAME}
										</TableCell>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.TEST_MODE_NAME}
										</TableCell>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.TEST_BEGIN_TIME}
										</TableCell>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.LAST_TEST_TIME}
										</TableCell>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.CONVERSION_SCORE_SUM}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Typography className='text-sm'>Summary Analysis</Typography>
						<Typography className='text-sm text-justify'>
							Click on a section to review the questions for that section. Click on the "% Correct" to
							review all incorrect questions If on the test customization page, you selected the untimed
							mode version and selected "Mark Guesses", click on "% Guessed" to see all questions marks
							as guessed:
						</Typography>
						<TableContainer className='mb-2'>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Section
										</TableCell>
										<TableCell
												sx={{ width: '35%' }}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										>
											Total Number of Correct Responses
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											% Correct
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Scaled Score
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											% Guessed*
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											% Correct of Guessed
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{content.READING ?
											<TableRow>
												<TableCell align={'center'} className='border border-slate-900'>
													<Link className={'font-bold cursor-pointer'}>Reading</Link>
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.READING.CORRECT_ANSWER_COUNT} of{' '}
													{content.READING.TOTAL_COUNT}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.READING.CORRECT_RATE}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.READING.CONVERSION_SCORE}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.READING.GUESSED_RATE}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.READING.GUESSED_CORRECT_RATE}
												</TableCell>
											</TableRow>
											: null}
									{content.LISTENING ?
											<TableRow>
												<TableCell align={'center'} className='border border-slate-900'>
													<Link className={'font-bold cursor-pointer'}>Listening</Link>
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.LISTENING.CORRECT_ANSWER_COUNT} of{' '}
													{content.LISTENING.TOTAL_COUNT}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.LISTENING.CORRECT_RATE}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.LISTENING.CONVERSION_SCORE}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.LISTENING.GUESSED_RATE}
												</TableCell>
												<TableCell align={'center'} className='border border-slate-900'>
													{content.LISTENING.GUESSED_CORRECT_RATE}
												</TableCell>
											</TableRow>
											: null}
								</TableBody>
							</Table>
						</TableContainer>

						<Typography className='text-sm italic'>
							*These scores available only if on the test customization page, you selected to mark the
							answers on which you guessed
						</Typography>
						<TableContainer className='mb-4'>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell
												width={90}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										>
											Section
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Scaled Score
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Scaled Score Range
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										content.SPEAKING ?
												<TableRow>
													<TableCell align={'center'} className='border border-slate-900'>
														{content.SPEAKING ? 'Speaking' : ''}
													</TableCell>
													<TableCell align={'center'} className='border border-slate-900'>
														{content.SPEAKING.CONVERSION_SCORE}
													</TableCell>
													<TableCell align={'center'} className='border border-slate-900'>
														{content.SPEAKING.SCALED_SCORE_MIN} - {content.SPEAKING.SCALED_SCORE_MAX}
													</TableCell>
												</TableRow>
												: null
									}
									{
										content.SPEAKING && content.SPEAKING.SPEAKING_LIST ?
												content.SPEAKING.SPEAKING_LIST.map((speak: any) => {
													return (
															<>
																<TableRow>
																	<TableCell align={'center'} className='border border-slate-900'>
																		Task {speak.QNOSEQ}
																	</TableCell>
																	<TableCell align={'center'} className='border border-slate-900'>
																		{speak.SCORE > 0 ? speak.SCORE : ''}
																	</TableCell>
																	<TableCell className='border border-slate-900'>
																		{speak.RECFILESIZE === 0 ?
																				<Link className={'ml-1 cursor-pointer font-bold'}>Click here to retake this
																					Speaking Practice Question</Link>
																				: ''}
																	</TableCell>
																</TableRow>
															</>
													);
												})
												: null
									}
								</TableBody>
							</Table>
						</TableContainer>

						<TableContainer>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} className='border border-slate-900 px-1'>
											<p className='underline italic font-bold'>Writing</p>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell align={'center'} className='border border-slate-900'>
											Writing scores are produced by e-rater® automated scoring software only. On the
											actual TOEFL test, human rater will evaluate writing responses.
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<TableContainer>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell
												width={'78%'}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										></TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Rating
										</TableCell>
										<TableCell colSpan={1} align={'center'} className='border border-slate-900'>
											Scaled Score
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell className='border border-slate-900'>
											<Link className='font-bold cursor-pointer ml-1'>
												Rating for Writing based on Reading and Listening
											</Link>
										</TableCell>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.WRITING && content.WRITING.WRL_ETSFLAG === 'Y' ? content.WRITING.WRL_SCORE : 'Wait for score'}
										</TableCell>
										<TableCell rowSpan={2} align={'center'} className='border border-slate-900'>
											{content.WRITING && content.WRITING.CONVERSION_SCORE}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className='border border-slate-900'>
											<Link className='font-bold cursor-pointer ml-1'>
												Rating for Writing based on Knowledge and Experience
											</Link>
										</TableCell>
										<TableCell align={'center'} className='border border-slate-900'>
											{content.WRITING.WKE_ETSFLAG === 'Y' ? content.WRITING.WKE_SCORE : 'Wait for score'}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<Typography className='my-4 text-sm italic font-semibold'>
							The Speaking and Writing sections of this practice test are scored by automated scoring
							systems.
						</Typography>
						<TableContainer>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} className='border border-slate-900 pl-1'>
											Reading
										</TableCell>
										<TableCell
												width={'10%'}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										>
											Level
										</TableCell>
										<TableCell width={'70%'} colSpan={1} className='border border-slate-900 pl-1'>
											Your Performance
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell className='border border-slate-900 pl-1'>Reading</TableCell>
										<TableCell
												sx={{ backgroundColor: '#e3e8f4' }}
												align={'center'}
												className='border border-slate-900'
										>
											{content.READING.GRADE_CODE !== '' ? content.READING.GRADE_CODE : 'Score of Zero'}
										</TableCell>
										<TableCell className='border border-slate-900 px-1'>
											<p>
												{content.READING.CDDESCRI}
											</p>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<TableContainer>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} className='border border-slate-900 pl-1'>
											Listening
										</TableCell>
										<TableCell
												width={'10%'}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										>
											Level
										</TableCell>
										<TableCell width={'70%'} colSpan={1} className='border border-slate-900 pl-1'>
											Your Performance
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell className='border border-slate-900 pl-1'>Listening</TableCell>
										<TableCell
												sx={{ backgroundColor: '#e3e8f4' }}
												align={'center'}
												className='border border-slate-900'
										>
											{content.LISTENING.GRADE_CODE !== '' ? content.LISTENING.GRADE_CODE : 'Score of Zero'}
										</TableCell>
										<TableCell className='border border-slate-900 px-1'>
											<p>
												{content.LISTENING.CDDESCRI}
											</p>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<TableContainer>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} className='border border-slate-900 pl-1'>
											Speaking
										</TableCell>
										<TableCell
												width={'10%'}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										>
											Level
										</TableCell>
										<TableCell width={'70%'} colSpan={1} className='border border-slate-900 pl-1'>
											Your Performance
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell className='border border-slate-900 pl-1'>Speaking</TableCell>
										<TableCell
												sx={{ backgroundColor: '#e3e8f4' }}
												align={'center'}
												className='border border-slate-900'
										></TableCell>
										<TableCell align={'center'} className='border border-slate-900'></TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<TableContainer>
							<Table className='w-full' sx={{ tableLayout: 'fixed' }} padding={'none'} size={'small'}>
								<TableHead sx={{ backgroundColor: '#831843' }}>
									<TableRow>
										<TableCell colSpan={1} className='border border-slate-900 pl-1'>
											Writing
										</TableCell>
										<TableCell
												width={'10%'}
												colSpan={1}
												align={'center'}
												className='border border-slate-900'
										>
											Level
										</TableCell>
										<TableCell width={'70%'} colSpan={1} className='border border-slate-900 pl-1'>
											Your Performance
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										content.WRITING ?
												<>
													<TableRow>
														<TableCell className='border border-slate-900 pl-1'>
															Writing based on Reading and Listening
														</TableCell>
														<TableCell
																align={'center'}
																sx={{ backgroundColor: '#e3e8f4' }}
																className='border border-slate-900'
														>
															{content.WRITING.WRL_GRADE}
														</TableCell>
														<TableCell className='border border-slate-900 px-1'>
															<p>
																{content.WRITING.WRLADV}
															</p>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell className='border border-slate-900 pl-1'>
															Writing based on Knowledge and Experience
														</TableCell>
														<TableCell
																align={'center'}
																sx={{ backgroundColor: '#e3e8f4' }}
																className='border border-slate-900'
														>
															{content.WRITING.WKE_GRADE}
														</TableCell>
														<TableCell className='border border-slate-900 px-1'>
															<p>
																{content.WRITING.WKEADV}
															</p>
														</TableCell>
													</TableRow>
												</>
												: null
									}
								</TableBody>
							</Table>
						</TableContainer>
						<Box className='w-full flex flex-col items-center mt-2'>
							<Typography className='text-sm mb-5'>
								To register for the TOEFL iBT®, please visit https://www.ets.org/mytoefl
							</Typography>
							<Typography className='text-sm mb-5'>
								To purchase additional TOEFL IBT® practice products, please visit
								https://www.toefltpo.com
							</Typography>
							<Typography className='text-sm'>
								*Scores and information presented in this score report are for practice use only and are
								not official test scores.
							</Typography>
						</Box>
					</Box>
				</Paper>
			</Box>
	);
};

export default ScoreReport;
