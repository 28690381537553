import { combineReducers } from 'redux';
import AuthSlice from './AuthSlice';
import ToeflSlice from './ToeflSlice';
import TopBarSlice from './TopBarSlice';
import AudioSlice from './AudioSlice';

const rootReducer = combineReducers({
	users: AuthSlice,
	toefls: ToeflSlice,
	topbar: TopBarSlice,
	audio: AudioSlice,
});

export default rootReducer;
