import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { NavigateFunction } from 'react-router-dom';

interface TestReloadedProps {
	closeDialog: () => void;
	navigate: NavigateFunction;
}

const TestReloadedDialog = ({ closeDialog, navigate }: TestReloadedProps) => {
	return (
			<>
				<DialogTitle
						className='py-3 font-bold'
						sx={{ backgroundColor: '#FDF4C5', color: '#313131', fontWeight: 'bold' }}
						id='alert-dialog-title'
				>
					Test Reloaded
				</DialogTitle>
				<DialogContent className='px-4 pt-4'>
					Your page has been reloaded and test has been ended
				</DialogContent>
				<DialogActions className='pb-6 px-4'>
					<Button
							variant='contained'
							sx={{ border: '1px solid #313131', color: '#144279', paddingX: '18px' }}
							color='primary'
							onClick={() => {
								closeDialog();
								navigate('/my-page', { replace: true });
							}}
					>
						<p className='text-white'>Continue</p>
					</Button>
				</DialogActions>
			</>
	);
};

export default TestReloadedDialog;
