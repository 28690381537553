import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlowControlName } from '../../constants/type.enum';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { userSelector } from '../../redux/AuthSlice';
import { onTestController, toeflSelector, updateExpiredTime } from '../../redux/ToeflSlice';
import './AudioPlayer.scss';
import { audioSelector, resetAudioState, updateAudioEnded, updatePlayingTrack } from '../../redux/AudioSlice';

interface AudioPlayerProps {
	visible?: boolean;
}

const AudioPlayer = ({ visible = true }: AudioPlayerProps) => {
	const { tracks, volumeCntl } = useAppSelector(audioSelector);
	const dispatch = useAppDispatch();
	const {
		user: { SESSION, USER_ID },
	} = useAppSelector(userSelector);
	const { data, content, expired_time } = useAppSelector(toeflSelector);
	const [trackIndex, setTrackIndex] = useState(0);
	const [trackProgress, setTrackProgress] = useState(0);

	const { AUDIO_FILE, IMG_FILE, WAIT_TIME } = tracks[trackIndex];

	const audioRef = useRef<HTMLAudioElement>(new Audio(`data:audio/ogg;base64,${AUDIO_FILE}`));
	const intervalRef = useRef<ReturnType<typeof setInterval>>();
	const isReady = useRef<boolean>(false);

	const { duration }: HTMLAudioElement = audioRef.current;

	const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
	const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #2883F0), color-stop(${currentPercentage}, #E1E2E5))
`;

	const nextStepAfterAudioPlay = useCallback(() => {
		if (content.NEXT_STEP === '-1') {
			return;
		}
		const body = {
			HEADER: {
				SESSION,
				USER_ID,
			},
			BODY: {
				TEST_CODE: data.test_code,
				NEXT_STEP: data.next_step,
				TEST_MODE: data.test_mode,
			},
		};

		dispatch(resetAudioState());
		dispatch(onTestController({ body }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Play next track on previous track ended
	const toNextTrack = useCallback(() => {
		if (trackIndex < tracks.length - 1) {
			setTimeout(() => {
				setTrackIndex(trackIndex + 1);
			}, WAIT_TIME * 1000);

		} else {
			// dispatch action after end of listening
			switch (content.FLOW_CNTL_NAME) {
				case FlowControlName.Listening1_setLeader:
				case FlowControlName.Listening2_setLeader:
				case FlowControlName.Speaking_stimulus_listening:
				case FlowControlName.Speaking_listen_carefully:
				case FlowControlName.Writing_stimulus:
				case FlowControlName.Listening1_stimulus:
				case FlowControlName.Listening2_stimulus:
				case FlowControlName.DIR_L1:
					setTimeout(() => {
						nextStepAfterAudioPlay();
					}, WAIT_TIME * 1000);
					break;
				default:
					break;
			}
			if (intervalRef.current) clearInterval(intervalRef.current);
			dispatch(updatePlayingTrack(false));
			dispatch(updateAudioEnded(true));
		}
	}, [WAIT_TIME, content.FLOW_CNTL_NAME, dispatch, nextStepAfterAudioPlay, trackIndex, tracks.length]);

	// interval function to track slider progress
	const startTimer = useCallback(() => {
		// Clear any timers already running
		clearInterval(intervalRef.current);

		intervalRef.current = setInterval(() => {
			if (audioRef.current.ended) {
				toNextTrack();
			} else {
				setTrackProgress(audioRef.current.currentTime);
			}
		}, 1000);
	}, [WAIT_TIME, toNextTrack]);

	const base64Convention = (base64: string): string => {
		const firstCharacter = base64.charAt(0);
		switch (firstCharacter) {
			case '/':
				return 'image/jpeg';
			case 'R':
				return 'image/gif';
			case 'i':
				return 'image/png';
			default:
				return 'image/webp';
		}
	};

	const imageFromBase64 = (base64: string) => {
		return `data:${base64Convention(base64)};base64,${base64}`;
	};

	const playAudio = useCallback(
			async (playPromise: Promise<void>) => {
				try {
					await playPromise;
					await dispatch(updatePlayingTrack(true));
					startTimer();

					switch (content.FLOW_CNTL_NAME) {
						case FlowControlName.Listening1_setLeader:
						case FlowControlName.Listening2_setLeader:
						case FlowControlName.Listening1_question:
						case FlowControlName.Listening2_question:
						case FlowControlName.Listening1_stimulus:
						case FlowControlName.Listening2_stimulus:
							setTimeout(() => {
								dispatch(updateExpiredTime(expired_time + Math.ceil(audioRef.current.duration * 1000)));
							}, 500);
							break;
						default:
							break;
					}

				} catch (e: any) {
					console.log(e.toString());
				}
			},
			[dispatch, startTimer],
	);

	// Start play audio
	useEffect(() => {
		if (AUDIO_FILE && audioRef.current) {
			audioRef.current.volume = volumeCntl;
			const playPromise = audioRef.current.play();
			if (playPromise !== undefined) {
				playAudio(playPromise).catch((err) => console.log(err));
			}
		}
	}, [AUDIO_FILE, playAudio]);

	useEffect(() => {
		// Pause and clean up on unmount
		return () => {
			audioRef.current.pause();
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, []);

	// Handle setup when changing tracks
	useEffect(() => {
		audioRef.current.pause();
		audioRef.current = new Audio(`data:audio/ogg;base64,${AUDIO_FILE}`);
		audioRef.current.volume = volumeCntl;
		setTrackProgress(audioRef.current.currentTime);

		if (isReady.current) {
			// let playPromise: Promise<void>;
			const playPromise = audioRef.current.play();

			if (playPromise !== undefined) {
				playAudio(playPromise).catch((err) => console.log(err));
			}
		} else {
			// Set the isReady ref as true for the next pass
			isReady.current = true;
		}
	}, [AUDIO_FILE, playAudio, trackIndex]);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.volume = volumeCntl;
		}
	}, [volumeCntl]);

	return visible ? (
			<Box className='audio-player flex justify-center flex-col w-full relative'>
				<Box className='track-info self-center'>
					{IMG_FILE ? (
							<img className='artwork' src={imageFromBase64(IMG_FILE)} alt={`track thumbnail`} />
					) : null}
				</Box>
				<Box
						sx={{ width: '60%' }}
						className='range-input self-center mt-6 border border-slate-900 flex justify-center px-4'
				>
					<input
							readOnly
							type='range'
							value={trackProgress}
							step='1'
							min='0'
							max={duration ? duration : `${duration}`}
							className='w-full px-4 py-2'
							style={{ background: trackStyling }}
					/>
				</Box>
				{/* Adding temporary skip button for testing*/}
				<Button onClick={toNextTrack}>Skip</Button>
			</Box>
	) : null;
};

export default AudioPlayer;
