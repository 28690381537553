import { createBrowserRouter, Navigate } from 'react-router-dom';
import App from '../../App';
import MainLayout from '../core/common/layout/main-layout/MainLayout';
import TestLayout from '../core/common/layout/test-layout/TestLayout';
import NotFound from '../main/404/404';
import Login from '../main/login/login';
import MyPage from '../main/my-page/my-page';
import EndSectionRemainTime
	from '../main/non-functional/reading-section/end-section-remain-time/end-section-remain-time';
import EndSectionTimeOut from '../main/non-functional/reading-section/end-section-time-out/end-section-time-out';
import ReadingHelp from '../main/help/reading-help';
import ReadingSectionDirections from '../main/reading-section-directions/reading-section-directions';
import Review from '../main/review';
import StartScreen from '../main/start-screen/start-screen';
import TestCommonScreen from '../main/test-share';
import CopyrightInformation from '../main/test-share/copyright-infomation/copyright-information';
import GeneralTestInformation from '../main/test-share/generate-test-information/general-test-information';
import HardwareCheck from '../main/test-share/hardware-check/hardware-check';
import HardwareCheckImageText
	from '../main/test-share/hardware-check/hardware-check-image-text/hardware-check-image-text';
import HardwareCheckMicrophone
	from '../main/test-share/hardware-check/hardware-check-microphone/hardware-check-microphone';
import HardwareCheckText from '../main/test-share/hardware-check/hardware-check-text/hardware-check-text';
import PrivateRoute from '../utils/PrivateRoute';
import ListeningHelp from '../main/help/listening-help';
import WritingHelp from '../main/help/writing-help';
import PlayBackResponse from '../main/test-share/speaking/playback-response/playback-response';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				index: true,
				element: <Navigate to={'/my-page'} />,
			},
			{
				path: 'login',
				element: <Login />,
			},
			{
				element: <PrivateRoute />,
				children: [
					{
						path: 'my-page',
						element: <MainLayout />,
						children: [
							{
								index: true,
								element: <MyPage />,
							},
						],
					},
					{
						element: <TestLayout />,
						children: [
							{
								path: 'testing',
								element: <TestCommonScreen />,
							},
							{
								path: 'time-mode',
								element: <StartScreen />,
							},
							{
								path: 'copyright-information',
								element: <CopyrightInformation />,
							},
							{
								path: 'examination',
								element: <GeneralTestInformation />,
							},
							{
								path: 'hardware-check',
								element: <HardwareCheck />,
							},
							{
								path: 'hardware-check-image-text',
								element: <HardwareCheckImageText />,
							},
							{
								path: 'hardware-check-text',
								element: <HardwareCheckText />,
							},
							{
								path: 'hardware-check-microphone',
								element: <HardwareCheckMicrophone />,
							},
							{
								path: 'reading-section-directions',
								element: <ReadingSectionDirections />,
							},
							{
								path: 'end-section',
								element: <EndSectionTimeOut />,
							},
						],
					},
					{
						path: 'reading-help',
						element: <ReadingHelp />,
					},
					{
						path: 'listening-help',
						element: <ListeningHelp />,
					},
					{
						path: 'writing-help',
						element: <WritingHelp />,
					},
					{
						path: 'review',
						element: <Review />,
					},
					{
						path: 'playback-response',
						element: <PlayBackResponse />,
					},
				],
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
]);

export default router;
